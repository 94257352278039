import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { useSnackbar } from 'notistack'
//
import Page from 'src/components/Page'
import { accountService } from 'src/services'
import ProfileDetails from './ProfileDetails'
import Password from './Password'
import Mfa from './Mfa'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const Account = ({ intl }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const user = accountService.userValue

  React.useEffect(() => {
    if (!user.mfaEnabled) {
      enqueueSnackbar(intl.formatMessage({ id: 'mfa.warning' }), {
        variant: 'warning',
        duration: 20000
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page className={classes.root} title={intl.formatMessage({ id: 'accountView.title' })}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mt={3}>
              <Mfa />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <ProfileDetails />
          </Grid>
          <Grid item xs={12}>
            <Box mt={3}>
              <Password />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default injectIntl(Account)

Account.propTypes = {
  intl: PropTypes.object
}
