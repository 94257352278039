import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, Route } from 'react-router-dom'
//
import { accountService, aggregationsService } from 'src/services'
import { LocalizedRedirect } from 'src/i18n'

const getLastQueryPathItem = path => {
  const effectivePath = path.endsWith('/') ? path.substring(0, path.lastIndexOf('/')) : path
  return effectivePath.substring(effectivePath.lastIndexOf('/') + 1)
}

const ProtectedRoute = ({ roles, element, children, onlyAuthorizedAdministrations = false, ...rest }) => {
  const user = accountService.userValue
  const location = useLocation()
  const { pathname } = location

  const [isAuthorized, setAuthorized] = React.useState(true)

  React.useEffect(() => {

    const checkAuthorization = async () => {
      const administrationCode = getLastQueryPathItem(pathname)
      const response = await aggregationsService.administrationAuthorizationByCode(administrationCode)
      setAuthorized(!!response && response.userAccessGranted)
    }

    if (onlyAuthorizedAdministrations) {
      checkAuthorization()
    }
  })

  React.useEffect(() => {
    setAuthorized(true)
  }, [location])

  if (!user) {
    // not logged in so redirect to login page with the return url
    return <LocalizedRedirect to={{ pathname: '/login' }} replace />
  }

  // mfa not enabled, enforce user to enable it
  if (!user.mfaEnabled && !/^.*\/app\/(help|terms-and-conditions|account)$/.test(pathname)) {
    return <LocalizedRedirect to={{ pathname: '/app/account' }} />
  }

  // check if route is restricted by role and redirect to homepage if not authorized
  if (roles && roles.indexOf(user.role) === -1) {
    return <LocalizedRedirect to={{ pathname: '/app' }} />
  }

  // check if the user is authorized for an administration detail and redirect to administrations if not authorized
  if (onlyAuthorizedAdministrations && !isAuthorized) {
    if (roles.includes('User')) {
      return <LocalizedRedirect to={{ pathname: '/app/administrations' }} />
    }
    return <LocalizedRedirect to={{ pathname: '/app' }} />
  }

  return (
    <Route element={element} {...rest}>
      {children}
    </Route>
  )
}

export default ProtectedRoute

ProtectedRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  path: PropTypes.string,
  element: PropTypes.element,
  children: PropTypes.node,
  onlyAuthorizedAdministrations: PropTypes.bool,
  onlyAuthorizedUnions: PropTypes.bool
}
