import config from 'src/utils/config'
import fetchWrapper from 'src/utils/fetch'

const baseUrl = `${config.apiUrl}/unions`
const aggregationsBaseUrl = `${baseUrl}/aggregations`

function getAll() {
  return fetchWrapper.get(baseUrl)
}

function getAllExcludingUser(userId) {
  return fetchWrapper.get(`${baseUrl}/exclude`, { userId })
}

function getByQuery(query) {
  return fetchWrapper.get(baseUrl, query)
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`)
}

function create(params) {
  return fetchWrapper.post(baseUrl, params)
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params)
}

function forceUpdate(id, params) {
  return fetchWrapper.patch(`${baseUrl}/${id}`, params)
}

// eslint-disable-next-line no-underscore-dangle
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`)
}

// AGGREGATIONS
function unionAuthorizationById(id) {
  return fetchWrapper.get(`${aggregationsBaseUrl}/unions/${id}`, {})
}

function unionAuthorizationByCode(unionId) {
  return fetchWrapper.get(`${aggregationsBaseUrl}/unions`, { unionId })
}

function getUnionsExtended() {
  return fetchWrapper.get(`${aggregationsBaseUrl}/unions/extended`)
}

function getAccountUnionsExtended(status) {
  return fetchWrapper.get(`${aggregationsBaseUrl}/accounts-unions/extended`, { status })
}

export default {
  getAll,
  getAllExcludingUser,
  getByQuery,
  getById,
  create,
  update,
  forceUpdate,
  delete: _delete,
  getUnionsExtended,
  getAccountUnionsExtended,
  unionAuthorizationById,
  unionAuthorizationByCode
}
