/* eslint-disable react/no-unescaped-entities */
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Alert, Skeleton } from '@material-ui/lab'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
//
import { CloudDownload, Replay } from '@material-ui/icons'
import { controlsService } from 'src/services'
import ControlStatusChip from '../ControlStatusChip'
import ControlContext from './ControlContext'

const ImportedAdministrationsDetail = ({
    administrationsStats,
    importAdministrationsStatus,
    loadingAdministrations,
    reimportAdministrationsAction,
    intl
}) => {
    const [overallProgress, setOverallProgress] = React.useState(null)
    const [open, setOpen] = React.useState(false);

    const control = React.useContext(ControlContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async (actionAgreed = false) => {
        if (actionAgreed) {
            await reimportAdministrationsAction(control)
        }
        setOpen(false);
    };

    React.useEffect(() => {
        if (importAdministrationsStatus) {
            const { total, statuses } = importAdministrationsStatus
            if (statuses) {
                const completed = statuses
                    .filter(({ status }) => status === "COMPLETED")
                    .flatMap(({ count }) => count)
                    .shift() || undefined
                if (completed && total) {
                    setOverallProgress(`${completed}/${total}`)
                } else {
                    setOverallProgress(`N/A`)
                }
            } else {
                setOverallProgress(`N/A`)
            }
        }
    }, [importAdministrationsStatus])

    return (<>
        <br />
        {/* Administrations */}
        <Dialog
            open={open}
            onClose={handleClose}>
            <DialogTitle>
                <FormattedMessage id="controlsView.reimportAdministrationsDialogTitle" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id="controlsView.reimportAdministrationsDialogText" />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    <FormattedMessage id="buttons.cancel" />
                </Button>
                <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                    <FormattedMessage id="buttons.ok" />
                </Button>
            </DialogActions>
        </Dialog>

        <Grid item xs={12} >
            <Box display="flex" alignContent="flex-end" alignItems="center">
                <Box width="20%">
                    <Typography color="textPrimary" variant="h4">
                        <FormattedMessage id="controlsView.importedAdministrations" />
                    </Typography>
                </Box>
                {importAdministrationsStatus && (
                    <Box display="flex" flexDirection="row" alignItems="center" px={2}>
                        <ControlStatusChip status={importAdministrationsStatus.status} outlined />
                        {["COMPLETED"].includes(importAdministrationsStatus.status) && (<>
                            <Box pl={1}>
                                <IconButton title={intl.formatMessage({ id: 'controlsView.reimportAdministrationButton' })}
                                    onClick={handleClickOpen}>
                                    <Replay />
                                </IconButton>
                            </Box>
                            <Box pl={1}>
                                <IconButton color="primary" title={intl.formatMessage({ id: 'controlsView.reportAdministrationButton' })}
                                    onClick={async () => controlsService.downloadAdministrationsCSVReport(control)}>
                                    <CloudDownload />
                                </IconButton>
                            </Box>
                        </>
                        )}
                    </Box>)}
            </Box>
        </Grid >
        {administrationsStats ? (
            <>
                <Grid item xs={6} md={3} >
                    <Typography color="textPrimary" variant="h5" >
                        <FormattedMessage id="controlsView.totalAdministrations" />
                        {importAdministrationsStatus && importAdministrationsStatus.status === "IN_PROGRESS" ? (
                            <FormattedMessage id="controlsView.overallProgress" />
                        ) : null}
                    </Typography>
                </Grid>
                {importAdministrationsStatus && importAdministrationsStatus.status === "IN_PROGRESS" ? (
                    <Grid item xs={6} md={3} >
                        <Typography color="textPrimary">
                            {overallProgress || 'N/A'}
                        </Typography>
                    </Grid>
                ) : (
                    <Grid item xs={6} md={3} >
                        <Typography color="textPrimary">
                            {(importAdministrationsStatus && importAdministrationsStatus.total) || 'N/A'}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={6} md={3} >
                    <Typography color="textPrimary" variant="h5" >
                        <FormattedMessage id="controlsView.totalNumberCSVFilesImported" />
                    </Typography>
                </Grid>
                <Grid item xs={6} md={3} >
                    <Typography color="textPrimary">
                        {(administrationsStats && administrationsStats.totalNumberCSVFilesImported) || 'N/A'}
                    </Typography>
                </Grid>

                <Grid item xs={6} md={3} >
                    <Typography color="textPrimary" variant="h5" >
                        <FormattedMessage id="controlsView.totalSizeCSVFilesImported" />
                    </Typography>
                </Grid>
                <Grid item xs={6} md={3} >
                    <Typography color="textPrimary">
                        {(administrationsStats && administrationsStats.totalSizeCSVFilesImported) || 'N/A'}
                    </Typography>
                </Grid>
                <Grid item xs={6} md={3} >
                    <Typography color="textPrimary" variant="h5" >
                        <FormattedMessage id="controlsView.totalAdministrationsPeople" />
                    </Typography>
                </Grid>
                <Grid item xs={6} md={3} >
                    <Typography color="textPrimary">
                        {(administrationsStats && administrationsStats.totalAdministrationentries) || 'N/A'}
                    </Typography>
                </Grid>
            </>
        ) : (
            <Grid item xs={12} >
                {loadingAdministrations ? (
                    <Skeleton variant="rect" width='100%' height={50} />
                ) : (
                    <Alert severity='info'>
                        <Typography color="textPrimary" variant="h6">
                            <FormattedMessage id="controlsView.importAdministrationsStatsNotYetAvailable" />
                        </Typography>
                    </Alert>
                )}
            </Grid>)}
        <br />
    </>
    )
}
export default injectIntl(ImportedAdministrationsDetail)

ImportedAdministrationsDetail.propTypes = {
    administrationsStats: PropTypes.object,
    importAdministrationsStatus: PropTypes.object,
    loadingAdministrations: PropTypes.bool,
    reimportAdministrationsAction: PropTypes.func.isRequired,
    intl: PropTypes.object
}
