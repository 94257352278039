import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Divider,
  Grid,
  Typography,
  CircularProgress,
  makeStyles
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
//
import locales from 'src/i18n/locales'
import { settingsService } from 'src/services'

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  textarea: {
    marginBottom: '1rem'
  }
})

const enabledLocales = Object.values(locales).filter(loc => loc.enabled)

const defaultValues = {}
Object.values(enabledLocales).forEach(loc => {
  defaultValues[loc.id] = ''
})

const InfoMessage = ({ className, intl, ...rest }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = React.useState(true)
  const [infoMessage, setInfoMessage] = React.useState({
    id: false,
    value: defaultValues
  })

  React.useEffect(() => {
    settingsService
      .getByKey('termsAndConditions')
      .then(res => {
        // eslint-disable-next-line no-underscore-dangle
        const id = res._id
        setInfoMessage({ id, value: res.value || defaultValues })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  return loading ? (
    <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
      <CircularProgress />
      <Typography color="textPrimary" variant="h4">
        <FormattedMessage id="global.loading" />
      </Typography>
    </div>
  ) : (
    <Formik
      initialValues={infoMessage.value}
      validationSchema={Yup.object().test(
        'value',
        'Only valid locales are allowd',
        val => Object.keys(val).length === Object.keys(enabledLocales)
      )}
      onSubmit={(value, { setSubmitting }) => {
        setSubmitting(true)

        if (!infoMessage.id) {
          settingsService
            .create({ key: 'termsAndConditions', value })
            .then(() => {
              setSubmitting(false)
              enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
            })
            .catch(() => {
              setSubmitting(false)
              enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), { variant: 'error' })
            })
        } else {
          settingsService
            .update(infoMessage.id, { key: 'termsAndConditions', value })
            .then(() => {
              setSubmitting(false)
              enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
            })
            .catch(() => {
              setSubmitting(false)
              enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), { variant: 'error' })
            })
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, resetForm }) => (
        <form onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
          <Card>
            <CardHeader
              subheader={intl.formatMessage({ id: 'settings.tecInfo' })}
              title={intl.formatMessage({ id: 'settings.tecTitle' })}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid className={classes.item} item md={12} sm={12} xs={12}>
                  {enabledLocales.map(loc => (
                    <Field
                      key={`message-${loc.id}`}
                      component={TextField}
                      error={Boolean(touched?.[loc.id] && errors?.[loc.id])}
                      helperText={touched?.[loc.id] && errors?.[loc.id]}
                      label={intl.formatMessage({ id: `languages.${loc.id}` })}
                      id={loc.id}
                      name={loc.id}
                      multiline
                      rows={3}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={classes.textarea}
                      value={values?.[loc.id]}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  ))}
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                style={{ marginRight: '15px' }}
                disabled={isSubmitting}
                size="large"
                type="reset"
                variant="contained"
                onClick={() => resetForm({ values: defaultValues })}
              >
                <FormattedMessage id="buttons.reset" />
              </Button>
              <Button color="primary" disabled={isSubmitting} size="large" type="submit" variant="contained">
                <FormattedMessage id="buttons.save" />
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  )
}

InfoMessage.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object
}

export default injectIntl(InfoMessage)
