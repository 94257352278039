import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Container,
  Dialog,
  DialogTitle,
  Typography
} from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useConfirm } from 'material-ui-confirm'
import { injectIntl, FormattedMessage } from 'react-intl'
//
import { unionsService, accountService, accountsUnionsService } from 'src/services'
import { isEmail } from 'src/utils/validations'

const getUsers = id => {
  return accountsUnionsService.getByUnionId(id).then(filtered =>
    filtered.map(user => {
      return {
        // eslint-disable-next-line no-underscore-dangle
        id: user._id,
        firstName: user.account.firstName,
        lastName: user.account.lastName,
        email: user.account.email,
        telephoneNumber: user.account.telephoneNumber,
        status: user.status
      }
    })
  )
}

const UsersUnion = ({ id, open, handleClose, intl }) => {
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirm()
  const [union, setUnion] = React.useState(null)
  const [users, setUsers] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [selected, setSelected] = React.useState([])

  const refreshUsers = () => {
    setLoading(true)
    getUsers(id).then(newUsers => {
      setUsers(newUsers)
      setLoading(false)
    })
  }

  React.useEffect(() => {
    unionsService
      .getById(id)
      .then(admin => {
        setUnion(admin)
        refreshUsers()
      })
      .catch(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleSelectionChange = selection => {
    if (selection && selection.rowIds) {
      setSelected(selection.rowIds)
    }
  }

  const columns = [
    {
      field: 'firstName',
      headerName: intl.formatMessage({ id: 'dataGrid.firstName' }),
      width: 150
    },
    {
      field: 'lastName',
      headerName: intl.formatMessage({ id: 'dataGrid.lastName' }),
      width: 200
    },
    {
      field: 'email',
      headerName: intl.formatMessage({ id: 'dataGrid.email' }),
      width: 320
    },
    {
      field: 'telephoneNumber',
      headerName: intl.formatMessage({ id: 'dataGrid.telephone' }),
      width: 160
    },
    {
      field: 'status',
      headerName: intl.formatMessage({ id: 'dataGrid.status' }),
      width: 120
    }
  ]

  const handleDelete = () => {
    confirm({
      title: intl.formatMessage({ id: 'confirmations.title' }),
      description: intl.formatMessage({ id: 'confirmations.description' })
    }).then(() => {
      setLoading(true)
      enqueueSnackbar(`Deleting ${selected.length} users`, { variant: 'info' })
      accountsUnionsService.deleteAccountsUnions({ accountsUnions: selected })
        .then(() => {
          enqueueSnackbar(intl.formatMessage({ id: 'unionsView.deletedUsers' }, { total: selected.length }), {
            variant: 'success',
            autoHideDuration: 10000
          })
          refreshUsers()
          setSelected([])
        })
    })
  }

  return (
    <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
      <DialogTitle>
        <FormattedMessage id="unionsView.editUsersTitle" />
      </DialogTitle>
      <Container>
        <Grid container xl spacing={2}>
          <Grid item xs={12}>
            {loading && (
              <div>
                <CircularProgress color="inherit" size={20} />
                <FormattedMessage id="global.loading" />
              </div>
            )}
            {!loading && union ? (
              <Formik
                onSubmit={async ({ emails }, { resetForm }) => {
                  const userEmails = []

                  emails
                    .split(/[\n,]/)
                    .map(email => email.trim())
                    .filter(email => email.length)
                    .map(email => email.toLowerCase())
                    .forEach(email => {
                      if (isEmail(email) && !userEmails.includes(email)) {
                        userEmails.push(email)
                      } else {
                        enqueueSnackbar(intl.formatMessage({ id: 'unionsView.emailInvalid' }, { email }), {
                          variant: 'error',
                          autoHideDuration: 5000
                        })
                      }
                    })

                  if (userEmails.length) {
                    accountService.getAll()
                      .then(_users => {
                        _users.forEach(user => {
                          if (userEmails.includes(user.email.toLowerCase())) {
                            accountsUnionsService
                              .createAccountsUnionRecord({
                                account: user.id,
                                union: id
                              })
                              .then(() => {
                                enqueueSnackbar(
                                  intl.formatMessage({ id: 'unionsView.emailAdded' }, { email: user.email }),
                                  {
                                    variant: 'info',
                                    autoHideDuration: 3000
                                  }
                                )
                                refreshUsers()
                              })
                              .catch(() => {
                                enqueueSnackbar(
                                  intl.formatMessage({ id: 'unionsView.emailError' }, { email: user.email }),
                                  {
                                    variant: 'error',
                                    autoHideDuration: 5000
                                  }
                                )
                              })
                          }
                        })
                      })
                  }
                  resetForm({})
                }}
                initialValues={{
                  emails: ''
                }}
              >
                {({ handleSubmit, values, touched, errors, handleBlur, handleChange }) => (
                  <form onSubmit={handleSubmit}>
                    <Card className="mb-2">
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <TextField
                              error={Boolean(touched.emails && errors.emails)}
                              fullWidth
                              helperText={touched.emails && errors.emails}
                              label={intl.formatMessage({ id: 'unionsView.addEmailsLabel' })}
                              name="emails"
                              multiline
                              rows={2}
                              value={values.emails}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>

                        <Box my={1} mt={1}>
                          <DialogActions>
                            <Button color="primary" size="small" type="submit" variant="contained">
                              <FormattedMessage id="buttons.addUsers" />
                            </Button>
                          </DialogActions>
                        </Box>
                      </CardContent>
                    </Card>
                  </form>
                )}
              </Formik>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ minHeight: '360px', flexGrow: 1 }}>
                {users.length ? (
                  <DataGrid
                    rows={users}
                    columns={columns}
                    pageSize={8}
                    headerHeight={42}
                    rowHeight={34}
                    loading={loading}
                    checkboxSelection
                    disableSelectionOnClick
                    onSelectionChange={handleSelectionChange}
                  />
                ) : (
                  <Typography>
                    <FormattedMessage id="unionsView.noUsers" />
                  </Typography>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} style={{ padding: '10px' }}>
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={handleClose} color="primary" variant="text">
                <FormattedMessage id="buttons.close" />
              </Button>
              <Button onClick={handleDelete} color="primary" variant="contained" disabled={!selected.length || loading}>
                <FormattedMessage id="buttons.removeUsers" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  )
}

export default injectIntl(UsersUnion)

UsersUnion.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object
}
