import 'react-perfect-scrollbar/dist/css/styles.css'
import React from 'react'
import { ThemeProvider } from '@material-ui/core'
import { ConfirmProvider } from 'material-ui-confirm'
import { CookiesProvider } from 'react-cookie'
//
import { accountService } from 'src/services'
import GlobalStyles from 'src/components/GlobalStyles'
import theme from 'src/theme'
import { I18nProvider } from 'src/i18n'

import './App.css'
import AppRoutes from './routes'

const App = () => {
  const [, setUser] = React.useState({})

  React.useEffect(() => {
    const subscription = accountService.user.subscribe(x => setUser(x))
    return subscription.unsubscribe
  }, [])

  return (
    <CookiesProvider>
      <I18nProvider>
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            <GlobalStyles />
            <AppRoutes />
          </ConfirmProvider>
        </ThemeProvider>
      </I18nProvider>
    </CookiesProvider>
  )
}

export default App
