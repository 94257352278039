import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useLocation } from 'react-router'
import { useSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

//
import Page from 'src/components/Page'
import { accountService } from 'src/services'
import { useLocalizedNavigate } from 'src/i18n'
import { passwordRegex } from 'src/utils/validations'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const LoginView = ({ intl }) => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useLocalizedNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [status, setStatus] = React.useState('processing')
  const search = new URLSearchParams(location.search)
  const token = search.get('token')

  React.useEffect(() => {
    accountService
      .validateResetToken(token)
      .then(() => {
        setStatus('valid')
      })
      .catch(() => {
        setStatus('invalid')
      })
  }, [token])

  return (
    <Page className={classes.root} title="Login">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
          {status === 'processing' && (
            <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
              <CircularProgress />
              <Typography color="textPrimary" variant="h4">
                <FormattedMessage id="global.loading" />
              </Typography>
            </div>
          )}

          {status === 'invalid' && (
            <Alert className={classes.alert} severity="error">
              <AlertTitle>
                <FormattedMessage id="passwordReset.error" />
              </AlertTitle>
              <Typography color="textPrimary" variant="h4">
                <FormattedMessage id="passwordReset.invalid" />
              </Typography>
            </Alert>
          )}

          {status === 'valid' ? (
            <Formik
              initialValues={{
                password: '',
                confirmPassword: ''
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .max(64)
                  .required(intl.formatMessage({ id: 'validations.field' }))
                  .matches(passwordRegex, intl.formatMessage({ id: 'validations.password' })),
                confirmPassword: Yup.string()
                  .max(64)
                  .required(intl.formatMessage({ id: 'validations.field' }))
                  .oneOf([Yup.ref('password')], intl.formatMessage({ id: 'validations.passwordMatch' }))
              })}
              onSubmit={({ password, confirmPassword }, { setSubmitting }) => {
                accountService
                  .resetPassword({ password, confirmPassword, token })
                  .then(() => {
                    enqueueSnackbar(intl.formatMessage({ id: 'passwordReset.success' }), { variant: 'success' })
                    navigate('/login', { replace: true })
                  })
                  .catch(() => {
                    setSubmitting(false)
                    enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), { variant: 'error' })
                  })
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      <FormattedMessage id="passwordReset.title" />
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2">
                      <FormattedMessage id="passwordReset.description" />
                    </Typography>
                  </Box>

                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label={<FormattedMessage id="login.password" />}
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />

                  <TextField
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    fullWidth
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    label={<FormattedMessage id="login.passwordConfirm" />}
                    margin="normal"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.confirmPassword}
                    variant="outlined"
                  />

                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      <FormattedMessage id="passwordReset.submit" />
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          ) : null}
        </Container>
      </Box>
    </Page>
  )
}

export default injectIntl(LoginView)

LoginView.propTypes = {
  intl: PropTypes.object
}
