import config from 'src/utils/config'
import fetchWrapper from 'src/utils/fetch'

const baseUrl = `${config.apiUrl}/assets`

function getAll() {
  return fetchWrapper.get(baseUrl)
}

function getByTag(tag) {
  return fetchWrapper.get(`${baseUrl}/tags/${tag}`,)
}

// eslint-disable-next-line no-underscore-dangle
function _delete(filename) {
  return fetchWrapper.delete(`${baseUrl}/${filename}`, true)
}

function upload(formData) {
  return fetchWrapper.postMultipart(baseUrl, formData)
}

function download(fileName) {
  return fetchWrapper.getOriginalFile(`${baseUrl}/${fileName}`, fileName)
}

function getFileContent(fileName) {
  return fetchWrapper.get(`${baseUrl}/${fileName}`, {}, true)
}

function postTag(fileName, tag) {
  return fetchWrapper.post(`${baseUrl}/${fileName}/tags/${tag}`)
}

export default {
  getAll,
  getByTag,
  upload,
  download,
  getFileContent,
  delete: _delete,
  postTag
}
