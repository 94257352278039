import config from 'src/utils/config'
import fetchWrapper from 'src/utils/fetch'

const baseUrl = `${config.apiUrl}/accounts-administrations`

function getAll() {
  return fetchWrapper.get(baseUrl)
}

function getByUserId(id) {
  return fetchWrapper.get(baseUrl, { account: id })
}

function getByAdministrationId(id) {
  return fetchWrapper.get(baseUrl, { administration: id })
}

function getByStatus(status) {
  return fetchWrapper.get(baseUrl, { status })
}

function createAccountsAdministrationRecord({ account, administration }) {
  return fetchWrapper.post(baseUrl, { account, administration })
}

function updateAccountsAdministrations(data) {
  return fetchWrapper.put(`${baseUrl}/`, data)
}

function deleteAccountsAdministrations(data) {
  return fetchWrapper.post(`${baseUrl}/delete`, data)
}

function getByQuery(query) {
  return fetchWrapper.get(`${baseUrl}`, query)
}

export default {
  getAll,
  getByUserId,
  getByAdministrationId,
  getByStatus,
  getByQuery,
  createAccountsAdministrationRecord,
  updateAccountsAdministrations,
  deleteAccountsAdministrations,
}
