import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router'
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
//
import Page from 'src/components/Page'
import { accountService } from 'src/services'
import { LocalizedLink, useLocalizedNavigate } from 'src/i18n'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  alert: {
    padding: theme.spacing(8)
  }
}))

const VerifyEmailView = ({ intl }) => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useLocalizedNavigate()
  const [status, setStatus] = React.useState('processing')
  const search = new URLSearchParams(location.search)
  const token = search.get('token')

  React.useEffect(() => {
    accountService
      .verifyEmail(token)
      .then(() => {
        setStatus('success')
        window.setTimeout(() => {
          navigate('/login')
        }, 5000)
      })
      .catch(() => {
        setStatus('error')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <Page className={classes.root} title={intl.formatMessage({ id: 'verifyEmail.title' })}>
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
          {status === 'processing' && (
            <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
              <CircularProgress />
              <Typography color="textPrimary" variant="h4">
                <FormattedMessage id="global.loading" />
              </Typography>
            </div>
          )}
          {status === 'error' && (
            <Alert className={classes.alert} severity="error">
              <AlertTitle>
                <FormattedMessage id="verifyEmail.error" />
              </AlertTitle>
              <Typography color="textPrimary" variant="h4">
                <FormattedMessage id="verifyEmail.invalid" />
              </Typography>
            </Alert>
          )}
          {status === 'success' && (
            <Alert className={classes.alert} severity="success">
              <AlertTitle>
                <FormattedMessage id="verifyEmail.success" />
              </AlertTitle>
              <Typography color="textPrimary" variant="h4">
                <FormattedMessage id="verifyEmail.successMessage" />
              </Typography>
              <Typography color="textPrimary" variant="h6">
                <Link style={{ textDecoration: 'underline' }} component={LocalizedLink} to="/login">
                  <FormattedMessage id="verifyEmail.redirectMessage" />
                </Link>
              </Typography>
            </Alert>
          )}
        </Container>
      </Box>
    </Page>
  )
}

export default injectIntl(VerifyEmailView)

VerifyEmailView.propTypes = {
  intl: PropTypes.object
}
