const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  controlsUrl: process.env.REACT_APP_CONTROLS_URL,
  controlsApiVersion: process.env.REACT_APP_CONTROLS_API_VERISON,
  supportMailbox: process.env.REACT_APP_SUPPORT_MAILBOX,
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  controlsPollingTime: 60000
}

export default config
