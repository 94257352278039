/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Card, CardContent, CardMedia, makeStyles } from '@material-ui/core'

import Page from 'src/components/Page'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.white,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  media: {
    width: '440px',
    height: '450px',
    backgroundSize: 'inherit'
  },
  mediaUnions: {
    width: '440px',
    height: '450px'
  },
}))

const RegistrationLandingView = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Sign up">
      <Container maxWidth="md" align="center">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="h2">
              <FormattedMessage id="registration.lw.info1" />
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md" align="center">
        <Grid container spacing={1} className={classes.langs}>
          <Grid item xs={6} align="center">
            <Link to="administration">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image="/static/images/administrations.png"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    <FormattedMessage id="registration.lw.amAdministration" />
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={6} align="center">
            <Link to="union">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.mediaUnions}
                  image="/static/images/unions.png"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    <FormattedMessage id="registration.lw.amUnion" />
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default RegistrationLandingView
