/* eslint-disable react/no-unescaped-entities */
import { Card, CardContent, CircularProgress, makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { Alert } from '@material-ui/lab'
import PropTypes from 'prop-types'
import React from 'react'
import { useSnackbar } from 'notistack'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useParams } from 'react-router'
//
import Page from 'src/components/Page'
import { controlsService } from 'src/services'
import ControlDetail from './ControlDetail'
import ControlContext from './ControlContext'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  card: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

const ControlView = ({ intl }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const { controlID } = useParams()
  const [control, setControl] = React.useState()
  const [controlsNotFound, setControlsNotFound] = React.useState(false)
  const [controlsApplicationOffline, setControlsApplicationOffline] = React.useState(false)

  const loadControl = async () => {
    if (controlID !== "new") {
      await controlsService.getById(controlID)
        .then(async response => {
          setControl(response)
        })
        .catch(() => {
          setControlsNotFound(true)
        })
    }
  }

  const doStartControl = async () => {
    if (control) {
      await controlsService.startControl(control)
        .then(async () => {
          enqueueSnackbar(intl.formatMessage({ id: 'controlsView.controlStartedMessage' }), {
            variant: 'info',
            autoHideDuration: 3000
          })
          setTimeout(async () => loadControl(), 500)
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
            variant: 'error',
            autoHideDuration: 10000
          })
        })
    }
  }

  const doRecalculateControlStatus = async (next) => {
    if (control) {
      await controlsService
        .recalculateControlStatus(control)
        .then(
          // setTimeout(
          async () =>
            loadControl()
              .then(next)
          // , 500)
        )
    }
  }

  const doStopControl = async () => {
    if (control) {
      await controlsService.stopControl(control)
        .then(async () => {
          enqueueSnackbar(intl.formatMessage({ id: 'controlsView.controlStoppedMessage' }), {
            variant: 'info',
            autoHideDuration: 3000
          })
          setTimeout(async () => loadControl(), 500)
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
            variant: 'error',
            autoHideDuration: 10000
          })
        })
    }
  }

  const doRestartControl = async () => {
    if (control) {
      await controlsService.restartControl(control)
        .then(async () => {
          enqueueSnackbar(intl.formatMessage({ id: 'controlsView.controlRestartedMessage' }), {
            variant: 'info',
            autoHideDuration: 3000
          })
          setTimeout(async () => loadControl(), 500)
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
            variant: 'error',
            autoHideDuration: 10000
          })
        })
    }
  }

  const doStartVerifications = async (next) => {
    if (control) {
      await controlsService.startVerifications(control)
        .then(async () => {
          enqueueSnackbar(intl.formatMessage({ id: 'controlsView.verificationStartedMessage' }), {
            variant: 'info',
            autoHideDuration: 3000
          })
          setTimeout(async () => loadControl().then(next), 500)
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
            variant: 'error',
            autoHideDuration: 10000
          })
        })
    }
  }

  const doReloadVerification = async (next) => {
    if (control) {
      await controlsService.reloadVerifications(control)
        .then(async () => {
          enqueueSnackbar(intl.formatMessage({ id: 'controlsView.verificationStartedMessage' }), {
            variant: 'info',
            autoHideDuration: 3000
          })
          setTimeout(async () => loadControl().then(next), 500)
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
            variant: 'error',
            autoHideDuration: 10000
          })
        })
    }
  }

  const doReimportAdministrations = async (next) => {
    if (control) {
      await controlsService.startImportAdministrations(control)
        .then(async () => {
          enqueueSnackbar(intl.formatMessage({ id: 'controlsView.reimportAdministrationsStartedMessage' }), {
            variant: 'info',
            autoHideDuration: 3000
          })
          setTimeout(async () => loadControl().then(next), 500)
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
            variant: 'error',
            autoHideDuration: 10000
          })
        })
    }
  }

  const doReimportUnions = async (next) => {
    if (control) {
      await controlsService.startImportUnions(control)
        .then(async () => {
          enqueueSnackbar(intl.formatMessage({ id: 'controlsView.reimportUnionsStartedMessage' }), {
            variant: 'info',
            autoHideDuration: 3000
          })
          setTimeout(async () => loadControl().then(next), 500)
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
            variant: 'error',
            autoHideDuration: 10000
          })
        })
    }
  }

  React.useEffect(() => {
    controlsService.health()
      .then(async isUp => {
        if (isUp) {
          await loadControl()
        } else {
          setControlsApplicationOffline(true)
        }
      }).catch(() => setControlsApplicationOffline(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlID])

  return (
    <Page className={classes.root} title={intl.formatMessage({ id: 'controlsView.controlDetailTitle' })}>
      {controlsApplicationOffline ? (
        <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
          <Alert severity='warning'>
            <Typography color="textPrimary" variant="h4">
              <FormattedMessage id="controlsView.controlsApplicationOffline" />
            </Typography>
          </Alert>
        </div>
      ) : <>
        {controlsNotFound ? (
          <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
            <Alert severity='warning'>
              <Typography color="textPrimary" variant="h4">
                <FormattedMessage id="controlsView.controlNotFound" />
              </Typography>
            </Alert>
          </div>
        ) : <>
          {!control &&
            < div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
              <CircularProgress />
            </div>
          }
          {control && <>
            <Container maxWidth="md" >
              <Card className={classes.card} >
                <CardContent>
                  <ControlContext.Provider value={control}>
                    <ControlDetail
                      doStartControl={doStartControl}
                      doStopControl={doStopControl}
                      doRestartControl={doRestartControl}
                      doRecalculateControlStatus={doRecalculateControlStatus}
                      doStartVerifications={doStartVerifications}
                      doReloadVerification={doReloadVerification}
                      doReimportAdministrations={doReimportAdministrations}
                      doReimportUnions={doReimportUnions}
                    />
                  </ControlContext.Provider>
                  <br />
                  <br />
                </CardContent>
              </Card>
            </Container>
          </>
          }
        </>}
      </>}
    </Page>
  )
}
export default injectIntl(ControlView)

ControlView.propTypes = {
  intl: PropTypes.object
}
