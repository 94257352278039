import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Box, Button, Container, TextField, Typography, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useLocation } from 'react-router-dom'
//
import Page from 'src/components/Page'
import { accountService } from 'src/services'
import { useLocalizedNavigate } from 'src/i18n'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  alert: {
    marginBottom: theme.spacing(2)
  }
}))

const MfaView = ({ intl }) => {
  const classes = useStyles()
  const navigate = useLocalizedNavigate()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const [user, setUser] = React.useState(null)

  React.useEffect(() => {
    const stateUser = location?.state?.user
    if (stateUser) {
      setUser(stateUser)
    } else {
      enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), { variant: 'error' })
      window.setTimeout(() => {
        navigate('/login')
      }, 500)
    }
    // eslint-disable-next-line
  }, [location])

  if (!user) {
    return null
  }

  return (
    <Page className={classes.root} title="Login">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              code: ''
            }}
            validationSchema={Yup.object().shape({
              code: Yup.string()
                .max(6)
                .required(intl.formatMessage({ id: 'validations.field' }))
                .min(6, intl.formatMessage({ id: 'validations.min' }, { number: '6' }))
            })}
            onSubmit={async ({ code }, { setSubmitting }) => {
              accountService
                .validatetMfaCode(user.id, code)
                .then(() => {
                  accountService.finalizeLogin(user)
                  navigate('/app')
                })
                .catch(() => {
                  enqueueSnackbar(intl.formatMessage({ id: 'mfa.invalidCode' }), { variant: 'error' })
                })
              setSubmitting(false)
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    <FormattedMessage id="mfa.title" />
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    <FormattedMessage id="mfa.enterCode" />
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.code && errors.code)}
                  fullWidth
                  autoFocus
                  helperText={touched.code && errors.code}
                  label={<FormattedMessage id="mfa.6digitcode" />}
                  margin="normal"
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.code}
                  variant="outlined"
                  inputProps={{
                    autoComplete: 'off'
                  }}
                />

                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    startIcon={isSubmitting ? <CircularProgress size={14} color="secondary" /> : null}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    <FormattedMessage id="buttons.continue" />
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  )
}

export default injectIntl(MfaView)

MfaView.propTypes = {
  intl: PropTypes.object
}
