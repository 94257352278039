import config from 'src/utils/config'
import fetchWrapper from 'src/utils/fetch'

const currVersionUrl = `${config.controlsUrl}/${config.controlsApiVersion}`
const controlsUrl = `${currVersionUrl}/controls`
const kafkaUrl = `${currVersionUrl}/kafka`

// actuator
function health() {
  return fetchWrapper.get(`${config.controlsUrl}/health`)
    .then(res => res.status === 'UP')
    .catch(() => false)
}

// controls crud
function getAll() {
  return fetchWrapper.get(controlsUrl)
}
function getById(id) {
  return fetchWrapper.get(`${controlsUrl}/${id}`)
}
function create(params) {
  return fetchWrapper.post(controlsUrl, params)
}
function update(id, params) {
  return fetchWrapper.put(`${controlsUrl}/${id}`, params)
}
function deleteControlById(id) {
  return fetchWrapper.delete(`${controlsUrl}/${id}`)
}

// controls operations
function startControl(control, params) {
  return fetchWrapper.post(`${controlsUrl}/${control.id}/start`, params)
}
function stopControl(control, params) {
  return fetchWrapper.post(`${controlsUrl}/${control.id}/stop`, params)
}
function restartControl(control, params) {
  return fetchWrapper.post(`${controlsUrl}/${control.id}/restart`, params)
}
function recalculateControlStatus(control, params) {
  return fetchWrapper.post(`${controlsUrl}/${control.id}/status/recalculate`, params)
}

// administrations
function getAllAdministrations(control) {
  return fetchWrapper.get(`${controlsUrl}/${control.id}/administrations`)
}
function statsForAdministrations(control) {
  return fetchWrapper.get(`${controlsUrl}/${control.id}/administrations/stats`)
}
function startImportAdministrations(control) {
  return fetchWrapper.post(`${controlsUrl}/${control.id}/administrations/import/start`)
}
function downloadAdministrationsCSVReport(control) {
  const fileName = `ImportedAdministrations-${control.controlYear}-${control.id}.csv`
  return fetchWrapper.getOriginalFile(`${controlsUrl}/${control.id}/administrations/import/report`, fileName)
}

// unions
function getAllUnions(control) {
  return fetchWrapper.get(`${controlsUrl}/${control.id}/unions`)
}
function statsForUnions(control) {
  return fetchWrapper.get(`${controlsUrl}/${control.id}/unions/stats`)
}
function startImportUnions(control) {
  return fetchWrapper.post(`${controlsUrl}/${control.id}/unions/import/start`)
}
function downloadUnionsCSVReport(control) {
  const fileName = `ImportedUnions-${control.controlYear}-${control.id}.csv`
  return fetchWrapper.getOriginalFile(`${controlsUrl}/${control.id}/unions/import/report`, fileName)
}

// verifications
function startVerifications(control) {
  return fetchWrapper.post(`${controlsUrl}/${control.id}/unions/verification/start`)
}
function stopVerifications(control) {
  return fetchWrapper.post(`${controlsUrl}/${control.id}/unions/verification/stop`)
}
function reloadVerifications(control) {
  return fetchWrapper.post(`${controlsUrl}/${control.id}/unions/verification/load`)
}
function downloadVerificationCSVReport(control) {
  const fileName = `ControlVerification-${control.controlYear}-${control.id}.csv`
  return fetchWrapper.getOriginalFile(`${controlsUrl}/${control.id}/unions/verification/report`, fileName)
}
function downloadVerificationCSVReportByType(control, reportType, reportName) {
  const fileName = `${reportName || reportType} - ${control.controlYear} - ${control.id}.csv`
  return fetchWrapper.getOriginalFile(`${controlsUrl}/${control.id}/unions/verification/report/${reportType}`, fileName)
}

// kafka
function getTopicsStats() {
  return fetchWrapper.get(`${kafkaUrl}/topics/stats`)
}

export default {
  health,
  getAll,
  getById,
  create,
  startControl,
  stopControl,
  restartControl,
  recalculateControlStatus,
  update,
  deleteControlById,
  getAllAdministrations,
  statsForAdministrations,
  getAllUnions,
  statsForUnions,
  startVerifications,
  stopVerifications,
  reloadVerifications,
  getTopicsStats,
  downloadAdministrationsCSVReport,
  downloadUnionsCSVReport,
  downloadVerificationCSVReport,
  downloadVerificationCSVReportByType,
  startImportAdministrations,
  startImportUnions
}
