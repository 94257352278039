import config from 'src/utils/config'
import fetchWrapper from 'src/utils/fetch'

const baseUrl = `${config.apiUrl}/accounts-unions`

function getAll() {
  return fetchWrapper.get(baseUrl)
}

function getByUserId(id) {
  return fetchWrapper.get(baseUrl, { account: id })
}

function getByUnionId(id) {
  return fetchWrapper.get(baseUrl, { union: id })
}

function getByStatus(status) {
  return fetchWrapper.get(baseUrl, { status })
}

function createAccountsUnionRecord({ account, union }) {
  return fetchWrapper.post(baseUrl, { account, union })
}

function updateAccountsUnions(data) {
  return fetchWrapper.put(`${baseUrl}/`, data)
}

function deleteAccountsUnions(data) {
  return fetchWrapper.post(`${baseUrl}/delete`, data)
}

function getByQuery(query) {
  return fetchWrapper.get(`${baseUrl}`, query)
}

export default {
  getAll,
  getByUserId,
  getByUnionId,
  getByStatus,
  getByQuery,
  createAccountsUnionRecord,
  updateAccountsUnions,
  deleteAccountsUnions,
}
