import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  TextField
} from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import { Formik } from 'formik'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as Yup from 'yup'
//

const AddPremiumRowDialog = ({ premiumRow, editMode, open, handleClose, intl }) => {
  const { id } = premiumRow

  return (
    <Dialog fullWidth maxWidth="lg" onClose={() => handleClose(false)} open={open}>
      <DialogTitle>
        {editMode ? <FormattedMessage id="controlsView.premium.editTitle" /> : <FormattedMessage id="controlsView.premium.addTitle" />}
      </DialogTitle>
      <Container>
        <Grid container xl>
          <Grid item xs={12}>
            <Formik
              onSubmit={async ({ name, cotisationMin, cotisationMax, prime }) => {
                const data = {
                  name,
                  cotisationMin,
                  cotisationMax,
                  prime,
                }
                const mergedRow = { id, ...data }
                handleClose(true, mergedRow)
              }}
              initialValues={{
                // eslint-disable-next-line no-restricted-globals
                name: premiumRow.name,
                cotisationMin: premiumRow.cotisationMin || null,
                cotisationMax: premiumRow.cotisationMax || null,
                prime: premiumRow.prime,
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .required(intl.formatMessage({ id: 'validations.field' })),
                cotisationMin: Yup.string().nullable().max(6),
                cotisationMax: Yup.string().nullable().max(6),
                prime: Yup.string().max(6).required()
              })}
            >
              {({ handleSubmit, values, touched, errors, handleBlur, handleChange }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            label={intl.formatMessage({ id: 'controlsView.premium.name' })}
                            name="name"
                            required
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12} />
                        <Grid item md={4} xs={12}>
                          <TextField
                            error={Boolean(touched.prime && errors.prime)}
                            fullWidth
                            helperText={touched.prime && errors.prime}
                            label={intl.formatMessage({ id: 'controlsView.premium.prime' })}
                            name="prime"
                            required
                            value={values.prime}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            error={Boolean(touched.cotisationMax && errors.cotisationMax)}
                            fullWidth
                            helperText={touched.cotisationMax && errors.cotisationMax}
                            label={intl.formatMessage({ id: 'controlsView.premium.cotisationMax' })}
                            name="cotisationMax"
                            value={values.cotisationMax || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            error={Boolean(touched.cotisationMin && errors.cotisationMin)}
                            fullWidth
                            helperText={touched.cotisationMin && errors.cotisationMin}
                            label={intl.formatMessage({ id: 'controlsView.premium.cotisationMin' })}
                            name="cotisationMin"
                            value={values.cotisationMin || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>

                      </Grid>
                    </CardContent>
                  </Card>

                  <Box my={2} mt={3}>
                    <DialogActions>
                      <Box px={2}>
                        <Button onClick={() => handleClose(false)} color="primary">
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </Box>
                      <Button color="primary" size="large" type="submit" variant="contained">
                        <FormattedMessage id="buttons.update" />
                      </Button>
                    </DialogActions>
                  </Box>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  )
}

export default injectIntl(AddPremiumRowDialog)

AddPremiumRowDialog.propTypes = {
  premiumRow: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object
}
