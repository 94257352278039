import React from 'react'
import PropTypes from 'prop-types'
import { DataGrid } from '@material-ui/data-grid'
import { makeStyles, Typography, Box, IconButton } from '@material-ui/core'
import { injectIntl, FormattedMessage } from 'react-intl'
import { CloudDownload } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
//
import { uploadService } from 'src/services'

const useStyles = makeStyles(theme => ({
  alert: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const ProcessedRecordsGrid = ({ upload, intl }) => {
  const classes = useStyles()

  const [uploadData, setUploadData] = React.useState(null)

  const columns = [
    { field: 'sequenceNR', headerName: intl.formatMessage({ id: 'dataGrid.nr' }), width: 100 },
    { field: 'firstname', headerName: intl.formatMessage({ id: 'dataGrid.firstName' }), width: 250 },
    { field: 'lastname', headerName: intl.formatMessage({ id: 'dataGrid.lastName' }), width: 250 },
    { field: 'dob', headerName: intl.formatMessage({ id: 'dataGrid.dob' }), width: 190 },
    { field: 'postcode', headerName: intl.formatMessage({ id: 'dataGrid.postCode' }), width: 140 },
    { field: 'year', headerName: intl.formatMessage({ id: 'dataGrid.year' }), width: 100 },
    { field: 'language', headerName: intl.formatMessage({ id: 'dataGrid.language' }), width: 140 },
    // { field: 'uniqueformID', headerName: intl.formatMessage({ id: 'dataGrid.uniqueformID' }), width: 180 }
  ]

  React.useEffect(() => {
    if (typeof upload === 'string') {
      uploadService
        .getById(upload)
        .then(res => {
          // eslint-disable-next-line no-underscore-dangle
          setUploadData({ id: res._id, ...res })
        })
        .catch(() => { })
    } else {
      setUploadData(upload)
    }
  }, [upload])

  if (!uploadData) {
    return (
      <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
        <CircularProgress />
        <Typography color="textPrimary" variant="h4">
          <FormattedMessage id="global.loading" />
        </Typography>
      </div>
    )
  }

  const { error, size, errors, records, id, filePath, status } = uploadData

  if (status === 'ERROR') {

    if (error && error === 'size') {
      return (
        <Alert severity="error" className={classes.alert}>
          <Typography color="textPrimary" variant="h5">
            <FormattedMessage id="uploads.errorSize" values={{ total: size }} />
          </Typography>
        </Alert>
      )
    }

    const rows = errors.map((rowError, idx) => {
      return { id: idx, ...rowError }
    })

    return (
      <Box>
        <Alert severity="error" className={classes.alert}>
          <Typography color="textPrimary" variant="h5">
            <FormattedMessage id="uploads.errors" />
          </Typography>
        </Alert>
        <div style={{ height: 250, width: '100%' }}>
          <div style={{ height: 250, width: '100%' }}>
            <DataGrid
              disableSelectionOnClick
              rowsPerPageOptions={[10, 20, 50, 100]}
              columns={columns}
              rows={rows}
              pageSize={10}
              headerHeight={42}
              rowHeight={30}
            />
          </div>
        </div>
      </Box>
    )
  }
  if (status === 'PROCESSED') {
    const rows = records.map((record, idx) => {
      return { id: idx, ...record, sequenceNR: parseInt(record.sequenceNR, 10) }
    })

    return (< Box >
      <div>
        <Typography variant="h5">
          <FormattedMessage id="uploads.processedTitle" />
        </Typography>
        <IconButton onClick={() => uploadService.download(id, filePath)}>
          <CloudDownload style={{ marginRight: '8px' }} color="primary" />
          <Typography>
            <FormattedMessage id="uploads.download" />
          </Typography>
        </IconButton>
      </div>
      <div style={{ height: 550, width: '100%' }}>
        <div style={{ height: 500, width: '100%' }}>
          <DataGrid
            disableSelectionOnClick
            rowsPerPageOptions={[10, 20, 50, 100]}
            columns={columns}
            rows={rows}
            pageSize={10}
            headerHeight={42}
            rowHeight={30}
          />
        </div>
      </div>
    </Box >)
  }
  return null
}

export default injectIntl(ProcessedRecordsGrid)

ProcessedRecordsGrid.propTypes = {
  upload: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  intl: PropTypes.object
}
