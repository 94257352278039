import React from 'react'
import PropTypes from 'prop-types'
import { Box, Breadcrumbs, Button, CircularProgress, Container, Grid, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { injectIntl, FormattedMessage } from 'react-intl'
//
import Page from 'src/components/Page'
import { controlsService } from 'src/services'
import { Alert } from '@material-ui/lab'
import { NavigateNext } from '@material-ui/icons'
import { useLocalizedNavigate } from 'src/i18n'
import ControlsDataTable from './ControlsDataTable'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  card: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

const sortByReferenceYear = (a, b) => {
  if (!a.controlYear) return -1
  if (!b.controlYear) return 1
  return (b.controlYear - a.controlYear)
}

const sortByCreated = (a, b) => {
  return new Date(b.created) - new Date(a.created)
}

const ControlsView = ({ intl }) => {
  const classes = useStyles()
  const navigate = useLocalizedNavigate()

  const [loading, setLoading] = React.useState(false)
  const [controls, setControls] = React.useState()
  const [controlsNotFound, setControlsNotFound] = React.useState(false)
  const [controlsApplicationOffline, setControlsApplicationOffline] = React.useState(false)

  const loadControls = async () => {
    controlsService.getAll()
      .then(async response => {
        const sortedRecords = response
          .map(control => ({
            ...control,
            actions: ''
          }))
          .sort(sortByCreated)
          .sort(sortByReferenceYear)

        const hasControls = sortedRecords.length > 0
        setControls(hasControls ? sortedRecords : null)
        setControlsNotFound(!hasControls)
        setLoading(false)
      })
      .catch(() => {
        setControlsApplicationOffline(true)
        setLoading(false)
      })
  }

  React.useEffect(() => {
    setLoading(true)
    controlsService.health()
      .then(isUp => {
        if (isUp) {
          loadControls()
        } else {
          setControlsApplicationOffline(true)
          setLoading(false)
        }
      }).catch(() => {
        setControlsApplicationOffline(true)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page className={classes.root} title={intl.formatMessage({ id: 'navigation.controls' })}>
      {controlsApplicationOffline ? (
        <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
          <Alert severity='warning'>
            <Typography color="textPrimary" variant="h4">
              <FormattedMessage id="controlsView.controlsApplicationOffline" />
            </Typography>
          </Alert>
        </div>
      ) : <>
        {(!controls && loading) ? <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress />
        </div>
          : (
            <Container maxWidth="xl">
              <Grid container spacing={1}>
                <Grid item xs={9}>
                  <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                    <Typography color="textPrimary" variant="h3" >
                      <FormattedMessage id="navigation.controls" />
                    </Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid item xs={3}>
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button color="primary" size="large" type="submit" variant="contained" onClick={() => {
                      navigate(`/app/controls/new`, { replace: false });
                    }} >
                      <FormattedMessage id="controlsView.configureControlButton" />
                    </Button>
                  </Box>
                </Grid>
                {controlsNotFound &&
                  <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
                    <br />
                    <Alert severity='info'>
                      <Typography color="textPrimary" variant="h4">
                        <FormattedMessage id="controlsView.noControlsFound" />
                      </Typography>
                    </Alert>
                  </div>
                }
                {!controlsNotFound && !controls &&
                  <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                }
                {controls && <>
                  <Grid item xs={12}>
                    <ControlsDataTable
                      controls={controls}
                      loading={loading}
                      setLoading={setLoading}
                      loadControls={loadControls}
                    />
                  </Grid>
                  <br />
                  <br />
                </>}
              </Grid>
            </Container>
          )}
      </>
      }
    </Page >
  )
}

export default injectIntl(ControlsView)

ControlsView.propTypes = {
  intl: PropTypes.object
}
