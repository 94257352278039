import React from 'react'
import { Route, Routes } from 'react-router-dom'
//
import ProtectedRoute from 'src/components/ProtectedRoute'
import { enabledLocales } from 'src/i18n'

import DashboardLayout from 'src/layouts/DashboardLayout'
import MainLayout from 'src/layouts/MainLayout'
import AccountView from 'src/views/account/AccountView'
import LandingView from 'src/views/pages/LandingView'
import RegistrationLandingView from 'src/views/auth/RegistrationLandingView'
import LoginView from 'src/views/auth/LoginView'
import MfaView from 'src/views/auth/MfaView'
import NotFoundView from 'src/views/errors/NotFoundView'
import RegisterView from 'src/views/auth/RegisterView'
import VerifyEmailView from 'src/views/auth/VerifyEmailView'
import ForgotPasswordView from 'src/views/auth/ForgotPasswordView'
import ResetPasswordView from 'src/views/auth/ResetPasswordView'
import SettingsView from 'src/views/settings/SettingsView'
import AdministrationsView from './views/administrations/AdministrationsView'
import BulkUploadView from './views/administrations/BulkUploadView'
import AccessRequestsView from './views/administrations/AccessRequestsView'
import AdministrationView from './views/administrations/AdministrationView'
import ManageAdministrationView from './views/administrations/ManageAdministrationView'
import ManageUnionsView from './views/administrations/ManageUnionsView'
import TermsAndConditionsView from './views/pages/TermsAndConditionsView'
import HelpView from './views/pages/HelpView'
import LoggedUserLandingView from './views/pages/LoggedUserLandingView'
import AssetsView from './views/settings/AssetsView'
import UnionView from './views/unions/UnionView'
import UnionPaymentView from './views/unions/UnionPaymentView'
import ControlsView from './views/controls/ControlsView'
import ControlView from './views/controls/ControlView'
import CreateControl from './views/controls/CreateControl'

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<MainLayout />}>
      <Route path="/" element={<LandingView />} />
    </Route>
    {enabledLocales.map(lang => (
      <React.Fragment key={`routes-${lang}`}>
        <ProtectedRoute path={`/${lang}/app`} element={<DashboardLayout />} roles={['Admin', 'User', 'Union']}>
          <Route path="account" element={<AccountView />} />
          <ProtectedRoute path="access-requests" element={<AccessRequestsView />} roles={['Admin']} />
          <ProtectedRoute path="administrations" element={<AdministrationsView />} roles={['User']} />
          <ProtectedRoute path="union" element={<UnionView />} roles={['Union']} />
          <ProtectedRoute path="union-payments" element={<UnionPaymentView />} roles={['Union']} />
          <ProtectedRoute path="bulk-upload" element={<BulkUploadView />} roles={['User']} />
          <ProtectedRoute path="administrations/:code" element={<AdministrationView />} roles={['User']} onlyAuthorizedAdministrations />
          <ProtectedRoute path="settings" element={<SettingsView />} roles={['Admin']} />
          <ProtectedRoute path="manage-administrations" element={<ManageAdministrationView />} roles={['Admin']} />
          <ProtectedRoute path="manage-unions" element={<ManageUnionsView />} roles={['Admin']} />
          <Route path="terms-and-conditions" element={<TermsAndConditionsView />} />
          <ProtectedRoute path="assets" element={<AssetsView />} roles={['Admin']} />
          <ProtectedRoute path="controls" element={<ControlsView />} roles={['Admin']} />
          <ProtectedRoute path="controls/new" element={<CreateControl />} roles={['Admin']} />
          <ProtectedRoute path="controls/:controlID" element={<ControlView />} roles={['Admin']} />
          <Route path="help" element={<HelpView />} />
          <Route path="/" element={<LoggedUserLandingView />} />
          <Route path="*" element={<NotFoundView />} />
        </ProtectedRoute>
        <Route path={`/${lang}`} element={<MainLayout />}>
          <Route path="login" element={<LoginView />} />
          <Route path="login/2fa" element={<MfaView />} />
          <Route path="verify-email" element={<VerifyEmailView />} />
          <Route path="forgot-password" element={<ForgotPasswordView />} />
          <Route path="reset-password" element={<ResetPasswordView />} />
          <Route path="register" element={<RegistrationLandingView />}/>
          <Route path="register/administration" element={<RegisterView isAdministration/>} />
          <Route path="register/union" element={<RegisterView isUnion />} />
          <Route path="terms-and-conditions" element={<TermsAndConditionsView />} />
          <Route path="help" element={<HelpView />} />
          <Route path="/" element={<LandingView />} />
          <Route path="*" element={<LandingView />} />
        </Route>
      </React.Fragment>
    ))}
  </Routes>
)

export default AppRoutes
