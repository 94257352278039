import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert'
import { injectIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
//
import { settingsService } from 'src/services'

const useStyles = makeStyles(() => ({
  content: {
    padding: '1rem'
  }
}))

const InfoMessage = ({ intl }) => {
  const classes = useStyles()
  const location = useLocation()
  const [message, setMessage] = React.useState('')
  const { locale } = intl

  React.useEffect(() => {
    settingsService
      .getInfoMessage()
      .then(res => {
        if (res.value && res.value[locale]) {
          setMessage(res.value[locale])
        }
      })
      .catch(() => setMessage(''))
  }, [locale])

  if (!message || !message.length || location.pathname === '/') {
    return null
  }

  return (
    <div className={classes.content}>
      <Container maxWidth="lg" className={classes.root}>
        <Alert severity="info">{message}</Alert>
      </Container>
    </div>
  )
}

export default injectIntl(InfoMessage)

InfoMessage.propTypes = {
  intl: PropTypes.object
}
