const locales = {
  nl: {
    id: 'nl',
    name: 'Dutch',
    path: 'nl',
    enabled: true
  },
  fr: {
    id: 'fr',
    name: 'French',
    path: 'fr',
    enabled: true
  },
  de: {
    id: 'de',
    name: 'German',
    path: 'de',
    enabled: true
  },
  en: {
    id: 'en',
    name: 'English',
    path: 'en',
    enabled: true
  }
}

export default locales
