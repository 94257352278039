import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Divider, Card, CardHeader, TextField, CardContent } from '@material-ui/core'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { useSnackbar } from 'notistack'
import { injectIntl, FormattedMessage } from 'react-intl'
import { useConfirm } from 'material-ui-confirm'
//
import { accountService } from 'src/services'

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions)

const Mfa = ({ intl }) => {
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirm()
  const user = accountService.userValue
  const { mfaEnabled } = user
  const [showModal, setShowModal] = React.useState(false)
  const [code, setCode] = React.useState(null)

  const handleShowModal = () => {
    accountService
      .getMfaCode()
      .then(res => {
        setCode(res)
        setShowModal(true)
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), { variant: 'error' })
      })
  }

  const handleDisableMfa = () => {
    confirm({
      title: intl.formatMessage({ id: 'confirmations.title' }),
      description: intl.formatMessage({ id: 'mfa.deleteMessageDescription' })
    }).then(() => {
      accountService
        .disableMfaCode(user.id)
        .then(() => {
          enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), { variant: 'error' })
        })
    })
  }

  return (
    <div>
      <Card>
        <CardHeader
          subheader={intl.formatMessage({ id: 'mfa.intro' })}
          title={intl.formatMessage({ id: 'mfa.label' })}
        />
        <Divider />
        <CardContent>
          <Box>
            {mfaEnabled ? (
              <div>
                <Typography gutterBottom>
                  <FormattedMessage id="mfa.disabledMessage" />
                </Typography>
                <br/>
                <br/>
                <Button style={{ color: 'red' }} size="large" variant="contained" onClick={handleDisableMfa}>
                  <FormattedMessage id="mfa.disable" />
                </Button>
              </div>
            ) : (
              <Button color="primary" size="large" variant="contained" onClick={handleShowModal}>
                <FormattedMessage id="mfa.enable" />
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
      {code ? (
        <Dialog onClose={() => setShowModal(false)} aria-labelledby="customized-dialog-title" open={!!showModal}>
          <Formik
            initialValues={{
              userToken: ''
            }}
            validationSchema={Yup.object().shape({
              userToken: Yup.string()
                .max(6)
                .required(intl.formatMessage({ id: 'validations.field' }))
                .min(6, intl.formatMessage({ id: 'validations.min' }, { number: '6' }))
            })}
            onSubmit={({ userToken }, { setSubmitting, resetForm }) => {
              setSubmitting(true)
              accountService
                .enableMfaCode(code.code, userToken)
                .then(() => {
                  enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
                  resetForm({})
                  setShowModal(false)
                })
                .catch(() => {
                  enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), { variant: 'error' })
                  resetForm({})
                })
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <DialogTitle id="customized-dialog-title" onClose={() => setShowModal(false)}>
                  <FormattedMessage id="mfa.enable" />
                </DialogTitle>

                <DialogContent dividers>
                  <Typography gutterBottom>
                    <FormattedMessage id="mfa.modalInfo1" />
                  </Typography>
                  <Typography gutterBottom>
                    <FormattedMessage id="mfa.modalInfo2" />
                  </Typography>
                  <Box style={{ textAlign: 'center' }}>
                    <img src={code.qrCode} alt="2fa QRCode" />
                  </Box>
                  <Typography gutterBottom>
                    <FormattedMessage id="mfa.modalInfo3" />
                  </Typography>
                  <Box style={{ textAlign: 'center' }}>
                    <Typography gutterBottom variant="h5">
                      {code.code}
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.userToken && errors.userToken)}
                    fullWidth
                    helperText={touched.userToken && errors.userToken}
                    label={intl.formatMessage({ id: 'mfa.code' })}
                    name="userToken"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.userToken}
                    variant="outlined"
                    inputProps={{
                      autoComplete: 'off'
                    }}
                  />
                  <Typography gutterBottom>
                    <FormattedMessage id="mfa.modalInfo4" />
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={() => setShowModal(false)} color="primary">
                    <FormattedMessage id="buttons.cancel" />
                  </Button>
                  <Button color="primary" size="large" type="submit" variant="contained" autoFocus>
                    <FormattedMessage id="buttons.enable" />
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Dialog>
      ) : null}
    </div>
  )
}

Mfa.propTypes = {
  intl: PropTypes.object
}

export default injectIntl(Mfa)
