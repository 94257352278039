export default code => {
    return (
      code &&
      Object.values(code)
        .join(' / ')
        .trim()
        .replace(/ ?\/$/, '')
        .replace(/^\//, '')
    )
  }
