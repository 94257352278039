// Polyfills for ie11
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'element-closest-polyfill'
import 'url-search-params-polyfill'
//
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import smoothscroll from 'smoothscroll-polyfill'
import history from 'src/utils/history'
import { accountService } from 'src/services'
import * as serviceWorker from './serviceWorker'
import App from './App'

// Polyfill for ie11 and edge
smoothscroll.polyfill()

function startApp() {
  ReactDOM.render(
    <BrowserRouter history={history}>
      <SnackbarProvider
        preventDuplicate
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <App />
      </SnackbarProvider>
    </BrowserRouter>,
    document.getElementById('root')
  )
}

accountService.refreshToken().finally(startApp)

serviceWorker.unregister()
