export default name => {
  return (
    name &&
    Object.values(name)
      .join(' / ')
      .trim()
      .replace(/ ?\/$/, '')
      .replace(/^\//, '')
  )
}
