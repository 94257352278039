import React from 'react'
import PropTypes from 'prop-types'
import { Container, List, ListItem, ListItemText, Modal, Paper, Box, makeStyles, Divider } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined'
import Typography from '@material-ui/core/Typography'
import { injectIntl, FormattedMessage } from 'react-intl'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
//
import { useSnackbar } from 'notistack'
import { uploadService } from 'src/services'
import ProcessedRecordsGrid from './ProcessedRecordsGrid'
import useInterval from '../../../utils/useInterval'
import ErrorsRecordsGrid from './ErrorsRecordsGrid'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))

function formattedDate(date) {
  const mm = date.getMonth() + 1
  const dd = date.getDate()
  const hh = date.getHours()
  const min = date.getMinutes()

  const day = [(dd > 9 ? '' : '0') + dd, (mm > 9 ? '' : '0') + mm, date.getFullYear()].join('-')
  const time = [(hh > 9 ? '' : '0') + hh, (min > 9 ? '' : '0') + min].join(':')

  return `${day} ${time}`
}

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const PreviousUploads = ({ intl, newUpload, handleProcessingStatus, useOverflow, union, handleUploadChanged }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = React.useState(true)
  const [previousUploads, setPreviousUploads] = React.useState([])
  const [selectedUploadId, setSelectedUploadId] = React.useState()
  const [selectedUpload, setSelectedUpload] = React.useState()

  const getUploads = async () => {
    // eslint-disable-next-line no-underscore-dangle
    uploadService.getByQueryForUnion({ union: union._id })
      .then(async uploads => {
        const uploadsByQuery = uploads
          .map(upload => {
            // eslint-disable-next-line no-underscore-dangle
            return { id: upload._id, date: formattedDate(new Date(upload.created)), ...upload }
          })
          .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())

        const checkStatusUpload = async (pastState, newState) => {
          if (pastState && pastState.length && newState) {
            const pastProcessedUploads = pastState.filter(({ status }) => status !== "PROCESSING")
            const currentProcessedUploads = newState.filter(({ status }) => status !== "PROCESSING")
            if (pastProcessedUploads.length === currentProcessedUploads.length - 1) {
              const recordNoLongerProcessing = currentProcessedUploads.reduce((acc, curr) => {
                // eslint-disable-next-line no-underscore-dangle
                return pastProcessedUploads.find(({ _id }) => _id === curr._id) ? acc : curr
              }, {})
              if (Object.keys(recordNoLongerProcessing).length) {
                // eslint-disable-next-line no-underscore-dangle
                const newUploadChanged = await uploadService.getByIdForUnion(recordNoLongerProcessing._id)
                handleUploadChanged(newUploadChanged)
                if (newUploadChanged.status === 'ERROR') {
                  enqueueSnackbar(intl.formatMessage({ id: 'uploads.errors' }), {
                    variant: 'error',
                    autoHideDuration: 10 * 1000
                  })
                } else if (newUploadChanged.status === 'PROCESSED') {
                  enqueueSnackbar(intl.formatMessage({ id: 'uploads.success' }, { total: newUploadChanged.size }), {
                    variant: 'success',
                    autoHideDuration: 10 * 1000
                  })
                }
              }
            }
          }
        }
        await checkStatusUpload(previousUploads, uploadsByQuery)

        setPreviousUploads(uploadsByQuery)
        setLoading(false)
        const running = uploadsByQuery.filter(upload => upload.status === 'PROCESSING').length
        if (!running) {
          handleProcessingStatus(true)
        }
      })
  }


  React.useEffect(() => {
    getUploads()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUpload])

  React.useEffect(() => {
    const refreshFromDb = async () => {
      if (selectedUploadId) {
        const uploadFromDB = await uploadService.getByIdForUnion(selectedUploadId)
        setSelectedUpload(uploadFromDB)
      } else {
        setSelectedUpload(null)
      }
    }
    refreshFromDb()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUploadId])

  const processing = previousUploads.filter(upload => upload.status === 'PROCESSING')
  const processed = previousUploads.filter(upload => ['PROCESSED', 'ERROR'].includes(upload.status))

  useInterval(
    async () => {
      await getUploads()
      if (!processing.length) {
        handleProcessingStatus(true)
      }
    },
    processing.length ? 15000 : null
  )

  return (
    <>
      {loading && (
        <div>
          <CircularProgress size={20} />
          <FormattedMessage id="global.loading" />
        </div>
      )}

      {!loading && processing && processing.length ? (
        <>
          <Typography color="textSecondary" gutterBottom variant="h5">
            <FormattedMessage id="unionsView.processingUploads" />
          </Typography>

          <LinearProgress size={16} color="secondary" />

          <List component="nav" aria-label="main">
            {processing.map(upload => (
              <ListItem key={upload.id} style={{ padding: '2px' }} dense>
                <ListItemIcon style={{ minWidth: '32px' }}>
                  <ScheduleOutlinedIcon color="error" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <small>
                      {upload.date} -{' '}
                      <FormattedMessage
                        id="unionsView.numberRows"
                        values={{ number: upload.size ? formatNumber(upload.size) : 'N/A' }}
                      />
                    </small>
                  }
                />
              </ListItem>
            ))}
          </List>

          <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
        </>
      ) : null}

      {!loading && processed && (
        <>
          <Typography color="textSecondary" gutterBottom variant="h5">
            <FormattedMessage id="unionsView.previousUploads" />
          </Typography>

          <List component="nav" aria-label="main" style={{ overflow: useOverflow ? 'auto' : undefined, maxHeight: useOverflow ? '30vh' : undefined }}>
            {processed.map(upload => (
              <ListItem key={upload.id} style={{ padding: '2px' }} onClick={() => setSelectedUploadId(upload.id)} button>
                <ListItemIcon style={{ minWidth: '32px' }}>
                  {upload.status === "PROCESSED" ? <img src="/static/images/csv_icon.png" width="30px" alt='csv' /> : null}
                  {upload.status === "ERROR" ? <img src="/static/images/csv_icon_red.png" width="30px" alt='csv' /> : null}
                </ListItemIcon>
                <ListItemText
                  primary={<small>{upload.originalFileName}</small>}
                  secondary={
                    <small>
                      {upload.date}
                      {(upload.size || upload.status === "ERROR") ? ' - ' : null}
                      {upload.size ? (
                        <FormattedMessage
                          id="unionsView.numberRows"
                          values={{ number: formatNumber(upload.size) }}
                        />
                      ) : null}
                      {upload.status === "ERROR" ? (<span style={{ color: "#d32f2f" }}>{upload.status}</span>) : null}
                    </small>
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      )}

      <Modal
        open={!!selectedUploadId}
        onClose={() => setSelectedUploadId()}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Container maxWidth="lg" style={{ outline: 0 }}>
          {selectedUpload ? (
            <Paper>
              <MuiDialogTitle disableTypography style={{ position: 'relative' }}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => setSelectedUploadId()}>
                  <CloseIcon />
                </IconButton>
              </MuiDialogTitle>
              <Box padding={2}>
                {selectedUpload && selectedUpload.status === 'PROCESSED' && <ProcessedRecordsGrid upload={selectedUploadId} />}
                {selectedUpload && selectedUpload.status === 'ERROR' && <>
                  <Typography variant="h5">
                    <FormattedMessage id="uploads.errorTitle" />
                  </Typography>
                  <ErrorsRecordsGrid upload={selectedUpload} />
                </>
                }
              </Box>
            </Paper>
          ) : <Box />}
        </Container>
      </Modal>
    </>
  )
}

PreviousUploads.propTypes = {
  intl: PropTypes.object,
  newUpload: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  handleProcessingStatus: PropTypes.func,
  useOverflow: PropTypes.bool,
  union: PropTypes.object,
  handleUploadChanged: PropTypes.func,
}

export default injectIntl(PreviousUploads)
