import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import {
  Button,
  FormControl,
  Grid,
  Box,
  Typography,
  IconButton,
  CircularProgress
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { CloudUpload } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
import { Formik } from 'formik'
//
import { uploadService } from 'src/services'

const FileUploadForm = ({ union, onUpload, intl, isClosedForSubmission }) => {
  const file = React.useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  
  return (
    <Formik
      initialValues={{ file: '' }}
      validationSchema={Yup.object().shape({
        file: Yup.string().required(intl.formatMessage({ id: 'validations.field' }))
      })}
      onSubmit={async ({ file: filename }, { setSubmitting, resetForm }) => {
        enqueueSnackbar(intl.formatMessage({ id: 'uploads.started' }), {
          variant: 'info',
          autoHideDuration: 3000
        })

        try {
          onUpload(false)

          const formData = new FormData()
          formData.append('file', file.current.files[0], filename.replace('C:\\fakepath\\', ''))
          // eslint-disable-next-line no-underscore-dangle
          formData.append('union', union._id)
          const uploads = await uploadService.uploadProofOfPayment(formData)
          file.current.value = null

          const { id, error, errors, status } = uploads

          resetForm({ values: { file: '' } })
          onUpload({ id, errors, status, error })
        } catch (err) {
          // eslint-disable-next-line no-console
          enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
            variant: 'error'
          })
          onUpload(false)
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ handleChange, handleSubmit, isSubmitting, values, isValid, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={0}>
            <Grid xs={12} item>
              <FormControl>
                <IconButton
                  type="button"
                  component="label"
                  disabled={isClosedForSubmission || isSubmitting}
                >
                  <CloudUpload color="primary" style={{ marginRight: '8px' }} />
                  <input
                    ref={file}
                    type="file"
                    name="file"
                    style={{ display: 'none' }}
                    accept=".pdf,.xml"
                    onChange={handleChange}
                  />
                  {values.file ? (
                    <Typography>{values.file.replace('C:\\fakepath\\', '')}</Typography>
                  ) : (
                    <Typography>
                      <FormattedMessage id="uploads.uploadPop" />
                    </Typography>
                  )}
                </IconButton>
              </FormControl>
            </Grid>
          </Grid>
          {isSubmitting && (
            <Grid xs={12} item>
              <Alert icon={<CircularProgress thickness={5} size={32} color="inherit" />} severity="info">
                <Typography variant="h6" style={{ paddingTop: '4px' }}>
                  <FormattedMessage id="uploads.uploadingPop" />
                </Typography>
              </Alert>
            </Grid>
          )}
          <Box my={2} mt={0}>
            <Button color="primary" disabled={isSubmitting || !(isValid && dirty)} type="submit" variant="contained">
              <FormattedMessage id="uploads.submitPop" />
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default injectIntl(FileUploadForm)

FileUploadForm.propTypes = {
  union: PropTypes.object, 
  onUpload: PropTypes.func.isRequired,
  intl: PropTypes.object,
  isClosedForSubmission: PropTypes.bool
}
