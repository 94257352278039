import React from 'react'
import PropTypes from 'prop-types'
import { Container, List, ListItem, ListItemText, Modal, Paper, Box, makeStyles, Divider } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import Typography from '@material-ui/core/Typography'
import { injectIntl, FormattedMessage } from 'react-intl'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { bulkUploadService } from 'src/services'
import BulkUploadDetails from './BulkUploadDetails'
//
import useInterval from '../../../utils/useInterval'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))

function formattedDate(date) {
  const mm = date.getMonth() + 1
  const dd = date.getDate()
  const hh = date.getHours()
  const min = date.getMinutes()

  const day = [(dd > 9 ? '' : '0') + dd, (mm > 9 ? '' : '0') + mm, date.getFullYear()].join('-')
  const time = [(hh > 9 ? '' : '0') + hh, (min > 9 ? '' : '0') + min].join(':')

  return `${day} ${time}`
}

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const PreviousUploads = ({ newUpload, handleProcessingStatus, user }) => {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(true)
  const [previousUploads, setPreviousUploads] = React.useState([])
  const [selectedUpload, setSelectedUpload] = React.useState()

  const getUploads = async () => {
    bulkUploadService.getByQuery({ user: user.id }).then(uploads => {
      const res = uploads
        .map(upload => {
          // eslint-disable-next-line no-underscore-dangle
          return { id: upload._id, date: formattedDate(new Date(upload.created)), ...upload }
        })
        .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())

      setPreviousUploads(res)
      setLoading(false)
      const running = res.filter(upload => upload.status === 'PROCESSING').length
      if (!running) {
        handleProcessingStatus(true)
      }
    })
  }

  React.useEffect(() => {
    getUploads()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUpload])

  const processing = previousUploads.filter(upload => upload.status === 'PROCESSING')
  const processed = previousUploads.filter(upload => upload.status === 'PROCESSED')

  useInterval(
    () => {
      getUploads()
      if (!processing.length) {
        handleProcessingStatus(true)
      }
    },
    processing.length ? 15000 : null
  )

  return (
    <>
      {loading && (
        <div>
          <CircularProgress size={20} />
          <FormattedMessage id="global.loading" />
        </div>
      )}

      {!loading && processing && processing.length ? (
        <>
          <Typography color="textSecondary" gutterBottom variant="h5">
            <FormattedMessage id="administrationsView.processingUploads" />
          </Typography>

          <LinearProgress size={16} color="secondary" />

          <List component="nav" aria-label="main">
            {processing.map(upload => (
              <ListItem key={upload.id} style={{ padding: '2px' }} dense>
                <ListItemIcon style={{ minWidth: '32px' }}>
                  <ScheduleOutlinedIcon color="error" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <small>
                      {upload.date} -{' '}
                      <FormattedMessage
                        id="administrationsView.numberAdministrations"
                        values={{ number: formatNumber(upload.uploads.length) }}
                      />
                    </small>
                  }
                />
              </ListItem>
            ))}
          </List>

          <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
        </>
      ) : null}

      {!loading && processed && (
        <>
          <Typography color="textSecondary" gutterBottom variant="h5">
            <FormattedMessage id="administrationsView.previousUploads" />
          </Typography>

          <List component="nav" aria-label="main">
            {processed.map(upload => (
              <ListItem key={upload.id} style={{ padding: '2px' }} onClick={() => setSelectedUpload(upload.id)} button>
                <ListItemIcon style={{ minWidth: '32px' }}>
                  <DescriptionOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<small>{upload.filePath}</small>}
                  secondary={
                    <small>
                      {upload.date} -{' '}
                      <FormattedMessage
                        id="administrationsView.numberAdministrations"
                        values={{ number: formatNumber(upload.uploads.length) }}
                      />
                    </small>
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
      <Modal
        open={!!selectedUpload}
        onClose={() => setSelectedUpload()}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Container maxWidth="lg" style={{ outline: 0 }}>
          {selectedUpload && (
            <Paper>
              <MuiDialogTitle disableTypography style={{ position: 'relative' }}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => setSelectedUpload()}>
                  <CloseIcon />
                </IconButton>
              </MuiDialogTitle>
              <Box padding={2}>
                <BulkUploadDetails upload={selectedUpload} />
              </Box>
            </Paper>
          )}
        </Container>
      </Modal>
    </>
  )
}

PreviousUploads.propTypes = {
  newUpload: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  handleProcessingStatus: PropTypes.func,
  user: PropTypes.object
}

export default injectIntl(PreviousUploads)
