import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  MenuItem,
  Select,
} from '@material-ui/core'
import { FormattedMessage, injectIntl } from 'react-intl'
import { controlsService } from 'src/services'
//

const ImportPremiumsDialog = ({ openImportPremiums, setOpenImportPremiums, setControlPremium, intl }) => {
  const [loading, setLoadig] = React.useState("true")
  const [otherControls, setOtherControls] = React.useState([])
  const [selectedControl, setSelectedControl] = React.useState()
  let cancelled = false

  const handleCloseImportPremium = () => {
    setOpenImportPremiums(false)
  }

  const importSelectedPremiums = () => {
    if (selectedControl && selectedControl.expectedPremiums) {
      // eslint-disable-next-line no-underscore-dangle
      setControlPremium(selectedControl.expectedPremiums.map((premium) => ({ ...premium, id: premium._id })))
    }
    handleCloseImportPremium()
  }

  const handleChange = (controlId) => {
    if (controlId) {
      const currentControl = otherControls.filter(({ id }) => id === controlId).shift()
      if (currentControl && currentControl.expectedPremiums) {
        setSelectedControl(currentControl)
      }
    } else {
      setSelectedControl(null)
    }
  }

  React.useEffect(() => {
    setLoadig("true")
    controlsService.getAll().then(controls => {
      if (!cancelled) {
        setOtherControls(controls.map(({ id, name, status, expectedPremiums }) => ({ id, name, status, expectedPremiums })) || [])
        setLoadig("false")
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => { cancelled = true }
  }, [])

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleCloseImportPremium} open={openImportPremiums}>
      <DialogTitle>
        <FormattedMessage id="controlsView.premium.importPremiums" />
      </DialogTitle>
      <Container>
        <Box display="flex" my={3}>
          <Box flexGrow={1} component="span">
            <Select
              style={{ paddingTop: 5, paddingBottom: 5 }}
              fullWidth
              placeholder={intl.formatMessage({ id: 'controlsView.premium.selectPremiumsPlaceholder' })}
              id="controls"
              name="controls"
              loading={loading}
              onChange={(e) => handleChange(e.target.value)}
              value={selectedControl ? selectedControl.id : ' '}
            >
              {otherControls.map(({ id, name }) => (
                <MenuItem
                  name="" key={id} value={id}><i>{`${id}`}</i>{`     - ${name}`}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box px={2} component="span">
            <Button variant="contained" color="default" disabled={!selectedControl} onClick={() => importSelectedPremiums()}>
              <FormattedMessage id="controlsView.premium.selectPremiumsButton" />
            </Button>
          </Box>
        </Box>
      </Container>
    </Dialog>
  )
}

export default injectIntl(ImportPremiumsDialog)

ImportPremiumsDialog.propTypes = {
  openImportPremiums: PropTypes.bool.isRequired,
  setOpenImportPremiums: PropTypes.func.isRequired,
  setControlPremium: PropTypes.func.isRequired,
  intl: PropTypes.object
}
