import React from 'react'
import { makeStyles } from '@material-ui/core'
//
import { accountService } from 'src/services'
import useLocalizedNavigate from 'src/i18n/hooks'
import Page from 'src/components/Page'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const LoggedUserLandingView = () => {
  const classes = useStyles()

  const user = accountService.userValue
  const navigate = useLocalizedNavigate()

  React.useEffect(() => {
    if (user) {
      if (user.role === 'Admin') {
        navigate('/app/access-requests', { replace: true })
      } else if (user.role === 'User') {
        navigate('/app/administrations', { replace: true })
      } else if (user.role === 'Union') {
        navigate('/app/union', { replace: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Page className={classes.root} title="Welcome">
    <span />
  </Page>
}

export default LoggedUserLandingView
