import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
  Switch
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
//
import { accountService, settingsService } from 'src/services'

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
})

const DisplayUploads = ({ className, intl, ...rest }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = React.useState(true)
  const [settingsItem, setSettingsItem] = React.useState({
    id: false,
    displayUploads: false
  })
  const user = accountService.userValue

  React.useEffect(() => {
    settingsService.getDisplayUploads()
      .then(res => {
        // eslint-disable-next-line no-underscore-dangle
        const id = res._id
        const { value, isCurrentSessionEnabled } = res
        const { displayUploads } = value
        setSettingsItem({ id, displayUploads: displayUploads && isCurrentSessionEnabled })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  return loading ? (
    <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
      <CircularProgress />
      <Typography color="textPrimary" variant="h4">
        <FormattedMessage id="global.loading" />
      </Typography>
    </div>
  ) : (
    <Formik
      initialValues={{
        displayUploads: settingsItem.displayUploads
      }}
      validationSchema={Yup.object().shape({
        displayUploads: Yup.bool()
      })}
      onSubmit={({ displayUploads }, { setSubmitting }) => {
        setSubmitting(true)

        if (!settingsItem.id) {
          settingsService
            .create({ key: 'displayUploads', value: { displayUploads, refreshTokenId: user.refreshTokenId } })
            .then(() => {
              setSubmitting(false)
              enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
            })
            .catch(() => {
              setSubmitting(false)
              enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), { variant: 'error' })
            })
        } else {
          settingsService
            .update(settingsItem.id, { key: 'displayUploads', value: { displayUploads, refreshTokenId: user.refreshTokenId } })
            .then(() => {
              setSubmitting(false)
              enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
            })
            .catch(() => {
              setSubmitting(false)
              enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), { variant: 'error' })
            })
        }
      }}
    >
      {({ handleBlur, handleChange, handleSubmit, isSubmitting, values }) => (
        <form onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
          <Card>
            <CardHeader
              subheader={intl.formatMessage({ id: 'settings.displayUploadsInfo' })}
              title={intl.formatMessage({ id: 'settings.displayUploadsTitle' })}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid className={classes.item} item md={9} xs={8}>
                  <Typography color="textPrimary" gutterBottom variant="h6">
                    <FormattedMessage id="settings.displayUploadsHeader" />
                  </Typography>
                </Grid>
                <Grid className={classes.item} item md={3} xs={4}>
                  <Switch
                    id="displayUploads"
                    name="displayUploads"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    checked={values.displayUploads}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button color="primary" disabled={isSubmitting} size="large" type="submit" variant="contained">
                <FormattedMessage id="buttons.save" />
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  )
}

DisplayUploads.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object
}

export default injectIntl(DisplayUploads)
