import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FormattedMessage, injectIntl } from 'react-intl'
//
import { unionsService, accountService } from 'src/services'
import getUnionsTitle from 'src/utils/getAdministrationsTitle'
import getUnionCode from 'src/utils/getUnionCode'

const sortForEndYear = (a, b) => {
  if (!a.endYear) return -1
  if (!b.endYear) return 1
  return (a.endYear - b.endYear)
}

const UnionsSelect = ({ onChange, intl, filtered = [] }) => {
  const [loading, setLoading] = React.useState(true)
  const [unions, setUnions] = React.useState()
  const user = accountService.userValue

  const getAllAvailableUnions = async () => {
    if (user && user.id) {
      return unionsService.getAllExcludingUser(user.id)
    }
    return unionsService.getAll()
  }

  React.useEffect(() => {
    const currentYear = new Date(Date.now()).getFullYear()
    const actualEndYear = currentYear - 2
    getAllAvailableUnions().then(allUnions => {
      const titledUnions = allUnions
        .sort(sortForEndYear)
        .filter(administation => !administation.endYear || administation.endYear >= actualEndYear)
        .reduce((acc, cur) => {
          const index = acc.findIndex(union => union.code === cur.code)
          if (index >= 0) {
            const union = acc[index]
            if (!union.endYear) {
              return acc
            }
            if (!cur.endYear) {
              if (index) {
                acc[index] = cur
                return acc
              }
            }
          }
          acc.push(cur)
          return acc
        }, [])
        .map(union => {
          union.title = `${getUnionCode(union.code)} - ${getUnionsTitle(union.name)}`
          return union
        })
      setUnions(titledUnions)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filteredUnions =
    // eslint-disable-next-line no-underscore-dangle
    (unions && filtered && unions.filter(union => !filtered.includes(union._id))) ||
    []

  return (
    <Field
      id="unions"
      name="unions"
      options={filteredUnions}
      onChange={onChange}
      component={Autocomplete}
      getOptionSelected={(option, value) => {
        // eslint-disable-next-line no-underscore-dangle
        return option._id === value._id
      }}
      getOptionLabel={option => option.title}
      renderInput={params => {
        return loading ? (
          <div>
            <CircularProgress color="inherit" size={20} />
            <FormattedMessage id="global.loading" />
          </div>
        ) : (
          <TextField
            {...params}
            label={intl.formatMessage({ id: 'unionSelect.placeholder' })}
            variant="outlined"
            placeholder={intl.formatMessage({ id: 'unionSelect.label' })}
          />
        )
      }}
    />
  )
}

UnionsSelect.propTypes = {
  onChange: PropTypes.func,
  filtered: PropTypes.array,
  intl: PropTypes.object
}

export default injectIntl(UnionsSelect)
