import { BehaviorSubject } from 'rxjs'

import config from 'src/utils/config'
import fetchWrapper from 'src/utils/fetch'
import history from 'src/utils/history'

const userSubject = new BehaviorSubject(null)
const baseUrl = `${config.apiUrl}/accounts`

let refreshTokenTimeout

function startRefreshTokenTimer() {
  // parse json object from base64 encoded jwt token
  const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split('.')[1]))

  // set a timeout to refresh the token a minute before it expires
  const expires = new Date(jwtToken.exp * 1000)
  const timeout = expires.getTime() - Date.now() - 60 * 1000
  // eslint-disable-next-line no-use-before-define
  refreshTokenTimeout = setTimeout(refreshToken, timeout)
}

function stopRefreshTokenTimer() {
  clearTimeout(refreshTokenTimeout)
}

function refreshToken() {
  return fetchWrapper
    .post(`${baseUrl}/refresh-token`, {})
    .then(user => {
      // publish user to subscribers and start timer to refresh token
      userSubject.next(user)
      startRefreshTokenTimer()
      return user
    })
    .catch(() => {})
}

function login(email, password, captcha) {
  return fetchWrapper.post(`${baseUrl}/authenticate`, { email, password, captcha }).then(user => {
    return user
  })
}

function finalizeLogin(user) {
  // publish user to subscribers and start timer to refresh token
  userSubject.next(user)
  startRefreshTokenTimer()
  return user
}

function logout() {
  // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
  fetchWrapper.post(`${baseUrl}/revoke-token`, {}).catch(() => {})
  stopRefreshTokenTimer()
  userSubject.next(null)
  history.push('/app/login')
}

function register(params) {
  return fetchWrapper.post(`${baseUrl}/register`, params)
}

function verifyEmail(token) {
  return fetchWrapper.post(`${baseUrl}/verify-email`, { token })
}

function forgotPassword(email, captcha) {
  return fetchWrapper.post(`${baseUrl}/forgot-password`, { email, captcha })
}

function validateResetToken(token) {
  return fetchWrapper.post(`${baseUrl}/validate-reset-token`, { token })
}

function resetPassword({ token, password, confirmPassword }) {
  return fetchWrapper.post(`${baseUrl}/reset-password`, { token, password, confirmPassword })
}

function getAll() {
  return fetchWrapper.get(baseUrl)
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`)
}

function create(params) {
  return fetchWrapper.post(baseUrl, params)
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params).then(user => {
    // update stored user if the logged in user updated their own record
    if (user.id === userSubject.value.id) {
      // publish updated user to subscribers
      user = { ...userSubject.value, ...user }
      userSubject.next(user)
    }
    return user
  })
}

function updatePassword(id, params) {
  return fetchWrapper.post(`${baseUrl}/update-password/${id}`, params).then(user => {
    // update stored user if the logged in user updated their own record
    if (user.id === userSubject.value.id) {
      // publish updated user to subscribers
      user = { ...userSubject.value, ...user }
      userSubject.next(user)
    }
    return user
  })
}

// eslint-disable-next-line no-underscore-dangle
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`).then(x => {
    // auto logout if the logged in user deleted their own record
    if (id === userSubject.value.id) {
      logout()
    }
    return x
  })
}

function getMfaCode() {
  return fetchWrapper.post(`${baseUrl}/mfa-generate`)
}

function validatetMfaCode(id, userToken) {
  return fetchWrapper.post(`${baseUrl}/mfa-validate/${id}`, { userToken })
}

function enableMfaCode(secret, userToken) {
  return fetchWrapper.post(`${baseUrl}/mfa-enable`, { secret, userToken }).then(user => {
    if (user.id === userSubject.value.id) {
      // publish updated user to subscribers
      user = { ...userSubject.value, ...user }
      userSubject.next(user)
    }
    return user
  })
}

function disableMfaCode(id) {
  return fetchWrapper.post(`${baseUrl}/mfa-disable/${id}`).then(user => {
    if (user.id === userSubject.value.id) {
      // publish updated user to subscribers
      user = { ...userSubject.value, ...user }
      userSubject.next(user)
    }
    return user
  })
}

export default {
  login,
  finalizeLogin,
  logout,
  refreshToken,
  register,
  verifyEmail,
  forgotPassword,
  validateResetToken,
  resetPassword,
  getAll,
  getById,
  create,
  update,
  updatePassword,
  delete: _delete,
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value
  },
  getMfaCode,
  enableMfaCode,
  validatetMfaCode,
  disableMfaCode
}
