import React from 'react'
import { Chip } from '@material-ui/core'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

const ControlStatusChip = ({ status, outlined = false }) => {

  const variant = outlined ? 'outlined' : undefined
  return (
    <>
      {["CREATED", "IN_PROGRESS"].includes(status) ? <Chip color="default" size="small" style={{ fontWeight: '400' }} label={status} variant={variant} /> : null}
      {status === "COMPLETED" ? <Chip color="primary" size="small" style={{ fontWeight: '400' }} label={status} variant={variant} /> : null}
      {status === "ERROR" ? <Chip color="default" size="small" style={{ color: 'red', fontWeight: '400' }} label={status} variant={variant} /> : null}
    </>
  )
}

ControlStatusChip.propTypes = {
  status: PropTypes.string,
  outlined: PropTypes.bool
}

export default injectIntl(ControlStatusChip)
