import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { makeStyles, Tab, Tabs, withStyles } from '@material-ui/core'
import { injectIntl, FormattedMessage } from 'react-intl'
//
import Page from 'src/components/Page'
import AccessRequestUnions from './AccessRequestUnions'
import AccessRequestAdministrations from './AccessRequestAdministrations'


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    marginRight: '10px'
  }
}))

const AccessRequestTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#212121',
  },
})(Tabs);

const AccessRequestsView = () => {
  const classes = useStyles()
  const [selectedTabValue, setSelectedTabValue] = React.useState('tabAdministrations');

  const handleChange = (event, newValue) => {
    setSelectedTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  return (
    <Page className={classes.root} title="Access Requests">
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography color="textPrimary" gutterBottom variant="h3">
              Access Requests
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <AccessRequestTabs value={selectedTabValue} onChange={handleChange} aria-label="wrapped label tabs example">
              <Tab
                value="tabAdministrations"
                label={<FormattedMessage id="accessRequest.tabAdministrations" />}
                {...a11yProps('tabAdministrations')}
              />
              <Tab
                value="tabUnions"
                label={<FormattedMessage id="accessRequest.tabUnions" />}
                {...a11yProps('tabUnions')} />
            </AccessRequestTabs>
            <TabPanel value={selectedTabValue} index="tabAdministrations">
              <AccessRequestAdministrations />
            </TabPanel>
            <TabPanel value={selectedTabValue} index="tabUnions">
              <AccessRequestUnions />
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

AccessRequestsView.propTypes = {
  intl: PropTypes.object
}

export default injectIntl(AccessRequestsView)
