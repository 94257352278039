import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { IntlContext } from 'react-intl'
//

const LocalizedLink = React.forwardRef(({ to, lang = null, children, ...rest }, ref) => {
  const intl = React.useContext(IntlContext)
  const dest = lang ? `/${lang}/${to}` : `/${intl.locale}/${to}`

  return (
    <RouterLink ref={ref} to={dest.replace('//', '/')} {...rest}>
      {children}
    </RouterLink>
  )
})

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
  lang: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default LocalizedLink
