/* eslint-disable no-underscore-dangle */
import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Container,
  Dialog,
  DialogTitle
} from '@material-ui/core'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import DialogActions from '@material-ui/core/DialogActions'
import { injectIntl, FormattedMessage } from 'react-intl'
//

import { unionsService } from 'src/services'

function checkNames() {
  const nameNl = this.parent.nameNl || ''
  const nameFr = this.parent.nameFr || ''
  if (!nameNl.length && !nameFr.length) {
    return false
  }
  return true
}

function checkCodes() {
  const codeNl = this.parent.codeNl || ''
  const codeFr = this.parent.codeFr || ''
  if (!codeNl.length && !codeFr.length) {
    return false
  }
  return true
}

const CreateUnion = ({ open, handleClose, intl }) => {
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
      <DialogTitle>
        <FormattedMessage id="unionsView.createUnion" />
      </DialogTitle>
      <Container>
        <Grid container xl>
          <Grid item xs={12}>
            <Formik
              onSubmit={async ({ unionId, codeFr, codeNl, nameFr, nameNl }, { resetForm }) => {
                const data = {
                  unionId,
                  code: {
                    nl: codeNl,
                    fr: codeFr
                  },
                  name: {
                    nl: nameNl,
                    fr: nameFr
                  }
                }

                const callbackOnSubmitSuccesful = () => {
                  enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), {
                    variant: 'success',
                    autoHideDuration: 10000
                  })
                  resetForm({})
                  handleClose()
                }

                unionsService
                  .create(data)
                  .then(() => {
                  // .then(currentUnion => {
                    // const userEmails = []
                    // emails
                    //   .split(/[\n,]/)
                    //   .map(email => email.trim())
                    //   .filter(email => email && email.length)
                    //   .map(email => email.toLowerCase())
                    //   .forEach(email => {
                    //     if (isEmail(email) && !userEmails.includes(email)) {
                    //       userEmails.push(email)
                    //     } else {
                    //       enqueueSnackbar(intl.formatMessage({ id: 'unionsView.emailInvalid' }, { email }), {
                    //         variant: 'error',
                    //         autoHideDuration: 3000
                    //       })
                    //     }
                    //   })

                    // if (userEmails.length) {
                    //   accountService.getAll().then(users => {
                    //     const promises = users
                    //       .filter(user => userEmails.includes(user.email.toLowerCase()))
                    //       .map(user => unionsAccountsService
                    //         .createAccountsUnionRecord({
                    //           account: user.id,
                    //           union: currentUnion._id
                    //         })
                    //         .then(() => {
                    //           enqueueSnackbar(
                    //             intl.formatMessage({ id: 'unionsView.emailAdded' }, { email: user.email }),
                    //             {
                    //               variant: 'info',
                    //               autoHideDuration: 3000
                    //             }
                    //           )
                    //         })
                    //         .catch(() => {
                    //           enqueueSnackbar(
                    //             intl.formatMessage({ id: 'unionsView.emailError' }, { email: user.email }),
                    //             {
                    //               variant: 'error',
                    //               autoHideDuration: 3000
                    //             }
                    //           )
                    //         })
                    //       )
                    //     Promise.all(promises).then(() => {
                    //       callbackOnSubmitSuccesful()
                    //     })
                    //   })
                    // } else {
                    callbackOnSubmitSuccesful()
                    // }
                  })
                  .catch(() => {
                    enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
                      variant: 'error',
                      autoHideDuration: 10000
                    })
                  })
              }}
              initialValues={{
                unionId: '',
                codeFr: '',
                codeNl: '',
                nameFr: '',
                nameNl: '',
                emails: ''
              }}
              validationSchema={Yup.object().shape({
                unionId: Yup.string()
                  .min(4)
                  .max(20)
                  .required(intl.formatMessage({ id: 'validations.field' })),
                codeNl: Yup.string()
                  .max(25)
                  .test('codeFr-check', intl.formatMessage({ id: 'validations.field' }), checkCodes),
                codeFr: Yup.string()
                  .max(25)
                  .test('codeFr-check', intl.formatMessage({ id: 'validations.field' }), checkCodes),
                nameNl: Yup.string()
                  .max(160)
                  .test('nameFr-check', intl.formatMessage({ id: 'validations.field' }), checkNames),
                nameFr: Yup.string()
                  .max(160)
                  .test('nameNl-check', intl.formatMessage({ id: 'validations.field' }), checkNames)
              })}
            >
              {({ handleSubmit, values, touched, errors, handleBlur, handleChange }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader title={intl.formatMessage({ id: 'unionsView.title' })} />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.unionId && errors.unionId)}
                            fullWidth
                            helperText={touched.unionId && errors.unionId}
                            label={intl.formatMessage({ id: 'unionsView.unionId' })}
                            name="unionId"
                            required
                            value={values.unionId}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12} />
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.codeNl && errors.codeNl)}
                            fullWidth
                            helperText={touched.codeNl && errors.codeNl}
                            label={intl.formatMessage({ id: 'unionsView.codeNl' })}
                            name="codeNl"
                            value={values.codeNl}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.codeFr && errors.codeFr)}
                            fullWidth
                            helperText={touched.codeFr && errors.codeFr}
                            label={intl.formatMessage({ id: 'unionsView.codeFr' })}
                            name="codeFr"
                            value={values.codeFr}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.nameNl && errors.nameNl)}
                            fullWidth
                            helperText={touched.nameNl && errors.nameNl}
                            label={intl.formatMessage({ id: 'unionsView.nameNl' })}
                            name="nameNl"
                            value={values.nameNl}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.nameFr && errors.nameFr)}
                            fullWidth
                            helperText={touched.nameFr && errors.nameFr}
                            label={intl.formatMessage({ id: 'unionsView.nameFr' })}
                            name="nameFr"
                            value={values.nameFr}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        {/* <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.emails && errors.emails)}
                            fullWidth
                            helperText={touched.emails && errors.emails}
                            label={intl.formatMessage({ id: 'unionsView.addUsers' })}
                            name="emails"
                            multiline
                            rows={2}
                            value={values.emails}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid> */}
                      </Grid>
                    </CardContent>
                  </Card>

                  <Box my={2} mt={3}>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        <FormattedMessage id="buttons.cancel" />
                      </Button>
                      <Button color="primary" size="large" type="submit" variant="contained">
                        <FormattedMessage id="buttons.create" />
                      </Button>
                    </DialogActions>
                  </Box>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </Dialog >
  )
}

export default injectIntl(CreateUnion)

CreateUnion.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object
}
