import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import clsx from 'clsx'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
//
import { uploadService } from 'src/services'

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
})

const ClearControlsCache = ({ className, intl, ...rest }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Formik
      initialValues={{
      }}
      // eslint-disable-next-line no-empty-pattern
      onSubmit={({ }, { setSubmitting }) => {
        setSubmitting(true)
        uploadService.clearControlsCache()
          .then(() => {
            setSubmitting(false)
            enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
          })
          .catch(() => {
            setSubmitting(false)
            enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), { variant: 'error' })
          })
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
          <Card>
            <CardHeader
              title={intl.formatMessage({ id: 'settings.clearCacheTitle' })}
              subheader={intl.formatMessage({ id: 'settings.clearCacheInfo' })}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid className={classes.item} item md={9} xs={8}>
                  <Typography color="textPrimary" gutterBottom>
                    <i><FormattedMessage id="settings.clearCacheMessage" /></i>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button color="primary" disabled={isSubmitting} size="large" type="submit" variant="contained">
                <FormattedMessage id="buttons.clearCache" />
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  )
}

ClearControlsCache.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object
}

export default injectIntl(ClearControlsCache)
