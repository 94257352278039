import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Container, Dialog, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import List from '@material-ui/core/List'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import Typography from '@material-ui/core/Typography'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import DialogActions from '@material-ui/core/DialogActions'
import { injectIntl, FormattedMessage } from 'react-intl'
//
import getAdministrationsTitle from 'src/utils/getAdministrationsTitle'
import Page from 'src/components/Page'
import { LocalizedLink } from 'src/i18n'
import { accountService, administrationsAccountsService, aggregationsService } from 'src/services'
import AdministrationsSelect from 'src/components/AdministrationsSelect'
import Administration from './Administration'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const defaultSortAdministrations = (a, b) => parseInt(a.administration.code, 10) - parseInt(b.administration.code, 10)

const getUserAdministrationAccounts = id => {
  return aggregationsService.accountsAdministrationsAuthorization(id).then(items =>
    items
      .filter(item => item.administration && item.account)
      .sort(defaultSortAdministrations)
      .map(item => {
        const name = getAdministrationsTitle(item.administration.name)

        return {
          ...item,
          name,
          code: item.administration.code,
          // eslint-disable-next-line no-underscore-dangle
          adminId: item.administration._id,
          status: item.status,
        }
      })
  )
}

const isLinkEnabled = (aa) => {
  if (aa.status === 'APPROVED') {
    return !(aa.isClosedForSubmission && aa.numDocumentsUplaoded <= 0)
  }
  return false
}

const AdministrationsView = ({ intl }) => {
  const classes = useStyles()
  const user = accountService.userValue

  const [admins, setAdmins] = React.useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  React.useEffect(() => {
    getUserAdministrationAccounts(user.id).then(items => setAdmins(items))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page className={classes.root} title={intl.formatMessage({ id: 'navigation.administrations' })}>
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography color="textPrimary" gutterBottom variant="h3">
              <FormattedMessage id="navigation.administrations" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  <FormattedMessage id="administrationsView.info" values={{ total: admins.length }} />
                </ListSubheader>
              }
            >
              {admins.map(admin => (
                <React.Fragment key={`admin-${admin.code}`}>
                  {isLinkEnabled(admin) ? (
                    <ListItem component={LocalizedLink} button to={`/app/administrations/${admin.code}`}>
                      <Administration {...admin} />
                    </ListItem>
                  ) : (
                    <ListItem style={{ cursor: 'not-allowed' }}>
                      <Administration {...admin} />
                    </ListItem>
                  )}
                </React.Fragment>
              ))}
            </List>
          </Grid>
          <Grid item xs={4}>
            <Box my={2} mt={3}>
              <Button
                onClick={() => setDialogOpen(true)}
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                <FormattedMessage id="administrationsView.requestAccess" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Dialog fullWidth maxWidth="lg" onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <DialogTitle>
          <FormattedMessage id="administrationsView.requestAccess" />
        </DialogTitle>
        <Container>
          <Grid container xl>
            <Grid item xs={12}>
              <Formik
                onSubmit={async ({ administrations: administration }, { resetForm }) => {
                  administrationsAccountsService
                    .createAccountsAdministrationRecord({
                      account: user.id,
                      administration
                    })
                    .then(() => {
                      enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), {
                        variant: 'success',
                        autoHideDuration: 10000
                      })

                      resetForm({})

                      getUserAdministrationAccounts(user.id).then(items => {
                        setAdmins(items)
                        setDialogOpen(false)
                      })
                    })
                    .catch(() => {
                      enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
                        variant: 'error',
                        autoHideDuration: 10000
                      })
                    })
                }}
                initialValues={{
                  administrations: []
                }}
              >
                {({ handleSubmit, setFieldValue, values }) => (
                  <form onSubmit={handleSubmit}>
                    <AdministrationsSelect
                      onChange={(e, value) => {
                        setFieldValue(
                          'administrations',
                          // eslint-disable-next-line no-underscore-dangle
                          value.map(admin => admin._id)
                        )
                      }}
                      filtered={admins.map(admin => admin.adminId)}
                    />
                    <Box my={2} mt={3}>
                      <DialogActions>
                        <Button onClick={() => setDialogOpen(false)} color="primary">
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          size="large"
                          type="submit"
                          variant="contained"
                          disabled={!values.administrations.length}
                        >
                          <FormattedMessage id="administrationsView.submitRequest" />
                        </Button>
                      </DialogActions>
                    </Box>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </Page>
  )
}

export default injectIntl(AdministrationsView)

AdministrationsView.propTypes = {
  intl: PropTypes.object
}
