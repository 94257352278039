import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
  FormControl,
  IconButton
} from '@material-ui/core'
//
import { assetsService } from 'src/services'
import { CloudUpload } from '@material-ui/icons'

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  dropdown: {
    minWidth: '200px'
  }
})

const UploadAsset = ({ reloadAssets, className, intl, ...rest }) => {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(true)
  const file = React.useRef(null)

  React.useEffect(() => {
    setLoading(false)
  }, [])

  return loading ? (
    <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
      <CircularProgress />
      <Typography color="textPrimary" variant="h4">
        <FormattedMessage id="global.loading" />
      </Typography>
    </div>
  ) : (
    <Formik
      initialValues={{
        file: ''
      }}
      validationSchema={Yup.object().shape({
        file: Yup.string().required(intl.formatMessage({ id: 'validations.field' }))
      })}
      onSubmit={async ({ file: filename }, { setSubmitting, resetForm }) => {
        setSubmitting(true)

        const formData = new FormData()
        formData.append('file', file.current.files[0], filename.replace('C:\\fakepath\\', ''))
        await assetsService.upload(formData)
        file.current.value = null
        await reloadAssets()
        setSubmitting(false)
        resetForm({ values: { file: '' } })
      }}
    >
      {({ handleChange, handleSubmit, isSubmitting, values }) => (
        <form onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
          <Card>
            <CardHeader
              title={intl.formatMessage({ id: 'assets.upload.title' })}
              subheader={intl.formatMessage({ id: 'assets.upload.subtitle' })}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid className={classes.item} item xs={12}>
                  <FormControl>
                    <IconButton
                      type="button"
                      component="label"
                      disabled={isSubmitting}
                    >
                      <CloudUpload color="primary" style={{ marginRight: '8px' }} />
                      <input
                        ref={file}
                        type="file"
                        name="file"
                        style={{ display: 'none' }}
                        accept=".*"
                        onChange={handleChange}
                      />
                      {values.file ? (
                        <Typography>{values.file.replace('C:\\fakepath\\', '')}</Typography>
                      ) : (
                        <Typography>
                          <FormattedMessage id="assets.upload.button" />
                        </Typography>
                      )}
                    </IconButton>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button color="primary" disabled={isSubmitting} size="large" type="submit" variant="contained">
                <FormattedMessage id="buttons.upload" />
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  )
}

UploadAsset.propTypes = {
  reloadAssets: PropTypes.func,
  className: PropTypes.string,
  intl: PropTypes.object
}

export default injectIntl(UploadAsset)
