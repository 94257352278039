import React from 'react'
import { makeStyles } from '@material-ui/core'
//
import Page from 'src/components/Page'
import HelpContent from './HelpContent'


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(3)
  }
}))

const HelpView = () => {
  const classes = useStyles()
 
  return <Page className={classes.root} title="Help">
    <HelpContent />
  </Page>
}

export default HelpView
