import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Box, Button, makeStyles, Link, Tooltip } from '@material-ui/core'
import { FormattedMessage, injectIntl } from 'react-intl'
import { DataGrid } from '@material-ui/data-grid'
import { useLocalizedNavigate } from 'src/i18n'
import ControlStatusChip from '../ControlStatusChip'
import DeleteControlDialog from './DeleteControlDialog'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    fontSize: '.6rem',
    textDecoration: 'underline'
  }
}))

const ControlsDataTable = ({ intl, controls, loading, setLoading, loadControls }) => {
  const classes = useStyles()

  const navigate = useLocalizedNavigate()
  const [dialog, setDialog] = React.useState(false)
  const [selectedControl, setSelectedControl] = React.useState()

  // eslint-disable-next-line react/prop-types, jsx-a11y/anchor-is-valid
  const TableCellLink = React.forwardRef(({ controlID, children }, ref) => <Link ref={ref} onClick={() => navigate(`/app/controls/${controlID}`, { replace: false })}>
    {children}
  </Link>)

  const handleCloseDialog = () => {
    setSelectedControl(null)
    setDialog('')
    loadControls()
  }

  const openDialogDeleteControl = async (control) => {
    setSelectedControl(control)
    setDialog('delete')
  }

  const controlsColumns = [
    {
      field: 'controlYear', headerName: intl.formatMessage({ id: 'controlsView.dataGrid.controlYear' }), width: 100, align: 'center',
      renderCell: params => {
        if (params) {
          const { value } = params
          const toolTipLabel = `${intl.formatMessage({ id: 'controlsView.dataGrid.controlYear' })} ${value}`
          return (
            <Tooltip placement="top" title={toolTipLabel}>
              <b>{value}</b>
            </Tooltip>
          )
        }
        return null
      }
    },
    {
      field: 'name', headerName: intl.formatMessage({ id: 'controlsView.dataGrid.name' }), width: 280, align: 'left',
      renderCell: params => {
        if (params) {
          const { value, row } = params
          return (
            <TableCellLink controlID={row.id}>
              <Tooltip placement="top" title={value}>
                <Box>{value}</Box>
              </Tooltip>
            </TableCellLink>
          )
        }
        return null
      }
    },
    {
      field: 'id',
      headerName: intl.formatMessage({ id: 'controlsView.dataGrid.controlUID' }),
      width: 220,
      align: 'right',
      renderCell: params => {
        if (params) {
          const { value } = params
          const toolTipLabel = `${intl.formatMessage({ id: 'controlsView.dataGrid.controlUID' })} ${value}`
          return (
            <Tooltip placement="top" title={toolTipLabel} >
              <Box>{value}</Box>
            </Tooltip >
          )
        }
        return null
      }
    },
    {
      field: 'created', headerName: intl.formatMessage({ id: 'controlsView.dataGrid.created' }), width: 160, align: 'right',
      renderCell: params => {
        if (params) {
          const { value: created } = params
          const formattedDate = moment(created).format('DD-MM-YYYY HH:mm:ss')
          return (
            <Tooltip placement="top" title={formattedDate}>
              <Box>{formattedDate}</Box>
            </Tooltip>
          )
        }
        return null
      }
    },
    {
      field: 'status', headerName: intl.formatMessage({ id: 'controlsView.dataGrid.status' }), width: 128, align: 'center',
      renderCell: params => {
        if (params) {
          const { value, row } = params
          return <Box width={111} display="flex" justifyContent="center">
            <TableCellLink controlID={row.id}>
              <ControlStatusChip status={value} />
            </TableCellLink>
          </Box>
        }
        return null
      }
    },
    {
      field: 'actions', headerName: intl.formatMessage({ id: 'controlsView.dataGrid.actions' }), width: 188, align: 'center',
      renderCell: params => {
        if (params) {
          const { row } = params
          return <Box width={111} display="flex" justifyContent="left">
            <Button
              onClick={() => navigate(`/app/controls/${row.id}`, { replace: false })}
              size="small"
              className={classes.button}
            >
              <FormattedMessage id="buttons.detail" />
            </Button>
            {
              row.status !== "IN_PROGRESS" ? (
                <Button
                  style={{ color: 'red' }}
                  onClick={() => openDialogDeleteControl(row)}
                  disabled={loading}
                  size="small"
                  className={classes.button}
                >
                  <FormattedMessage id="buttons.delete" />
                </Button>
              ) : null
            }
          </Box>
        }
        return null
      }
    },
  ]

  return (
    <>
      {dialog === 'delete' && (
        <DeleteControlDialog
          open
          control={selectedControl}
          loading={loading}
          setLoading={setLoading}
          handleClose={handleCloseDialog}
        />
      )}
      {controls && (
        <>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ minHeight: '450px', flexGrow: 1 }}>
              <DataGrid
                rows={controls}
                columns={controlsColumns}
                rowHeight={44}
                disableSelectionOnClick
                pageSize={10}
                autoHeight
                loading={loading}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

ControlsDataTable.propTypes = {
  intl: PropTypes.object,
  controls: PropTypes.array,
  loading: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  loadControls: PropTypes.func.isRequired
}

export default injectIntl(ControlsDataTable)
