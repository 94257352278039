import config from 'src/utils/config'
import { accountService } from 'src/services'
//

function langHeader() {
  const lang = window.location.pathname.split('/')[1]

  return { 'Accept-Language': lang }
}

function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  const user = accountService.userValue
  const isLoggedIn = user && user.jwtToken
  const isApiUrl = url.startsWith(config.apiUrl) || url.startsWith(config.controlsUrl)
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${user.jwtToken}` }
  }
  return {}
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)

    if (!response.ok) {
      if ([401, 403].includes(response.status) && accountService.userValue) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        accountService.logout()
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}

function get(url, params = {}, skipHandleResponse = false) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(url), ...langHeader() }
  }
  const queryParams = new URLSearchParams(params)
  return fetch(`${url}/?${queryParams}`, requestOptions).then(!skipHandleResponse && handleResponse)
}

function post(url, body, skipHandleResponse = false) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader(url), ...langHeader() },
    credentials: 'include',
    body: JSON.stringify(body)
  }
  return fetch(url, requestOptions).then(!skipHandleResponse && handleResponse)
}

function put(url, body, skipHandleResponse = false) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader(url), ...langHeader() },
    body: JSON.stringify(body)
  }
  return fetch(url, requestOptions).then(!skipHandleResponse && handleResponse)
}

function patch(url, body, skipHandleResponse = false) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...authHeader(url), ...langHeader() },
    body: JSON.stringify(body)
  }
  return fetch(url, requestOptions).then(!skipHandleResponse && handleResponse)
}

// eslint-disable-next-line no-underscore-dangle
function _delete(url, skipHandleResponse = false) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(url), ...langHeader() }
  }
  return fetch(url, requestOptions).then(!skipHandleResponse && handleResponse)
}

function postMultipart(url, formData, skipHandleResponse = false) {
  return fetch(url, {
    method: 'POST',
    headers: { ...authHeader(url), ...langHeader() },
    body: formData
  })
    .then(!skipHandleResponse && handleResponse)
}

function getZipFile(url, fileName) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(url), ...langHeader() }
  }
  return fetch(`${url}`, requestOptions)
    .then(response => response.blob())
    .then(blob => {
      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(blob)
      a.download = `${fileName}.zip`
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
}

function getOriginalFile(url, fileName) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(url), ...langHeader() }
  }
  return fetch(`${url}`, requestOptions)
    .then(response => response.blob())
    .then(blob => {
      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(blob)
      a.download = `${fileName}`
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
}

const fetchWrapper = {
  get,
  post,
  put,
  patch,
  delete: _delete,
  postMultipart,
  getZipFile,
  getOriginalFile
}

export default fetchWrapper
