import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { IntlContext } from 'react-intl'
//

const LocalizedRedirect = React.forwardRef(({ to, lang = false, ...rest }, ref) => {
  const intl = React.useContext(IntlContext)
  const { pathname } = to

  to.pathname = lang ? `/${lang}/${pathname}` : `/${intl.locale}/${pathname}`

  return <Navigate ref={ref} to={to} {...rest} />
})

LocalizedRedirect.propTypes = {
  to: PropTypes.object.isRequired,
  lang: PropTypes.string
}

export default LocalizedRedirect
