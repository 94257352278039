/* eslint-disable react/no-unescaped-entities */
import { Box, Breadcrumbs, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { DataGrid } from '@material-ui/data-grid'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import useInterval from 'src/utils/useInterval'
import config from 'src/utils/config'
//
import { NavigateNext, Replay, Stop } from '@material-ui/icons'
import { controlsService } from 'src/services'
import { Play } from 'react-feather'
import { LocalizedLink } from 'src/i18n'
import ImportedAdministrationsDetail from './ImportedAdministrationsDetail'
import ImportedUnionsDetail from './ImportedUnionsDetail'
import TopicsOffsetsDetail from './TopicsOffsetsDetail'
import VerificationDetail from './VerificationDetail'
import ControlStatusChip from '../ControlStatusChip'
import ControlContext from './ControlContext'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    card: {
        // backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}))

const ControlDetail = ({
    doStartControl,
    doStopControl,
    doRestartControl,
    doRecalculateControlStatus,
    doStartVerifications,
    doReloadVerification,
    doReimportAdministrations,
    doReimportUnions,
    intl,
}) => {
    const classes = useStyles()

    const [unionsStats, setUnionsStats] = React.useState(null)
    const [unionsImportStatus, setUnionsImportStatus] = React.useState(null)
    const [verificationStatus, setVerificationStatus] = React.useState(null)
    const [loadingImportUnions, setLoadingImportUnions] = React.useState(true)

    const [administrationsStats, setAdministrationsStats] = React.useState(null)
    const [administrationsImportStatus, setAdministrationsImportStatus] = React.useState(null)
    const [loadingAdministrations, setLoadingAdministrations] = React.useState(true)
    const [openStart, setOpenStart] = React.useState(false);
    const [openStop, setOpenStop] = React.useState(false);
    const [openRestart, setOpenRestart] = React.useState(false);

    let cancelled
    const control = React.useContext(ControlContext);

    const handleClickOpenStart = () => {
        setOpenStart(true);
    };
    const handleCloseStart = async (actionAgreed = false) => {
        if (actionAgreed) {
            await doStartControl()
        }
        setOpenStart(false);
    };

    const handleClickOpenStop = () => {
        setOpenStop(true);
    };
    const handleCloseStop = async (actionAgreed = false) => {
        if (actionAgreed) {
            await doStopControl()
        }
        setOpenStop(false);
    };

    const handleClickOpenRestart = () => {
        setOpenRestart(true);
    };
    const handleCloseRestart = async (actionAgreed = false) => {
        if (actionAgreed) {
            await doRestartControl()
        }
        setOpenRestart(false);
    };

    const isNullOrEmptyObject = (obj) => !obj || Object.keys(obj).length === 0

    const loadStatsForUnions = async () => {
        setLoadingImportUnions(true)
        const [unionStatusResult] = await Promise.all([
            controlsService.statsForUnions(control),
        ])
        if (!cancelled) {
            setUnionsStats(isNullOrEmptyObject(unionStatusResult) ? null : unionStatusResult)
            const { importStatus, verificationStatus: verificationStatusResult } = unionStatusResult
            setUnionsImportStatus(isNullOrEmptyObject(importStatus) ? null : importStatus)
            setVerificationStatus(isNullOrEmptyObject(verificationStatusResult) ? null : verificationStatusResult)
            setLoadingImportUnions(false)
        }
    }

    const loadStatsForAdministrations = async () => {
        setLoadingAdministrations(true)
        const [results] = await Promise.all([
            controlsService.statsForAdministrations(control),
        ])
        if (!cancelled) {
            if (!isNullOrEmptyObject(results)) {
                setAdministrationsStats(results)
                const { administrationsImportStatus: importStatus } = results
                setAdministrationsImportStatus(isNullOrEmptyObject(importStatus) ? null : importStatus)
            }
        }
        setLoadingAdministrations(false)
    }

    const refreshAllStats = async () => {
        await Promise.all([
            loadStatsForAdministrations(),
            loadStatsForUnions()
        ])
    }
    const refreshAction = async () => {
        await doRecalculateControlStatus(() => refreshAllStats())
    }

    const startVerificationAction = async () => {
        await doStartVerifications(() => loadStatsForUnions())
    }

    const reloadVerificationAction = async () => {
        await doReloadVerification(() => refreshAllStats())
    }

    const reimportAdministrationsAction = async () => {
        await doReimportAdministrations(() => refreshAllStats())
    }

    const reimportUnionsAction = async () => {
        await doReimportUnions(() => refreshAllStats())
    }

    React.useEffect(() => {
        refreshAllStats()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => { cancelled = true }
    }, [])

    useInterval(
        async () => refreshAllStats(),
        (control && control.status === "IN_PROGRESS") ? config.controlsPollingTime : null)

    const columns = [
        { field: 'name', headerName: intl.formatMessage({ id: 'controlsView.premium.name' }), width: 256 },
        { field: 'prime', headerName: intl.formatMessage({ id: 'controlsView.premium.prime' }), width: 156, align: 'right' },
        { field: 'cotisationMax', headerName: intl.formatMessage({ id: 'controlsView.premium.cotisationMax' }), width: 156, align: 'right' },
        { field: 'cotisationMin', headerName: intl.formatMessage({ id: 'controlsView.premium.cotisationMin' }), width: 156, align: 'right' },
    ]

    return <>
        {!control &&
            < div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
                <CircularProgress />
            </div>
        }
        {control && <>
            <Dialog
                open={openStart}
                onClose={handleCloseStart}>
                <DialogTitle>
                    <FormattedMessage id="controlsView.startControlDialogTitle" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="controlsView.startControlDialogText" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseStart()} color="primary">
                        <FormattedMessage id="buttons.cancel" />
                    </Button>
                    <Button onClick={() => handleCloseStart(true)} color="primary" autoFocus>
                        <FormattedMessage id="buttons.ok" />
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openStop}
                onClose={handleCloseStop}>
                <DialogTitle>
                    <FormattedMessage id="controlsView.stopControlDialogTitle" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="controlsView.stopControlDialogText" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseStop(false)} color="primary">
                        <FormattedMessage id="buttons.cancel" />
                    </Button>
                    <Button onClick={() => handleCloseStop(true)} color="primary" autoFocus>
                        <FormattedMessage id="buttons.ok" />
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openRestart}
                onClose={handleCloseRestart}>
                <DialogTitle>
                    <FormattedMessage id="controlsView.restartControlDialogTitle" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="controlsView.restartControlDialogText" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseRestart()} color="primary">
                        <FormattedMessage id="buttons.cancel" />
                    </Button>
                    <Button onClick={() => handleCloseRestart(true)} color="primary" autoFocus>
                        <FormattedMessage id="buttons.ok" />
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                        <LocalizedLink to='app/controls'>
                            <Typography color="textSecondary" variant="h3" >
                                <FormattedMessage id="navigation.controls" />
                            </Typography>
                        </LocalizedLink>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box flexGrow={0} pr={2}>
                                <Typography color="textPrimary" variant="h3">
                                    {`${control.name}`}
                                </Typography>
                            </Box>
                            <Box flexGrow={0}>
                                <ControlStatusChip status={control.status} />
                            </Box>
                         </Box>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={2}>
                <Grid item xs={6} md={2} >
                    <Typography color="textPrimary" variant="h5" >
                        <FormattedMessage id="controlsView.referenceYear" />
                    </Typography>
                </Grid>
                <Grid item xs={6} md={4} >
                    <Typography color="textPrimary" >
                        <b>{control.controlYear}</b>
                    </Typography>
                </Grid>
                <Grid item xs={6} md={2} >
                    <Typography color="textPrimary" variant="h5" >
                        <FormattedMessage id="controlsView.name" />
                    </Typography>
                </Grid>
                <Grid item xs={6} md={4} >
                    <Typography color="textPrimary">
                        {control.name}
                    </Typography>
                </Grid>

                <Grid item xs={6} md={2} >
                    <Typography color="textPrimary" variant="h5" >
                        <FormattedMessage id="controlsView.controlID" />
                    </Typography>
                </Grid>
                <Grid item xs={6} md={4} >
                    <Typography color="textPrimary">
                        {control.id}
                    </Typography>
                </Grid>
                <Grid item xs={6} md={2} >
                    <Typography color="textPrimary" variant="h5" >
                        <FormattedMessage id="controlsView.created" />
                    </Typography>
                </Grid>
                <Grid item xs={6} md={4} >
                    <Typography color="textPrimary">
                        {control.created ? moment(control.created).format('DD-MM-YYYY HH:mm:ss') : ''}
                    </Typography>
                </Grid>
                {["CREATED", "IN_PROGRESS", "ERROR"].includes(control.status) && (
                    <Grid item xs={12} md={6} >
                        <Box display='flex' alignContent="flex-start">
                            {["CREATED"].includes(control.status) ? (
                                <Box flexGrow={0} px={1}>
                                    <Button color="primary" variant="contained" startIcon={<Play />}
                                        onClick={() => handleClickOpenStart()}
                                    >
                                        <FormattedMessage id="controlsView.startControlButton" />
                                    </Button>
                                </Box>) : null}
                            {["IN_PROGRESS"].includes(control.status) ? (
                                <Box flexGrow={0} px={1}>
                                    <Button color="primary" variant="contained" startIcon={<Stop />}
                                        onClick={() => handleClickOpenStop()}
                                    >
                                        <FormattedMessage id="controlsView.stopControlButton" />
                                    </Button>
                                </Box>) : null}
                            {["ERROR"].includes(control.status) ? (
                                <Box flexGrow={0} px={1}>
                                    <Button color="primary" variant="outlined" startIcon={<Replay />}
                                        onClick={() => handleClickOpenRestart()}
                                    >
                                        <FormattedMessage id="controlsView.restartControlButton" />
                                    </Button>
                                </Box>
                            ) : (
                                <Box flexGrow={0} pl={1} pr={2}>
                                    <Button color="primary" variant="outlined" startIcon={<Replay />}
                                        onClick={() => refreshAction()}
                                    >
                                        <FormattedMessage id="controlsView.refreshControlButton" />
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                )}

                <Grid item xs={12} >
                    <Typography color="textPrimary" variant="h5" >
                        <FormattedMessage id="controlsView.premiumLabel" />
                    </Typography>
                </Grid>
                <Grid item xs={12} >
                    <Box height={179}>
                        <DataGrid
                            // eslint-disable-next-line no-underscore-dangle
                            rows={control.expectedPremiums.map(prime => ({ ...prime, id: prime._id }))}
                            columns={columns}
                            hideFooter
                            rowHeight={30} />
                    </Box>
                </Grid>
                <br />
                {control && control.status !== "CREATED" && <>
                    <ImportedAdministrationsDetail
                        loadingAdministrations={loadingAdministrations}
                        administrationsStats={administrationsStats}
                        importAdministrationsStatus={administrationsImportStatus}
                        reimportAdministrationsAction={reimportAdministrationsAction}
                    />
                    <ImportedUnionsDetail
                        loadingImportUnions={loadingImportUnions}
                        unionsStats={unionsStats}
                        unionImportStatus={unionsImportStatus}
                        verificationStatus={verificationStatus}
                        reimportUnionsAction={reimportUnionsAction}
                    />
                    <VerificationDetail
                        loadingImportUnions={loadingImportUnions}
                        administrationsImportStatus={administrationsImportStatus}
                        unionsImportStatus={unionsImportStatus}
                        verificationStatus={verificationStatus}
                        startVerificationAction={startVerificationAction}
                        reloadVerificationAction={reloadVerificationAction}
                    />
                    {!["COMPLETED"].includes(control.status) ? (<TopicsOffsetsDetail />) : null}
                </>}
                <br />
            </Grid >
        </>
        }
    </>
}
export default injectIntl(ControlDetail)

ControlDetail.propTypes = {
    intl: PropTypes.object,
    doStartControl: PropTypes.func.isRequired,
    doStopControl: PropTypes.func.isRequired,
    doRestartControl: PropTypes.func.isRequired,
    doRecalculateControlStatus: PropTypes.func.isRequired,
    doStartVerifications: PropTypes.func.isRequired,
    doReloadVerification: PropTypes.func.isRequired,
    doReimportUnions: PropTypes.func.isRequired,
    doReimportAdministrations: PropTypes.func.isRequired,
}
