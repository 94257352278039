import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'
//
import Page from 'src/components/Page'
import { assetsService, settingsService } from 'src/services'


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    textDecoration: 'underline'
  }
}))

const TermsAndConditionsView = () => {
  const classes = useStyles()

  const [loading, setLoading] = React.useState(true)
  const [nlDescription, setNlDescription] = React.useState()
  const [frDescription, setFrDescription] = React.useState()
  const [nlFile, setNlFile] = React.useState()
  const [frFile, setFrFile] = React.useState()

  React.useEffect(() => {
    let cancelled = false
    
    let loadingText = true
    let loadingNlTag = true
    let loadingFrTag = true

    const updateLoading = () => {
      setLoading(loadingText || loadingNlTag || loadingFrTag)
    }

    const loadText = async () => {
      setLoading(true)
      const tecSettings = await settingsService.getTermsAndConditions()
      if (!cancelled) {
        if (tecSettings && tecSettings.value) {
          setNlDescription(tecSettings.value.nl)
          setFrDescription(tecSettings.value.fr)
        }
      }
      loadingText = false
      updateLoading()
    }

    const loadNlTag = async () => {
      setLoading(true)
      const resultNl = await assetsService.getByTag("T&C NL")
      if (!cancelled) {
        if (resultNl && resultNl.name) {
          setNlFile(resultNl.name)
        }
      }
      loadingNlTag = false
      updateLoading()
    }

    const loadFrTag = async () => {
      setLoading(true)
      const resultFr = await assetsService.getByTag("T&C FR")
      if (!cancelled) {
        if (resultFr && resultFr.name) {
          setFrFile(resultFr.name)
        }
      }
      loadingFrTag = false
      updateLoading()
    }

    loadText()
    loadNlTag()
    loadFrTag()

    return () => {
      cancelled = true
    }
  }, [])

  return (
    <Page className={classes.root} title="Terms and Conditions">
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1>
              <FormattedMessage id="termsConditions.title" />
            </h1>
          </Grid>
        </Grid>
        {!loading && <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity="info" className={classes.alert} icon={<GetAppIcon />}>
              <AlertTitle>
                <FormattedMessage id="languages.dutch" />
              </AlertTitle>
              <Typography color="textPrimary" variant="h6">
                {nlDescription || 'Werkwijze betreffende de toekenning en uitbetaling van een vakbondspremie aan sommige personeelsleden van de overheidssector – Referentiejaar 2021'
                }
              </Typography>
              {nlFile ? <Button
                className={classes.button}
                size="small"
                variant="text"
                color="primary"
                onClick={() => assetsService.download(nlFile)}
                target="_blank"
              >
                <FormattedMessage id="buttons.downloadDocument" />
              </Button> : null
              }
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info" className={classes.alert} icon={<GetAppIcon />}>
              <AlertTitle>
                <FormattedMessage id="languages.french" />
              </AlertTitle>
              <Typography color="textPrimary" variant="h6">
                {frDescription || `Nouvelle procédure relative à l’octroi et le paiement d'une prime syndicale à certains membres du
                personnel du secteur public – Année de référence 2021`}
              </Typography>
              {frFile ? <Button
                className={classes.button}
                size="small"
                variant="text"
                color="primary"
                onClick={() => assetsService.download(frFile)}
                target="_blank"
              >
                <FormattedMessage id="buttons.downloadDocument" />
              </Button> : null
              }
              {/* <p>&nbsp;</p>
              <Typography color="textPrimary" variant="h6">
                Fiche de Traitement RGDP – Traitement des Primes Syndicales
              </Typography>
              <Button
                className={classes.button}
                size="small"
                variant="text"
                color="primary"
                href="/docs/RGPD_Secretariats_FicheTraitement_Basic_FR_PrimeSyndicale_v0.3.pdf"
                target="_blank"
              >
                <FormattedMessage id="buttons.downloadDocument" />
              </Button> */}
            </Alert>
          </Grid>
        </Grid>
        }
      </Container>
    </Page>
  )
}

export default TermsAndConditionsView
