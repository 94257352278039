/* eslint-disable react/no-unescaped-entities */
import { Button, Box, IconButton, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, FormControlLabel, RadioGroup, Radio } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Alert, Skeleton } from '@material-ui/lab'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
//
import { CloudDownload, PlayArrow, Publish } from '@material-ui/icons'
import { controlsService } from 'src/services'
import ControlStatusChip from '../ControlStatusChip'
import ControlContext from './ControlContext'

const ReportTypes = {
    UNION_PRIME_SUMMARY: 'UNION_PRIME_SUMMARY',
    UNION_COTISATION_SUMMARY: 'UNION_COTISATION_SUMMARY',
    LIST_OF_INVALID_PRIME_AMOUNTS: 'LIST_OF_INVALID_PRIME_AMOUNTS',
    LIST_OF_PEOPLE_WITH_TOO_SMALL_COTISATION: 'LIST_OF_PEOPLE_WITH_TOO_SMALL_COTISATION',
    LIST_OF_PAYMENTS_NOT_IN_REGULAR_PRIME_YEAR: 'LIST_OF_PAYMENTS_NOT_IN_REGULAR_PRIME_YEAR',
    LIST_OF_PEOPLE_WITHOUT_INSTITUTION: 'LIST_OF_PEOPLE_WITHOUT_INSTITUTION',
    LIST_OF_REAL_DOUBLE_BY_UUID: 'LIST_OF_REAL_DOUBLE_BY_UUID',
    LIST_OF_REAL_DOUBLE_BY_UNIQUE_FORM_ID: 'LIST_OF_REAL_DOUBLE_BY_UNIQUE_FORM_ID',
    LIST_OF_REAL_DOUBLE_BY_UUID2: 'LIST_OF_REAL_DOUBLE_BY_UUID2',
}

const VerificationDetail = ({
    verificationStatus,
    unionsImportStatus,
    administrationsImportStatus,
    loadingImportUnions,
    startVerificationAction,
    reloadVerificationAction,
    intl
}) => {
    const [open, setOpen] = React.useState(false);
    const [openReportDialog, setOpenReportDialog] = React.useState(false);
    const [reportType, setReportType] = React.useState(ReportTypes.UNION_PRIME_SUMMARY);

    const control = React.useContext(ControlContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async (actionAgreed = false) => {
        if (actionAgreed) {
            await reloadVerificationAction(control)
        }
        setOpen(false);
    };

    const handleClickOpenReportDialog = () => {
        setOpenReportDialog(true);
    };

    const handleCloseReportDialog = async (actionAgreed = false) => {
        if (actionAgreed) {
            const reportName = intl.formatMessage({ id: `controlsView.reportTypes.${reportType}` })
            await controlsService.downloadVerificationCSVReportByType(control, reportType, reportName)
        }
        setOpenReportDialog(false);
    };

    const controlResultColumns = [
        { field: 'status', headerName: intl.formatMessage({ id: 'controlsView.controlStatus.status' }), width: 156 },
        { field: 'count', headerName: intl.formatMessage({ id: 'controlsView.controlStatus.count' }), width: 156, align: 'right' }
    ]

    const isImportCompleted = unionsImportStatus && administrationsImportStatus && ["COMPLETED"].includes(unionsImportStatus.status) && ["COMPLETED"].includes(administrationsImportStatus.status)

    return <>
        <br />

        <Dialog
            open={open}
            onClose={() => handleClose(false)} >
            <DialogTitle>
                <FormattedMessage id="controlsView.requeueUnionsDialogTitle" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id="controlsView.requeueUnionsDialogText" />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    <FormattedMessage id="buttons.cancel" />
                </Button>
                <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                    <FormattedMessage id="buttons.ok" />
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={openReportDialog}
            onClose={() => handleCloseReportDialog(false)} >
            <DialogTitle>
                <FormattedMessage id="controlsView.reportDialogTitle" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id="controlsView.reportDialogText" />
                </DialogContentText>
                <RadioGroup aria-label="report-type" name="reportType" value={reportType} onChange={e => setReportType(e.target.value)}>
                    <FormControlLabel value={ReportTypes.UNION_PRIME_SUMMARY} control={<Radio />}
                        label={intl.formatMessage({ id: 'controlsView.reportTypes.UNION_PRIME_SUMMARY' })} />
                    <FormControlLabel value={ReportTypes.UNION_COTISATION_SUMMARY} control={<Radio />}
                        label={intl.formatMessage({ id: 'controlsView.reportTypes.UNION_COTISATION_SUMMARY' })} />

                    <FormControlLabel value={ReportTypes.LIST_OF_INVALID_PRIME_AMOUNTS} control={<Radio />}
                        label={intl.formatMessage({ id: 'controlsView.reportTypes.LIST_OF_INVALID_PRIME_AMOUNTS' })} />
                    <FormControlLabel value={ReportTypes.LIST_OF_PEOPLE_WITH_TOO_SMALL_COTISATION} control={<Radio />}
                        label={intl.formatMessage({ id: 'controlsView.reportTypes.LIST_OF_PEOPLE_WITH_TOO_SMALL_COTISATION' })} />
                    <FormControlLabel value={ReportTypes.LIST_OF_PAYMENTS_NOT_IN_REGULAR_PRIME_YEAR} control={<Radio />}
                        label={intl.formatMessage({ id: 'controlsView.reportTypes.LIST_OF_PAYMENTS_NOT_IN_REGULAR_PRIME_YEAR' })} />
                    <FormControlLabel value={ReportTypes.LIST_OF_PEOPLE_WITHOUT_INSTITUTION} control={<Radio />}
                        label={intl.formatMessage({ id: 'controlsView.reportTypes.LIST_OF_PEOPLE_WITHOUT_INSTITUTION' })} />

                    <FormControlLabel value={ReportTypes.LIST_OF_REAL_DOUBLE_BY_UUID} control={<Radio />}
                        label={intl.formatMessage({ id: 'controlsView.reportTypes.LIST_OF_REAL_DOUBLE_BY_UUID' })} />
                    <FormControlLabel value={ReportTypes.LIST_OF_REAL_DOUBLE_BY_UNIQUE_FORM_ID} control={<Radio />}
                        label={intl.formatMessage({ id: 'controlsView.reportTypes.LIST_OF_REAL_DOUBLE_BY_UNIQUE_FORM_ID' })} />
                    <FormControlLabel value={ReportTypes.LIST_OF_REAL_DOUBLE_BY_UUID2} control={<Radio />}
                        label={intl.formatMessage({ id: 'controlsView.reportTypes.LIST_OF_REAL_DOUBLE_BY_UUID2' })} />
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseReportDialog(false)} color="primary">
                    <FormattedMessage id="buttons.cancel" />
                </Button>
                <Button onClick={() => handleCloseReportDialog(true)} color="primary" autoFocus>
                    <FormattedMessage id="buttons.ok" />
                </Button>
            </DialogActions>
        </Dialog>

        <Grid item xs={12}>
            <Box display="flex" alignContent="flex-end" alignItems="center">
                <Box width="20%">
                    <Typography color="textPrimary" variant="h4" >
                        <FormattedMessage id="controlsView.controlResult" />
                    </Typography>
                </Box>
                {verificationStatus && (
                    <Box display="flex" flexDirection="row" alignItems="center" px={2}>
                        <ControlStatusChip status={verificationStatus.status} outlined />
                        {isImportCompleted && (
                            <Box pl={1}>
                                <IconButton title={intl.formatMessage({ id: 'controlsView.requeueButton' })}
                                    onClick={handleClickOpen}>
                                    <Publish />
                                </IconButton>
                            </Box>
                        )}
                    </Box>)
                }
            </Box>
        </Grid>
        {verificationStatus ? (<>
            <Grid item xs={12} md={8} >
                {verificationStatus.statuses ? (
                    <Box height={180}>
                        <DataGrid
                            rows={verificationStatus.statuses.map((x, index) => ({ ...x, id: index }))}
                            columns={controlResultColumns}
                            hideFooter
                            rowHeight={30} />
                    </Box>
                ) : (
                    <Alert severity='info'>
                        <Typography color="textPrimary" variant="h6">
                            <FormattedMessage id="controlsView.controlResultsNotYetAvailable" />
                        </Typography>
                    </Alert>
                )}
            </Grid>
            <Grid item xs={12} md={4} >
                <Box display='flex' flexDirection='column' alignContent="flex-start">
                    {(isImportCompleted && ["CREATED"].includes(verificationStatus.status)) && (
                        <Box flexGrow={1} pb={1}>
                            <Button color="primary" size="large" variant="contained" startIcon={<PlayArrow />}
                                onClick={() => startVerificationAction()}
                            >
                                <FormattedMessage id="controlsView.performControlsButton" />
                            </Button>
                        </Box>
                    )}

                    {(isImportCompleted && ["COMPLETED"].includes(verificationStatus.status)) && (
                        <Box flexGrow={1} pb={1}>
                            <Button color="default" size="large" variant="outlined" startIcon={<CloudDownload />}
                                onClick={() => handleClickOpenReportDialog()}
                            >
                                <FormattedMessage id="controlsView.reportDownloadButton" />
                            </Button>
                        </Box>
                    )}
                </Box>
            </Grid>
        </>) : (
            <Grid item xs={12} >
                {loadingImportUnions ? (
                    <Skeleton variant="rect" width='100%' height={50} />
                ) : (
                    <Alert severity='info'>
                        <Typography color="textPrimary" variant="h6">
                            <FormattedMessage id="controlsView.controlResultsNotYetAvailable" />
                        </Typography>
                    </Alert>
                )}
            </Grid>
        )}
        <br />
    </>
}
export default injectIntl(VerificationDetail)

VerificationDetail.propTypes = {
    verificationStatus: PropTypes.object,
    unionsImportStatus: PropTypes.object,
    administrationsImportStatus: PropTypes.object,
    loadingImportUnions: PropTypes.bool.isRequired,
    startVerificationAction: PropTypes.func.isRequired,
    reloadVerificationAction: PropTypes.func.isRequired,
    intl: PropTypes.object,
}
