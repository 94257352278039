import React from 'react'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { injectIntl, FormattedMessage } from 'react-intl'
//
import { accountService } from 'src/services'

const useStyles = makeStyles(() => ({
  root: {}
}))

const ProfileDetails = ({ className, intl, ...rest }) => {
  const user = accountService.userValue
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Formik
      initialValues={{
        title: user.title,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        telephoneNumber: user.telephoneNumber
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(intl.formatMessage({ id: 'validations.field' })),
        email: Yup.string()
          .email(intl.formatMessage({ id: 'validations.email' }))
          .max(160)
          .required(intl.formatMessage({ id: 'validations.field' })),
        firstName: Yup.string()
          .max(100)
          .required(intl.formatMessage({ id: 'validations.field' })),
        lastName: Yup.string()
          .max(100)
          .required(intl.formatMessage({ id: 'validations.field' })),
        telephoneNumber: Yup.string()
          .max(64)
          .required(intl.formatMessage({ id: 'validations.field' }))
      })}
      onSubmit={({ title, firstName, lastName, telephoneNumber }, { setSubmitting, resetForm }) => {
        setSubmitting(true)

        accountService
          .update(user.id, { title, firstName, lastName, telephoneNumber, lang: intl.locale })
          .then(() => {
            setSubmitting(false)
            enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
            resetForm({})
          })
          .catch(() => {
            setSubmitting(false)
            enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), { variant: 'error' })
            resetForm({})
          })
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, setFieldValue, values }) => (
        <form onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
          <Card>
            <CardHeader
              subheader={intl.formatMessage({ id: 'accountView.info' })}
              title={intl.formatMessage({ id: 'accountView.profile' })}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.title && errors.title)}
                    fullWidth
                    helperText={touched.title && errors.title}
                    label={intl.formatMessage({ id: 'account.title' })}
                    name="title"
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('title', e.target.value)}
                    required
                    select
                    SelectProps={{ native: true }}
                    defaultValue={values.title}
                    variant="outlined"
                  >
                    <option key="Mr" value="Mr">
                      {intl.formatMessage({ id: 'titles.Mr' })}
                    </option>
                    <option key="Mrs" value="Mrs">
                      {intl.formatMessage({ id: 'titles.Mrs' })}
                    </option>
                    <option key="Miss" value="Miss">
                      {intl.formatMessage({ id: 'titles.Miss' })}
                    </option>
                  </TextField>
                </Grid>{' '}
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    label={intl.formatMessage({ id: 'account.firstName' })}
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    label={intl.formatMessage({ id: 'account.lastName' })}
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.lastName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label={intl.formatMessage({ id: 'account.emailAddress' })}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.telephoneNumber && errors.telephoneNumber)}
                    fullWidth
                    helperText={touched.telephoneNumber && errors.telephoneNumber}
                    label={intl.formatMessage({ id: 'account.phoneNumber' })}
                    name="telephoneNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.telephoneNumber}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
                <FormattedMessage id="buttons.update" />
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  )
}

ProfileDetails.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object
}

export default injectIntl(ProfileDetails)
