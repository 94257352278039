import React from 'react'
import { makeStyles } from '@material-ui/core'
import { FormattedMessage, IntlContext } from 'react-intl'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'
import HelpIcon from '@material-ui/icons/Help'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
//
import { accountService, assetsService } from 'src/services'
import LocalizedLink from 'src/i18n/LocalizedLink'
import config from 'src/utils/config'

const globalMd = {
  en: 'global.en.md',
  de: 'global.de.md',
  fr: 'global.fr.md',
  nl: 'global.nl.md'
}

const userMd = {
  en: 'user.en.md',
  de: 'user.de.md',
  fr: 'user.fr.md',
  nl: 'user.nl.md'
}

const unionMd = {
  en: 'union.en.md',
  de: 'union.de.md',
  fr: 'union.fr.md',
  nl: 'union.nl.md'
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(3)
  }
}))

const HelpViewContent = ({ isHelpPreview = false, helpPreviewFile }) => {
  const classes = useStyles()
  const intl = React.useContext(IntlContext)
  const locale = intl?.locale || 'en'
  const user = accountService.userValue

  const [showHelp, setShowHelp] = React.useState(true)
  const [globalText, setGlobalText] = React.useState('')

  const getLastQueryPathItem = path => {
    const effectivePath = path.endsWith('/') ? path.substring(0, path.lastIndexOf('/')) : path
    return effectivePath.substring(effectivePath.lastIndexOf('/') + 1)
  }

  const convertAssetsHref = (href) => `${config.apiUrl}/assets/${getLastQueryPathItem(href)}`

  const renderers = {
    // eslint-disable-next-line react/prop-types
    link: ({ children, href }) => {
      if (/^\/.*/.test(href) && !/^\/docs\/.*/.test(href) && !/^\/assets\/.*/.test(href)) {
        return <LocalizedLink to={href} target="_blank">{children}</LocalizedLink>
      }
      if (/^\/assets\/.*/.test(href)) {
        return <a href={convertAssetsHref(href)} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      }
      return <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    }
  }

  React.useEffect(() => {
    let cancel = false
    if (!user && !locale) {
      return () => { }
    }

    const loadFile = async (fileName) => {
      assetsService.getFileContent(fileName)
        .then(res => res.text())
        .then(text => {
          if (!cancel) {
            try {
              const data = text && JSON.parse(text)
              if (data && data.message && data.message === "File not found") {
                setShowHelp(false)
              }
            } catch (error) {
              setGlobalText({ markdown: text })
              setShowHelp(true)
            }
          }
        })
    }

    const fileNameByUserAndLocale = () => {
      if (user) {
        if (user.role === 'Union') {
          return unionMd[locale]
        }
        return userMd[locale]
      }
      return globalMd[locale]
    }
    const fileName = (isHelpPreview) ? helpPreviewFile : fileNameByUserAndLocale()

    if (fileName) {
      loadFile(fileName)
    } else {
      setShowHelp(false)
    }

    return () => {
      cancel = true
    }
  }, [locale, user, isHelpPreview, helpPreviewFile])

  return (
    <>
      {showHelp && <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1>
              {!isHelpPreview ? <FormattedMessage id="help.title" /> : <FormattedMessage id="help.previewTitle" values={{ filename: helpPreviewFile }} />}
            </h1>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert className={classes.alert} icon={<HelpIcon />}>
              {globalText ? (
                <ReactMarkdown className="markdown"
                  renderers={renderers}
                  source={globalText?.markdown || ''} />
              ) : null}
            </Alert>
          </Grid>
        </Grid>
      </Container>
      }
    </>
  )
}

HelpViewContent.propTypes = {
  isHelpPreview: PropTypes.bool,
  helpPreviewFile: PropTypes.string
}

export default HelpViewContent
