/* eslint-disable no-underscore-dangle */
import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { makeStyles, Typography, Box, IconButton, Tooltip, Grid, CircularProgress, TextareaAutosize } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import Alert from '@material-ui/lab/Alert'
import { CloudDownload } from '@material-ui/icons'
import { bulkUploadService, uploadService } from 'src/services'
import { useSnackbar } from 'notistack'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
//

const ERROR_LOG_DISPLAY_LIMIT = 1000

const useStyles = makeStyles(theme => ({
  alert: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  alertOutlined: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textareaAutosize: {
    width: '100%',
    maxHeight: '25vh',
    resize: 'vertical',
    padding: theme.spacing(.5)
  }
}))

function formattedDate(date) {
  const mm = date.getMonth() + 1
  const dd = date.getDate()
  const hh = date.getHours()
  const min = date.getMinutes()

  const day = [(dd > 9 ? '' : '0') + dd, (mm > 9 ? '' : '0') + mm, date.getFullYear()].join('-')
  const time = [(hh > 9 ? '' : '0') + hh, (min > 9 ? '' : '0') + min].join(':')

  return `${day} ${time}`
}

const BulkUploadDetails = ({ upload, intl }) => {
  const { enqueueSnackbar } = useSnackbar()

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    enqueueSnackbar('copied!', {
      variant: 'secondary'
    })
  }

  const classes = useStyles()

  const [bulkUpload, setBulkUpload] = React.useState(null)

  const columns = [
    { field: 'sequenceNR', headerName: intl.formatMessage({ id: 'dataGrid.nr' }), width: 100 },
    { field: 'administration', headerName: intl.formatMessage({ id: 'dataGrid.administration' }), width: 150 },
    { field: 'year', headerName: intl.formatMessage({ id: 'dataGrid.year' }), width: 100 },
    { field: 'size', headerName: intl.formatMessage({ id: 'dataGrid.size' }), width: 100 },
    { field: 'status', headerName: intl.formatMessage({ id: 'dataGrid.status' }), width: 150 },
    { field: 'created', headerName: intl.formatMessage({ id: 'dataGrid.created' }), width: 200 },
    {
      field: 'download',
      headerName: intl.formatMessage({ id: 'dataGrid.download' }),
      width: 250,
      renderCell: params => params.value
    }
  ]

  React.useEffect(() => {
    if (typeof upload === 'string') {
      bulkUploadService
        .getById(upload)
        .then(res => {
          // eslint-disable-next-line no-underscore-dangle
          setBulkUpload({ id: res._id, ...res })
        })
        .catch(() => { })
    } else {
      setBulkUpload(upload)
    }
  }, [upload])

  if (!bulkUpload) {
    return (
      <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
        <CircularProgress />
        <Typography color="textPrimary" variant="h4">
          <FormattedMessage id="global.loading" />
        </Typography>
      </div>
    )
  }

  const { error, size, errors, uploads } = bulkUpload

  if (error && error === 'missing-headers' && errors && errors.length > 0) {
    return (
      <Alert severity="error" className={classes.alert}>
        <Typography color="textPrimary" variant="h5">
          <FormattedMessage id="uploads.errors" />
        </Typography>
        <Typography color="textPrimary" variant="h5">
          {errors.length > 1 ?
            <FormattedMessage id="uploads.validationErrors.missingHeaders" values={{ headers: errors.join(", ") }} /> :
            <FormattedMessage id="uploads.validationErrors.missingHeader" values={{ header: errors[0] }} />
          }
        </Typography>
      </Alert>
    )
  }

  if (error && error === 'unsupported-headers' && errors && errors.length > 0) {
    return (
      <Alert severity="error" className={classes.alert}>
        <Typography color="textPrimary" variant="h5">
          <FormattedMessage id="uploads.errors" />
        </Typography>
        <Typography color="textPrimary" variant="h5">
          {errors.length > 1 ?
            <FormattedMessage id="uploads.validationErrors.unsupportedHeaders" values={{ headers: errors.join(", ") }} /> :
            <FormattedMessage id="uploads.validationErrors.unsupportedHeader" values={{ header: errors[0] }} />
          }
        </Typography>
      </Alert>
    )
  }

  if (error && error === 'admin-code-not-found-errors' && errors && errors.length > 0) {
    return (
      <Alert severity="error" className={classes.alert}>
        <Typography color="textPrimary" variant="h5">
          <FormattedMessage id="uploads.errors" />
        </Typography>
        <Typography color="textPrimary" variant="h5">
          {errors.length > 1 ?
            <FormattedMessage id="uploads.validationErrors.orgUIDsNotFound" values={{ codes: errors.join(", ") }} /> :
            <FormattedMessage id="uploads.validationErrors.orgUIDNotFound" values={{ code: errors[0] }} />
          }
        </Typography>
      </Alert>
    )
  }

  if (error && error === 'admin-code-unauthorized-errors' && errors && errors.length > 0) {
    return (
      <Alert severity="error" className={classes.alert}>
        <Typography color="textPrimary" variant="h5">
          <FormattedMessage id="uploads.errors" />
        </Typography>
        <Typography color="textPrimary" variant="h5">
          {errors.length > 1 ?
            <FormattedMessage id="uploads.validationErrors.orgUIDsUnauthorized" values={{ codes: errors.join(", ") }} /> :
            <FormattedMessage id="uploads.validationErrors.orgUIDUnauthorized" values={{ code: errors[0] }} />
          }
        </Typography>
      </Alert>
    )
  }

  if (error && error === 'csv-errors') {
    const logMessages = errors.map((err) => `${intl.formatMessage({ id: `uploads.line`},{number: err.index})} ${intl.formatMessage({ id: `uploads.validationErrors.${err.errorCode}` })}`)
    const errorText = logMessages.join('\n')
    let errorDisplay
    if (logMessages.length > ERROR_LOG_DISPLAY_LIMIT) {
      errorDisplay = logMessages.slice(0, ERROR_LOG_DISPLAY_LIMIT).join('\n')
    }
    return (
      <Box>
        <Alert severity="error" className={classes.alert}>
          <Typography color="textPrimary" variant="h5">
            <FormattedMessage id="uploads.errors" />
          </Typography>
          <Typography color="textPrimary" variant="h5">
            <FormattedMessage id="uploads.csvErrors" values={{ headers: errors[0] }} />
          </Typography>
        </Alert>
        <div style={{ height: 550 }}>
          <div style={{ height: 500 }}>
            <Grid
              container
              direction="row"
              justifycontent="flex-start"
              alignItems="baseline"
              style={{ paddingTop: '1rem' }}
              spacing={1}>
              <Grid item >
                <Typography color="textPrimary" gutterBottom variant="h5">
                  <FormattedMessage id="uploads.errorList" />
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={intl.formatMessage({ id: 'buttons.copyToClipboard' })}>
                  <IconButton aria-label="copy to clipboard" onClick={() => copyToClipboard(errorText)}>
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <TextareaAutosize
              className={classes.textareaAutosize}
              aria-label="errors area"
              defaultValue={errorDisplay || errorText}
              disabled
              style={{ overflow: 'auto' }}
            />
            {errorDisplay &&
              <Alert severity="error" variant="outlined" className={classes.alertOutlined}>
                <Typography color="textPrimary" variant="h6">
                  <FormattedMessage id="uploads.errorDisplayLimit" values={{ limit: ERROR_LOG_DISPLAY_LIMIT }} />
                </Typography>
              </Alert>
            }
          </div>
        </div>
      </Box>
    )
  }

  if (error && error === 'size') {
    return (
      <Alert severity="error" className={classes.alert}>
        <Typography color="textPrimary" variant="h5">
          <FormattedMessage id="uploads.errorSize" values={{ total: size }} />
        </Typography>
      </Alert>
    )
  }

  const getDownloadButton = (id, filePath) => {
    return (
      <IconButton onClick={() => uploadService.download(id, filePath)}>
        <CloudDownload style={{ marginRight: '8px' }} color="primary" />
        <Typography>
          <FormattedMessage id="uploads.download" />
        </Typography>
      </IconButton>
    )
  }

  const rows = errors
    ? errors.map((rowError, idx) => {
      return { id: idx, ...rowError }
    })
    : uploads.map((_upload, idx) => {
      return {
        id: _upload._id,
        sequenceNR: idx + 1,
        administration: _upload.administration.code,
        year: _upload.year,
        size: _upload.size,
        status: _upload.status,
        created: formattedDate(new Date(_upload.created)),
        download: getDownloadButton(_upload._id, _upload.filePath)
      }
    })

  return (
    <Box>
      {errors ? (
        <Alert severity="error" className={classes.alert}>
          <Typography color="textPrimary" variant="h5">
            <FormattedMessage id="uploads.errors" />
          </Typography>
        </Alert>
      ) : (
        <div>
          <Typography variant="h5">
            <FormattedMessage id="uploads.processedTitle" />
          </Typography>
          <IconButton onClick={() => bulkUploadService.download(bulkUpload._id, `bulk-upload-${bulkUpload.filePath}`)}>
            <CloudDownload style={{ marginRight: '8px' }} color="primary" />
            <Typography>
              <FormattedMessage id="uploads.download" />
            </Typography>
          </IconButton>
        </div>
      )}
      <div style={{ height: 550, width: '100%' }}>
        <div style={{ height: 500, width: '100%' }}>
          <DataGrid
            disableSelectionOnClick
            rowsPerPageOptions={[10, 20, 50, 100]}
            columns={columns}
            rows={rows}
            pageSize={10}
            headerHeight={42}
            rowHeight={30}
          />
        </div>
      </div>
    </Box>
  )
}

export default injectIntl(BulkUploadDetails)

BulkUploadDetails.propTypes = {
  upload: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  intl: PropTypes.object
}
