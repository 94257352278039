import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { AppBar, Box, Hidden, IconButton, Toolbar, makeStyles } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { FormattedMessage, injectIntl } from 'react-intl'
//
import Logo from 'src/components/Logo'
import FederalHeader from 'src/components/FederalHeader'
import LocalizedLink from 'src/i18n/LocalizedLink'
import { accountService } from 'src/services'

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  link: {
    color: theme.palette.text.primary
  }
}))

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <FederalHeader />
      <Toolbar>
        <LocalizedLink to="/app">
          <Logo />
        </LocalizedLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit" aria-haspopup="true" onClick={handleClick}>
            <AccountCircle />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleClose}>
            <LocalizedLink className={classes.link} to="/app/account">
              <FormattedMessage id="header.account" />
            </LocalizedLink>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <LocalizedLink className={classes.link} onClick={accountService.logout} to="/login">
              <FormattedMessage id="header.logout" />
            </LocalizedLink>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
}

export default injectIntl(TopBar)
