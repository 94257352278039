import React from 'react'
import { IntlContext } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
//
import { enabledLocales, LocalizedLink } from '../i18n'

const useStyles = makeStyles(theme => ({
  root: {
    '& .link': {
      fontFamily: 'Arial, Verdana, sans-serif',
      marginLeft: theme.spacing(1),
      borderRadius: '3px',
      color: '#484848',
      padding: '4px',
      textDecoration: 'none',
      textTransform: 'uppercase',
      fontSize: '11px'
    },
    '& .link:hover': {
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#666'
    },
    '& .link.active': {
      color: '#fff',
      backgroundColor: '#767676'
    },
    '& .link:not(.active)': {}
  }
}))

const LangSwitcher = () => {
  const classes = useStyles()
  const location = useLocation()
  const intl = React.useContext(IntlContext)
  const pathname = `${location.pathname.replace(`/${intl.locale}`, '')}${location.search}`

  if (pathname === '/') {
    return null
  }

  return (
    <Typography className={classes.root}>
      {enabledLocales.map(key => (
        <Link
          key={`lang-${key}`}
          component={LocalizedLink}
          to={pathname}
          lang={key}
          color="inherit"
          className={intl.locale === key ? 'active link' : 'link'}
        >
          {key}
        </Link>
      ))}
    </Typography>
  )
}

export default LangSwitcher
