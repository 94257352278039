import React from 'react'
import PropTypes from 'prop-types'
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography'
import { injectIntl, FormattedMessage } from 'react-intl'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
//
import { uploadService } from 'src/services'

function formattedDate(date) {
  const mm = date.getMonth() + 1
  const dd = date.getDate()
  const hh = date.getHours()
  const min = date.getMinutes()

  const day = [(dd > 9 ? '' : '0') + dd, (mm > 9 ? '' : '0') + mm, date.getFullYear()].join('-')
  const time = [(hh > 9 ? '' : '0') + hh, (min > 9 ? '' : '0') + min].join(':')

  return `${day} ${time}`
}

const checkCurrenYear = (date) => {
  const currentYear = new Date().getFullYear()
  return date && date.getFullYear() === currentYear
}
const PreviousPopUploads = ({ newUpload, union }) => {
  const [loading, setLoading] = React.useState(true)
  const [proofsOfPayments, setProofsOfPayments] = React.useState([])


  const getProofsOfPayments = async () => {
    // eslint-disable-next-line no-underscore-dangle
    uploadService.getByQueryProofOfPayments({ union: union._id }).then(pops => {
      const res = pops
        .map(pop => {
          return {
            // eslint-disable-next-line no-underscore-dangle
            id: pop._id,
            date: formattedDate(new Date(pop.created)),
            isCurrentYear: checkCurrenYear(new Date(pop.created)),
            ...pop
          }
        })
        .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
      setProofsOfPayments(res)
      setLoading(false)
    })
  }


  React.useEffect(() => {
    getProofsOfPayments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUpload])


  return (
    <>
      {loading && (
        <div>
          <CircularProgress size={20} />
          <FormattedMessage id="global.loading" />
        </div>
      )}


      {!loading && proofsOfPayments && (
        <>
          <Typography color="textSecondary" gutterBottom variant="h5" >
            <FormattedMessage id="unionsView.proofsOfPayments" />
          </Typography>

          <List component="nav" aria-label="main">
            {proofsOfPayments.map(pop => (
              <ListItem key={pop.id} style={{ padding: '2px' }} onClick={() =>
                uploadService.downloadProofOfPayments(pop.id, pop.fileName)} button>
                <ListItemIcon style={{ minWidth: '32px' }}>
                  {pop.mimetype.includes('pdf') && <img src="/static/images/pdf_icon.png" width="30px" alt='pdf' />}
                  {pop.mimetype.includes('xml') && <img src="/static/images/xml_icon.png" width="30px" alt='xml' />}
                  {(!pop.mimetype || (!pop.mimetype.includes('pdf') && !pop.mimetype.includes('xml'))
                  ) && <DescriptionOutlinedIcon />}
                </ListItemIcon>
                <ListItemText
                  primary={<small>{pop.fileName}
                  </small>}
                  secondary={
                    <small>
                      {pop.date}
                    </small>
                  }
                />
                {pop.isCurrentYear &&
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={async () => {
                      await uploadService.deleteProofOfPayments(pop.id);
                      getProofsOfPayments()
                    }}>
                      <DeleteIcon color="disabled" />
                    </IconButton>
                  </ListItemSecondaryAction>
                }
              </ListItem>
            ))}
          </List>
        </>
      )}

    </>
  )
}

PreviousPopUploads.propTypes = {
  newUpload: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  union: PropTypes.object
}

export default injectIntl(PreviousPopUploads)
