/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import LanguageIcon from '@material-ui/icons/Language'
import ListItemText from '@material-ui/core/ListItemText'
//
import Page from 'src/components/Page'
import { enabledLocales, LocalizedLink } from 'src/i18n'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.white,
    minHeight: '100%'
  },
  langs: {
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center'
  },
  lang: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  langLink: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  alert: {
    textAlign: 'left',
    marginTop: '0.2rem'
  }
}))

const title = {
  nl: 'Welkom',
  fr: 'Bienvenu',
  de: 'Willkommen',
  en: 'Welcome'
}

const LandingView = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Homepage">
      <Container maxWidth="md" align="center">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <img src="/static/logo.gif" width="64" height="64" alt="ICT Kanselarij - Chancellerie" />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md" align="center">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <h1>
              {enabledLocales.map((loc, idx) => (
                <span>
                  {title[loc]}
                  {idx < enabledLocales.length - 1 ? ' • ' : null}
                </span>
              ))}
            </h1>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md" align="center">
        <Grid container spacing={4} className={classes.langs}>
          <Grid item xs={12} align="center">
            <LanguageIcon />
            <List component="nav" aria-label="language">
              {enabledLocales.includes('fr') ? (
                <ListItem className={classes.lang} component={LocalizedLink} button to="/login" lang="fr">
                  <ListItemText className={classes.langLink} primary="Français • Frans" />
                </ListItem>
              ) : null}

              {enabledLocales.includes('nl') ? (
                <ListItem className={classes.lang} component={LocalizedLink} button to="/login" lang="nl">
                  <ListItemText className={classes.langLink} primary="Nederlands • Néerlandais" />
                </ListItem>
              ) : null}

              {enabledLocales.includes('de') ? (
                <ListItem className={classes.lang} component={LocalizedLink} button to="/login" lang="de">
                  <ListItemText className={classes.langLink} primary="Allemand • Duits" />
                </ListItem>
              ) : null}

              {enabledLocales.includes('en') ? (
                <ListItem className={classes.lang} component={LocalizedLink} button to="/login" lang="en">
                  <ListItemText className={classes.langLink} primary="Anglais • Engels" />
                </ListItem>
              ) : null}
            </List>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg" align="center">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {enabledLocales.includes('fr') ? (
              <Alert severity="info" className={classes.alert}>
                <Typography color="textPrimary" variant="h6">
                  Veuillez noter que l'application Primes Syndicales est optimisée pour les navigateurs modernes et peut
                  ne pas fonctionner correctement sur les anciens navigateurs.
                </Typography>
                <Typography color="textPrimary" variant="h6">
                  Nous vous recommandons d'utiliser la dernière version de Microsoft Edge, Google Chrome, Mozilla
                  Firefox ou Apple Safari.
                </Typography>
              </Alert>
            ) : null}

            {enabledLocales.includes('nl') ? (
              <Alert severity="info" className={classes.alert}>
                <Typography color="textPrimary" variant="h6">
                  Houd er rekening mee dat de Primes Syndicales-applicatie is geoptimaliseerd voor moderne browsers en
                  mogelijk niet goed werkt op oudere browsers.
                </Typography>
                <Typography color="textPrimary" variant="h6">
                  We raden aan om de nieuwste versie van Microsoft Edge, Google Chrome, Mozilla Firefox of Apple Safari
                  te gebruiken.
                </Typography>
              </Alert>
            ) : null}

            {enabledLocales.includes('de') ? (
              <Alert severity="info" className={classes.alert}>
                <Typography color="textPrimary" variant="h6">
                  Bitte beachten Sie, dass die Anwendung Primes Syndicales für moderne Browser optimiert ist und in
                  älteren Browsern möglicherweise nicht gut funktioniert.
                </Typography>
                <Typography color="textPrimary" variant="h6">
                  Wir empfehlen die Verwendung der neuesten Version von Microsoft Edge, Google Chrome, Mozilla Firefox
                  oder Apple Safari.
                </Typography>
              </Alert>
            ) : null}

            {enabledLocales.includes('en') ? (
              <Alert severity="info" className={classes.alert}>
                <Typography color="textPrimary" variant="h6">
                  Please note that the Primes Syndicales application is optimized for modern browsers and may not work
                  well on older browsers.
                </Typography>
                <Typography color="textPrimary" variant="h6">
                  We recommend using the latest version of Microsoft Edge, Google Chrome, Mozilla Firefox, or Apple
                  Safari.
                </Typography>
              </Alert>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default LandingView
