import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import { useSnackbar } from 'notistack'
import { useConfirm } from 'material-ui-confirm'
import { administrationsAccountsService, aggregationsService } from 'src/services'
import getAdministrationsTitle from 'src/utils/getAdministrationsTitle'
import { injectIntl, FormattedMessage } from 'react-intl'
//

const moment = require('moment');

const formatDate = value => value && moment(value).format('DD/MM/YYYY')

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    marginRight: '10px'
  }
}))

const AccessRequestsAdministrations = ({ intl }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirm()
  const [loading, setLoading] = React.useState(true)
  const [accessRequests, setAccessRequests] = React.useState([])
  const [selected, setSelected] = React.useState([])

  const columns = [
    { field: 'administrationCode', headerName: intl.formatMessage({ id: 'dataGrid.admCode' }), width: 80 },
    {
      field: 'administrationName',
      headerName: intl.formatMessage({ id: 'dataGrid.admName' }),
      width: 260,
      renderCell: params => (
        <Tooltip placement="top" title={params.value}>
          <Box>{params.value}</Box>
        </Tooltip>
      )
    },
    { field: 'firstName', headerName: intl.formatMessage({ id: 'dataGrid.firstName' }), width: 130 },
    { field: 'lastName', headerName: intl.formatMessage({ id: 'dataGrid.lastName' }), width: 130 },
    {
      field: 'email',
      headerName: intl.formatMessage({ id: 'dataGrid.email' }),
      width: 150,
      renderCell: params => (
        <Tooltip placement="top" title={params.value}>
          <Box>{params.value}</Box>
        </Tooltip>
      )
    },
    { field: 'totalUsers', headerName: intl.formatMessage({ id: 'dataGrid.users' }), width: 80, type: 'number', },
    {
      field: 'lastLoginDate',
      headerName: intl.formatMessage({ id: 'dataGrid.lastLogin' }),
      width: 120,
      type: 'date',
      renderCell: params => {
        if (params.value) {
          const formattedDate = formatDate(params.value)
          return <Tooltip placement="top" title={formattedDate} >
            <Box>{formattedDate}</Box>
          </Tooltip >
        }
        return null
      }
    },
    {
      field: 'lastLoginEmail',
      headerName: intl.formatMessage({ id: 'dataGrid.lastLoginEmail' }),
      width: 150,
      renderCell: params => params.value && (
        <Tooltip placement="top" title={params.value}>
          <Box>{params.value}</Box>
        </Tooltip>
      )
    }
  ]

  const mappedRequests = record => ({
    // eslint-disable-next-line no-underscore-dangle
    id: record._id,
    administrationCode: record.administration && record.administration.code,
    administrationName:
      record.administration && record.administration.name && getAdministrationsTitle(record.administration.name),
    lastName: record.account.lastName,
    firstName: record.account.firstName,
    email: record.account.email,
    lastLoginDate: record.lastLoginDate,
    lastLoginEmail: record.lastLoginEmail,
    totalUsers: record.totalUsers
  })

  const refreshAccessRequest = () => aggregationsService.getAccountAdministrationsExtended('PENDING')
    .then(records => {
      const requests = records.map(mappedRequests)
      setAccessRequests(requests)
      setLoading(false)
      setSelected([])
    })

  React.useEffect(() => {
    setLoading(true)
    refreshAccessRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectionChange = selection => {
    setSelected(selection.rowIds)
  }

  const handleGrantAccess = () => {
    confirm({
      title: intl.formatMessage({ id: 'confirmations.title' }),
      description: intl.formatMessage({ id: 'confirmations.description' })
    }).then(() => {
      setLoading(true)
      enqueueSnackbar(`Granting access to ${selected.length} requests`, { variant: 'info' })
      const payload = {
        accountsAdministrations: selected.map(id => ({ id, status: 'APPROVED' }))
      }

      administrationsAccountsService.updateAccountsAdministrations(payload).then(() => {
        refreshAccessRequest()
          .then(() => {
            enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
          })
      })
    })
  }

  const handleRefuseAccess = () => {
    confirm({
      title: intl.formatMessage({ id: 'confirmations.title' }),
      description: intl.formatMessage({ id: 'confirmations.description' })
    }).then(() => {
      setLoading(true)
      enqueueSnackbar(`Refusing access to ${selected.length} requests`, { variant: 'info' })
      administrationsAccountsService.deleteAccountsAdministrations({ accountsAdministrations: selected }).then(() => {
        refreshAccessRequest()
          .then(() => {
            enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
          })
      })
    })
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', marginTop: '-22px' }}>
        <div style={{ minHeight: '385px', flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}>
          {accessRequests.length ? (
            <DataGrid
              rows={accessRequests}
              rowsPerPageOptions={[10, 20, 50, 100]}
              columns={columns}
              pageSize={10}
              headerHeight={32}
              rowHeight={30}
              checkboxSelection
              autoHeight
              loading={loading}
              onSelectionChange={handleSelectionChange}
            />
          ) : (
            <div>
              <Typography>
                {loading ? '' : <FormattedMessage id="messages.noRequests" />}
              </Typography>
            </div>
          )}
        </div>
        <div style={{ flexGrow: .1, width: '100%' }}>
          {accessRequests.length ? (
            <Box display="flex" justifyContent="flex-end">
              <Button
                className={classes.button}
                onClick={handleGrantAccess}
                color="primary"
                variant="contained"
                disabled={!selected.length || loading}
              >
                <FormattedMessage id="buttons.grantAccess" />
              </Button>
              <Button
                className={classes.button}
                onClick={handleRefuseAccess}
                variant="contained"
                style={{ color: selected.length ? 'red' : 'gray' }}
                disabled={!selected.length || loading}
              >
                <FormattedMessage id="buttons.refuseAccess" />
              </Button>
            </Box>
          ) : null}
        </div>
      </div>
      {/* <div style={{ flexGrow: .1 }}>
            {accessRequests.length ? (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  className={classes.button}
                  onClick={handleGrantAccess}
                  color="primary"
                  variant="contained"
                  disabled={!selected.length || loading}
                >
                  <FormattedMessage id="buttons.grantAccess" />
                </Button>
                <Button
                  className={classes.button}
                  onClick={handleRefuseAccess}
                  variant="contained"
                  style={{ color: selected.length ? 'red' : 'gray' }}
                  disabled={!selected.length || loading}
                >
                  <FormattedMessage id="buttons.refuseAccess" />
                </Button>
              </Box>
            ) : null}
          </div>
        </div> */}
      {/* </div> */}
    </>
  )
}

AccessRequestsAdministrations.propTypes = {
  intl: PropTypes.object
}

export default injectIntl(AccessRequestsAdministrations)
