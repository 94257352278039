import config from 'src/utils/config'
import fetchWrapper from 'src/utils/fetch'

const baseUrl = `${config.apiUrl}/aggregations`

function administrationAuthorizationById(id) {
  return fetchWrapper.get(`${baseUrl}/administrations/${id}`, {})
}

function administrationAuthorizationByCode(administrationCode) {
  return fetchWrapper.get(`${baseUrl}/administrations`, { code: administrationCode })
}

function getAdministrationsExtended() {
  return fetchWrapper.get(`${baseUrl}/administrations/extended`)
}

function exportAdministrationsExtended(filename) {
  return fetchWrapper.getOriginalFile(`${baseUrl}/administrations/extended/export/csv`, filename)
}

function accountsAdministrationsAuthorization(userId) {
  return fetchWrapper.get(`${baseUrl}/accounts-administrations`, { account: userId })
}

function getAccountAdministrationsExtended(status) {
  return fetchWrapper.get(`${baseUrl}/accounts-administrations/extended`, { status })
}

export default {
  administrationAuthorizationById,
  administrationAuthorizationByCode,
  getAdministrationsExtended,
  exportAdministrationsExtended,
  accountsAdministrationsAuthorization,
  getAccountAdministrationsExtended
}
