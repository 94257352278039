import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Container, Grid, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { injectIntl, FormattedMessage } from 'react-intl'
//
import getUnionTitle from 'src/utils/getAdministrationsTitle'
import Page from 'src/components/Page'
import { accountService, accountsUnionsService } from 'src/services'
import getUnionCode from 'src/utils/getUnionCode'
import { Alert, AlertTitle } from '@material-ui/lab'
import PopUploadForm from './PopUploadForm'
import PreviousPopUploads from './PreviousPopUploads'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const sortForEndYear = (a, b) => {
  if (!a.endYear) return -1
  if (!b.endYear) return 1
  return (a.endYear - b.endYear)
}

const UnionsView = ({ intl }) => {
  const classes = useStyles()
  const user = accountService.userValue

  const [upload, setUpload] = React.useState()
  const [union, setUnion] = React.useState()
  const [unionNotFound, setUnionNotFound] = React.useState(false)
  const [status, setStatus] = React.useState()
  const [processing, setProcessing] = React.useState(false)

  const handleProcessingStatus = (completed = false) => {
    if (processing && completed) {
      setProcessing(false)
    }
  }

  React.useEffect(() => {
    setUnionNotFound(false)

    accountsUnionsService.getByUserId(user.id).then(records => {
      const sortedRecords = records.filter(au => au.union).sort(sortForEndYear)

      if (sortedRecords.length > 0) {
        setUnion(sortedRecords[0].union)
        setStatus(sortedRecords[0].status)
      } else {
        setUnionNotFound(true)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page className={classes.root} title={intl.formatMessage({ id: 'navigation.union' })}>
      {unionNotFound &&
        <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
          Union not found
        </div>
      }
      {!unionNotFound && !union &&
        < div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress />
        </div>
      }
      {!!union &&
        <Container maxWidth="xl">
          <Grid container spacing={1}>
            <Grid item xs={12} >
              <Grid item xs={12}>
                {union && (
                  <Typography color="textPrimary" gutterBottom variant="h3">
                    {`${getUnionCode(union.code)} - ${getUnionTitle(union.name)}`}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {status === "PENDING" &&
              <Grid item xs={12}>
                <Alert className={classes.alert} severity="warning">
                  <AlertTitle>
                    <FormattedMessage id="unionsView.pendingAuthorizationTitle" />
                  </AlertTitle>
                  <Typography color="textPrimary" variant="h4">
                    <FormattedMessage id="unionsView.pendingAuthorizationMessage" />
                  </Typography>
                </Alert>
              </Grid>
            }
            {status === "APPROVED" && <>
              <Grid item xs={6}>
                <Typography style={{ marginBottom: '2rem' }} color="textSecondary" gutterBottom variant="h5">
                  <FormattedMessage id="unionsView.proofsOfPaymentUpload" />
                </Typography>
                <PopUploadForm union={union} onUpload={setUpload} />
              </Grid>

              <Grid item xs={6}>
                <PreviousPopUploads newUpload={upload} handleProcessingStatus={handleProcessingStatus} useOverflow={upload} union={union} />
              </Grid>
            </>
            }
          </Grid>
        </Container>
      }
    </Page >
  )
}

export default injectIntl(UnionsView)

UnionsView.propTypes = {
  intl: PropTypes.object
}
