import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FormattedMessage, injectIntl } from 'react-intl'
//
import { administrationsService, accountService } from 'src/services'
import getAdministrationsTitle from 'src/utils/getAdministrationsTitle'

const sortForEndYear = (a, b) => {
  if (!a.endYear) return -1
  if (!b.endYear) return 1
  return (a.endYear - b.endYear)
}

const AdministrationsSelect = ({ onChange, intl, filtered = [] }) => {
  const [loading, setLoading] = React.useState(true)
  const [administrations, setAdministrations] = React.useState([])

  const user = accountService.userValue

  const getAllAvailableAdministrations = async () => {
    if (user && user.id) {
      return administrationsService.getAllExcludingUser(user.id)
    }
    return administrationsService.getAll()
  }

  React.useEffect(() => {
    const currentYear = new Date(Date.now()).getFullYear()
    const actualEndYear = currentYear - 2
    getAllAvailableAdministrations().then(allAdministrations => {
      const titledAdministrations = allAdministrations
        .sort(sortForEndYear)
        .filter(administation => !administation.endYear || administation.endYear >= actualEndYear)
        .reduce((acc, cur) => {
          const index = acc.findIndex(administration => administration.code === cur.code)
          if (index >= 0) {
            const administration = acc[index]
            if (!administration.endYear) {
              return acc
            }
            if (!cur.endYear) {
              if (index) {
                acc[index] = cur
                return acc
              }
            }
          }
          acc.push(cur)
          return acc
        }, [])
        .map(administration => {
          administration.title = `${administration.code} - ${getAdministrationsTitle(administration.name)}`
          administration.type = getAdministrationsTitle(administration.name)
          return administration
        })
      setAdministrations(titledAdministrations)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filteredAdmins =
    // eslint-disable-next-line no-underscore-dangle
    (administrations && filtered && administrations.filter(administration => !filtered.includes(administration._id))) ||
    []

  return (
    <Field
      id="administrations"
      name="administrations"
      multiple
      options={filteredAdmins}
      onChange={onChange}
      component={Autocomplete}
      getOptionSelected={(option, value) => {
        // eslint-disable-next-line no-underscore-dangle
        return option._id === value._id
      }}
      getOptionLabel={option => option.title}
      groupBy={option => option.type}
      renderInput={params => {
        return loading ? (
          <div>
            <CircularProgress color="inherit" size={20} />
            <FormattedMessage id="global.loading" />
          </div>
        ) : (
          <TextField
            {...params}
            label={intl.formatMessage({ id: 'administrationSelect.placeholder' })}
            variant="outlined"
            placeholder={intl.formatMessage({ id: 'administrationSelect.label' })}
          />
        )
      }}
    />
  )
}

AdministrationsSelect.propTypes = {
  onChange: PropTypes.func,
  filtered: PropTypes.array,
  intl: PropTypes.object
}

export default injectIntl(AdministrationsSelect)
