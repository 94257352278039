/* eslint-disable no-underscore-dangle */
import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Container,
  Dialog,
  DialogTitle
} from '@material-ui/core'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import DialogActions from '@material-ui/core/DialogActions'
import { injectIntl, FormattedMessage } from 'react-intl'
//
import { administrationsService, accountService, administrationsAccountsService } from 'src/services'
import { isEmail } from 'src/utils/validations'

function checkNames() {
  const nameNl = this.parent.nameNl || ''
  const nameFr = this.parent.nameFr || ''
  if (!nameNl.length && !nameFr.length) {
    return false
  }
  return true
}

const CreateAdministration = ({ open, handleClose, intl }) => {
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
      <DialogTitle>
        <FormattedMessage id="administrationsView.createAdministration" />
      </DialogTitle>
      <Container>
        <Grid container xl>
          <Grid item xs={12}>
            <Formik
              onSubmit={async ({ code, nameFr, nameNl, emails, maxUploadsPerYear }, { resetForm }) => {
                const data = {
                  code,
                  name: {
                    nl: nameNl,
                    fr: nameFr
                  },
                  maxUploadsPerYear
                }

                const callbackOnSubmitSuccesful = () => {
                  enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), {
                    variant: 'success',
                    autoHideDuration: 10000
                  })
                  resetForm({})
                  handleClose()
                }

                administrationsService
                  .create(data)
                  .then(admin => {
                    const userEmails = []

                    emails
                      .split(/[\n,]/)
                      .map(email => email.trim())
                      .filter(email => email && email.length)
                      .map(email => email.toLowerCase())
                      .forEach(email => {
                        if (isEmail(email) && !userEmails.includes(email)) {
                          userEmails.push(email)
                        } else {
                          enqueueSnackbar(intl.formatMessage({ id: 'administrationsView.emailInvalid' }, { email }), {
                            variant: 'error',
                            autoHideDuration: 3000
                          })
                        }
                      })

                    if (userEmails.length) {
                      accountService.getAll().then(users => {
                        const promises = users
                          .filter(user => userEmails.includes(user.email.toLowerCase()))
                          .map(user => administrationsAccountsService
                            .createAccountsAdministrationRecord({
                              account: user.id,
                              administration: admin._id
                            })
                            .then(() => {
                              enqueueSnackbar(
                                intl.formatMessage({ id: 'administrationsView.emailAdded' }, { email: user.email }),
                                {
                                  variant: 'info',
                                  autoHideDuration: 3000
                                }
                              )
                            })
                            .catch(() => {
                              enqueueSnackbar(
                                intl.formatMessage({ id: 'administrationsView.emailError' }, { email: user.email }),
                                {
                                  variant: 'error',
                                  autoHideDuration: 3000
                                }
                              )
                            })
                          )
                        Promise.all(promises).then(() => {
                          callbackOnSubmitSuccesful()
                        })
                      })
                    } else {
                      callbackOnSubmitSuccesful()
                    }
                  })
                  .catch(() => {
                    enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
                      variant: 'error',
                      autoHideDuration: 10000
                    })
                  })
              }}
              initialValues={{
                code: '',
                nameFr: '',
                nameNl: '',
                emails: '',
                maxUploadsPerYear: 1
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string()
                  .min(4)
                  .max(4)
                  .required(intl.formatMessage({ id: 'validations.field' })),
                nameNl: Yup.string()
                  .max(160)
                  .test('nameFr-check', intl.formatMessage({ id: 'validations.field' }), checkNames),
                nameFr: Yup.string()
                  .max(160)
                  .test('nameNl-check', intl.formatMessage({ id: 'validations.field' }), checkNames)
              })}
            >
              {({ handleSubmit, values, touched, errors, handleBlur, handleChange }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader title={intl.formatMessage({ id: 'administrationsView.title' })} />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.code && errors.code)}
                            fullWidth
                            helperText={touched.code && errors.code}
                            label={intl.formatMessage({ id: 'administrationsView.code' })}
                            name="code"
                            required
                            value={values.code}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.maxUploadsPerYear && errors.maxUploadsPerYear)}
                            fullWidth
                            helperText={touched.maxUploadsPerYear && errors.maxUploadsPerYear}
                            label={intl.formatMessage({ id: 'administrationsView.maxUploadsPerYear' })}
                            name="maxUploadsPerYear"
                            value={values.maxUploadsPerYear}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            type="number"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.nameNl && errors.nameNl)}
                            fullWidth
                            helperText={touched.nameNl && errors.nameNl}
                            label={intl.formatMessage({ id: 'administrationsView.nameNl' })}
                            name="nameNl"
                            value={values.nameNl}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.nameFr && errors.nameFr)}
                            fullWidth
                            helperText={touched.nameFr && errors.nameFr}
                            label={intl.formatMessage({ id: 'administrationsView.nameFr' })}
                            name="nameFr"
                            value={values.nameFr}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.emails && errors.emails)}
                            fullWidth
                            helperText={touched.emails && errors.emails}
                            label={intl.formatMessage({ id: 'administrationsView.addUsers' })}
                            name="emails"
                            multiline
                            rows={2}
                            value={values.emails}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Box my={2} mt={3}>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        <FormattedMessage id="buttons.cancel" />
                      </Button>
                      <Button color="primary" size="large" type="submit" variant="contained">
                        <FormattedMessage id="buttons.create" />
                      </Button>
                    </DialogActions>
                  </Box>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </Dialog >
  )
}

export default injectIntl(CreateAdministration)

CreateAdministration.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object
}
