/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react'
import { IntlProvider } from 'react-intl'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
//
import locales from './locales'

const defaultLocale = locales.nl

export const IntlContext = React.createContext(defaultLocale)
export const IntlContextProvider = IntlContext.Provider
export const IntlContextConsumer = IntlContext.Consumer

const activeLocales = process.env.REACT_APP_ENABLED_LOCALES?.split(',') || []

export const enabledLocales = activeLocales.length
  ? Object.keys(locales).filter(loc => activeLocales.includes(loc))
  : Object.keys(locales)

const flattenMessages = (nestedMessages, prefix = '') => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      // eslint-disable-next-line no-param-reassign
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

export const getMessages = key => {
  try {
    const messages = require(`./messages/${key}.json`)

    return flattenMessages(messages)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`[ERROR] couldn't find file "${key}/${key}.json"`)
    throw error
  }
}

export const getLangSlug = (slug, lang) => {
  return `/${lang}/${slug}`.replace('//', '/')
}

export { default as locales } from './locales'
export { default as LocalizedLink } from './LocalizedLink'
export { default as LocalizedRedirect } from './LocalizedRedirect'
export { default as useLocalizedNavigate } from './hooks'

export const I18nProvider = ({ children }) => {
  const location = useLocation()
  const lang = location.pathname.split('/')[1]
  const locale = locales[lang] || defaultLocale

  return (
    <IntlProvider locale={locale.id} key={locale.id} messages={getMessages(locale.id)}>
      <IntlContextProvider value={locale}>{children}</IntlContextProvider>
    </IntlProvider>
  )
}

I18nProvider.propTypes = {
  children: PropTypes.node
}
