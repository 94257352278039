import config from 'src/utils/config'
import fetchWrapper from 'src/utils/fetch'

const baseUrl = `${config.apiUrl}/uploads`
const downloadBaseUrl = `${config.apiUrl}/download`
const unionUploadBaseUrl = `${config.apiUrl}/union-uploads`
const proofOfPaymentBaseUrl = `${config.apiUrl}/proof-of-payment-uploads`


function upload(formData) {
  return fetchWrapper.postMultipart(baseUrl, formData)
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`)
}

function getAll() {
  return fetchWrapper.get(baseUrl)
}

function getByQuery(query) {
  return fetchWrapper.get(`${baseUrl}`, query)
}

function download(id, fileName) {
  return fetchWrapper.getZipFile(`${downloadBaseUrl}/${id}`, fileName)
}

function downloadCSV(id, fileName) {
  const csvFileName = fileName.replaceAll('.zip', '.csv')
  return fetchWrapper.getOriginalFile(`${baseUrl}/${id}/csv`, csvFileName)
}

function clearControlsCache() {
  return fetchWrapper.post(`${baseUrl}/clear-controls-cache`)
}

// UNION 
function uploadForUnion(formData) {
  return fetchWrapper.postMultipart(unionUploadBaseUrl, formData)
}

function getByIdForUnion(id) {
  return fetchWrapper.get(`${unionUploadBaseUrl}/${id}`)
}

function getAllForUnion() {
  return fetchWrapper.get(unionUploadBaseUrl)
}

function getByQueryForUnion(query) {
  return fetchWrapper.get(`${unionUploadBaseUrl}`, query)
}

// Proof of payment
function uploadProofOfPayment(formData) {
  return fetchWrapper.postMultipart(proofOfPaymentBaseUrl, formData)
}

function getByIdProofOfPayment(id) {
  return fetchWrapper.get(`${proofOfPaymentBaseUrl}/${id}`)
}

function getAllProofOfPayment() {
  return fetchWrapper.get(proofOfPaymentBaseUrl)
}

function getByQueryProofOfPayments(query) {
  return fetchWrapper.get(`${proofOfPaymentBaseUrl}`, query)
}

function downloadProofOfPayments(id, fileName) {
  return fetchWrapper.getOriginalFile(`${proofOfPaymentBaseUrl}/${id}/${fileName}`, fileName)
}

function deleteProofOfPayments(id) {
  return fetchWrapper.delete(`${proofOfPaymentBaseUrl}/${id}`, true)
}



export default {
  upload,
  download,
  downloadCSV,
  getAll,
  getById,
  getByQuery,
  uploadForUnion,
  getByIdForUnion,
  getAllForUnion,
  getByQueryForUnion,
  uploadProofOfPayment,
  getByIdProofOfPayment,
  getAllProofOfPayment,
  getByQueryProofOfPayments,
  downloadProofOfPayments,
  deleteProofOfPayments,
  clearControlsCache
}