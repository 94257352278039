import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Container,
  Dialog,
  DialogTitle
} from '@material-ui/core'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { injectIntl, FormattedMessage } from 'react-intl'
//
import { administrationsService } from 'src/services'

const EditAdministration = ({ id, open, handleClose, intl }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [administration, setAdministration] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    administrationsService
      .getById(id)
      .then(admin => {
        setAdministration(admin)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [id])

  return (
    <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
      <DialogTitle>
        <FormattedMessage id="administrationsView.editTitle" />
      </DialogTitle>
      <Container>
        <Grid container xl>
          <Grid item xs={12}>
            {loading && (
              <div>
                <CircularProgress color="inherit" size={20} />
                <FormattedMessage id="global.loading" />
              </div>
            )}
            {!loading && !administration && <p>error loading data!</p>}
            {!loading && administration ? (
              <Formik
                onSubmit={async ({ code, nameFr, nameNl, maxUploadsPerYear }, { resetForm }) => {
                  const data = {
                    code,
                    name: {
                      nl: nameNl,
                      fr: nameFr
                    },
                    maxUploadsPerYear
                  }

                  administrationsService
                    .update(id, data)
                    .then(() => {
                      enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), {
                        variant: 'success',
                        autoHideDuration: 10000
                      })

                      resetForm({})
                      handleClose()
                    })
                    .catch(() => {
                      enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
                        variant: 'error',
                        autoHideDuration: 10000
                      })
                    })
                }}
                initialValues={{
                  // eslint-disable-next-line no-underscore-dangle
                  code: administration.code,
                  nameFr: administration.name.fr,
                  nameNl: administration.name.nl,
                  maxUploadsPerYear: administration.maxUploadsPerYear
                }}
                validationSchema={Yup.object().shape({
                  code: Yup.string()
                    .min(4)
                    .max(4)
                    .required(intl.formatMessage({ id: 'validations.field' })),
                  nameNl: Yup.string().max(160),
                  nameFr: Yup.string().max(160)
                })}
              >
                {({ handleSubmit, values, touched, errors, handleBlur, handleChange }) => (
                  <form onSubmit={handleSubmit}>
                    <Card>
                      <CardHeader title="Administration" />
                      <Divider />
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={Boolean(touched.code && errors.code)}
                              fullWidth
                              helperText={touched.code && errors.code}
                              label={intl.formatMessage({ id: 'administrationsView.code' })}
                              name="code"
                              required
                              value={values.code}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={Boolean(touched.maxUploadsPerYear && errors.maxUploadsPerYear)}
                              fullWidth
                              helperText={touched.maxUploadsPerYear && errors.maxUploadsPerYear}
                              label={intl.formatMessage({ id: 'administrationsView.maxUploadsPerYear' })}
                              name="maxUploadsPerYear"
                              value={values.maxUploadsPerYear}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              type="number"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={Boolean(touched.nameNl && errors.nameNl)}
                              fullWidth
                              helperText={touched.nameNl && errors.nameNl}
                              label={intl.formatMessage({ id: 'administrationsView.nameNl' })}
                              name="nameNl"
                              required
                              value={values.nameNl}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={Boolean(touched.nameFr && errors.nameFr)}
                              fullWidth
                              helperText={touched.nameFr && errors.nameFr}
                              label={intl.formatMessage({ id: 'administrationsView.nameFr' })}
                              name="nameFr"
                              required
                              value={values.nameFr}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>

                    <Box my={2} mt={3}>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                        <Button color="primary" size="large" type="submit" variant="contained">
                          <FormattedMessage id="buttons.update" />
                        </Button>
                      </DialogActions>
                    </Box>
                  </form>
                )}
              </Formik>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  )
}

export default injectIntl(EditAdministration)

EditAdministration.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object
}
