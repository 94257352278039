import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import clsx from 'clsx'
import { Box, Button, Card, CardContent, CardHeader, Divider, TextField, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { injectIntl, FormattedMessage } from 'react-intl'
//
import { accountService } from 'src/services'
import { passwordRegex } from 'src/utils/validations'

const useStyles = makeStyles({
  root: {}
})

const Password = ({ className, intl }) => {
  const { enqueueSnackbar } = useSnackbar()
  const user = accountService.userValue
  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        password: '',
        confirmPassword: ''
      }}
      validationSchema={Yup.object().shape({
        oldPassword: Yup.string()
          .max(64)
          .required(intl.formatMessage({ id: 'validations.field' }))
          .min(6, intl.formatMessage({ id: 'validations.min' }, { number: '6' })),
        password: Yup.string()
          .max(64)
          .required(intl.formatMessage({ id: 'validations.field' }))
          .matches(passwordRegex, intl.formatMessage({ id: 'validations.password' })),
        confirmPassword: Yup.string()
          .max(64)
          .required(intl.formatMessage({ id: 'validations.field' }))
          .oneOf([Yup.ref('password')], intl.formatMessage({ id: 'validations.passwordMatch' }))
      })}
      onSubmit={({ oldPassword, password, confirmPassword }, { setSubmitting, resetForm }) => {
        setSubmitting(true)

        accountService
          .updatePassword(user.id, { oldPassword, password, confirmPassword })
          .then(() => {
            setSubmitting(false)
            enqueueSnackbar(intl.formatMessage({ id: 'account.passwordUpdated' }), { variant: 'success' })
            resetForm({})
          })
          .catch(() => {
            setSubmitting(false)
            enqueueSnackbar(intl.formatMessage({ id: 'account.passwordFailed' }), { variant: 'error' })
            resetForm({})
          })
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form onSubmit={handleSubmit} className={clsx(classes.root, className)}>
          <Card>
            <CardHeader
              subheader={intl.formatMessage({ id: 'account.updatePassword' })}
              title={intl.formatMessage({ id: 'global.password' })}
            />
            <Divider />
            <CardContent>
              <TextField
                error={Boolean(touched.oldPassword && errors.oldPassword)}
                fullWidth
                helperText={touched.oldPassword && errors.oldPassword}
                label={intl.formatMessage({ id: 'passwordUpdate.oldPassword' })}
                margin="normal"
                name="oldPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.oldPassword}
                variant="outlined"
              />{' '}
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label={intl.formatMessage({ id: 'passwordUpdate.newPassword' })}
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                label={intl.formatMessage({ id: 'passwordUpdate.confirmPassword' })}
                margin="normal"
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.confirmPassword}
                variant="outlined"
              />
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button color="primary" disabled={isSubmitting} size="large" type="submit" variant="contained">
                <FormattedMessage id="passwordUpdate.submitButton" />
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  )
}

Password.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object
}

export default injectIntl(Password)
