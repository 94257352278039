import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IntlContext } from 'react-intl'
//

export default function useLocalizedNavigate() {
  const intl = React.useContext(IntlContext)
  const navigate = useNavigate()

  const intlNavigate = React.useCallback(
    (to, options, lang) => {
      const dest = lang ? `/${lang}/${to}` : `/${intl.locale}/${to}`
      navigate(dest, options)
    },
    [intl, navigate]
  )

  return intlNavigate
}
