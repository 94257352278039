import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Divider,
  Grid,
  Typography,
  CircularProgress,
  makeStyles
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
//
import { settingsService } from 'src/services'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
})

const Security = ({ className, intl, ...rest }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = React.useState(true)
  const [defined, setDefined] = React.useState(false)

  React.useEffect(() => {
    settingsService
      .getByKey('zipSecret')
      .then(res => {
        setDefined(!!(res.value && res.value.length))
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  return loading ? (
    <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
      <CircularProgress />
      <Typography color="textPrimary" variant="h4">
        <FormattedMessage id="global.loading" />
      </Typography>
    </div>
  ) : (
    <Formik
      initialValues={{
        zipSecret: ''
      }}
      validationSchema={Yup.object().shape({
        zipSecret: Yup.string()
          .min(12)
          .max(16)
          .required(intl.formatMessage({ id: 'validations.field' }))
      })}
      onSubmit={({ zipSecret }, { setSubmitting, resetForm }) => {
        setSubmitting(true)

        settingsService
          .create({ key: 'zipSecret', value: zipSecret })
          .then(() => {
            resetForm({})
            setSubmitting(false)
            setDefined(true)
            enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
          })
          .catch(() => {
            setSubmitting(false)
            enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), { variant: 'error' })
          })
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
          <Card>
            <CardHeader
              subheader={intl.formatMessage({ id: 'settings.securityInfo' })}
              title={intl.formatMessage({ id: 'settings.security' })}
            />
            <Divider />

            {defined && (
              <Alert severity="warning">
                <FormattedMessage id="settings.zipSecretDefined" />
              </Alert>
            )}

            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid className={classes.item} item xs={12}>
                  <Typography color="textPrimary" gutterBottom variant="h6">
                    <FormattedMessage id="settings.defineZipSecret" />
                  </Typography>

                  <Box mt={2}>
                    <Field
                      component={TextField}
                      error={Boolean(touched.zipSecret && errors.zipSecret)}
                      helperText={touched.zipSecret && errors.zipSecret}
                      label={intl.formatMessage({ id: 'settings.zipSecret' })}
                      id="zipSecret"
                      name="zipSecret"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={classes.textField}
                      value={values.zipSecret}
                      placeholder={defined ? '*************' : ''}
                      disabled={defined}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button color="primary" disabled={isSubmitting || defined} size="large" type="submit" variant="contained">
                <FormattedMessage id="buttons.save" />
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  )
}

Security.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object
}

export default injectIntl(Security)
