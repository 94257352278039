import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import { injectIntl, FormattedMessage } from 'react-intl'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
//
import Page from 'src/components/Page'
import getAdministrationsTitle from 'src/utils/getAdministrationsTitle'
import { accountService, administrationsAccountsService } from 'src/services'
import FileBulkUploadForm from './FileBulkUploadForm'
import PreviousUploads from './PreviousUploads'
import BulkUploadDetails from './BulkUploadDetails'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const getUserAdministrationAccounts = id => {
  return administrationsAccountsService.getByUserId(id).then(items =>
    items
      .filter(item => item.administration && item.account)
      .map(item => {
        const name = getAdministrationsTitle(item.administration.name)

        return {
          name,
          code: item.administration.code,
          // eslint-disable-next-line no-underscore-dangle
          adminId: item.administration._id,
          status: item.status
        }
      })
  )
}

const BulkUploadView = ({ intl }) => {
  const classes = useStyles()
  const user = accountService.userValue
  const [admins, setAdmins] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [upload, setUpload] = useState()

  const [processing, setProcessing] = React.useState(false)

  useEffect(() => {
    if (upload && upload.status === 'PROCESSING') {
      setProcessing(true)
    }
  }, [upload])

  useEffect(() => {
    if (!loaded) {
      getUserAdministrationAccounts(user.id)
        .then(items => items.filter(admin => admin.status === 'APPROVED'))
        .then(items => setAdmins(items))
        .finally(() => setLoaded(true))
    }
  }, [loaded, user])

  const handleProcessingStatus = (completed = false) => {
    if (processing && completed) {
      setProcessing(false)
    }
  }

  return (
    <Page className={classes.root} title={intl.formatMessage({ id: 'navigation.bulkUpload' })}>
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography color="textPrimary" gutterBottom variant="h3">
              <FormattedMessage id="navigation.bulkUpload" />
            </Typography>
          </Grid>

          {!loaded ? (
            <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
              <CircularProgress />
              <Typography color="textPrimary" variant="h4">
                <FormattedMessage id="global.loading" />
              </Typography>
            </div>
          ) : null}

          {admins?.length <= 1 && loaded ? (
            <Alert className={classes.alert} severity="warning">
              <AlertTitle>
                <FormattedMessage id="bulkUpload.error" />
              </AlertTitle>
              <Typography color="textPrimary" variant="h4">
                <FormattedMessage id="bulkUpload.invalid" />
              </Typography>
            </Alert>
          ) : null}

          {admins?.length > 1 ? (
            <>
              <Grid item xs={9}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography style={{ marginBottom: '2rem' }} color="textPrimary" gutterBottom variant="h5">
                      <FormattedMessage id="administrationsView.csvUpload" />
                    </Typography>
                    <FileBulkUploadForm onUpload={setUpload} />
                  </Grid>
                  <Grid item xs={12}>
                    {/* {upload && upload.status === 'PROCESSED' && <ProcessedRecordsGrid upload={upload.id} />} */}
                    {upload && upload.status === 'ERROR' && <BulkUploadDetails upload={upload} />}
                    {upload && upload.status === 'PROCESSING' && processing && (
                      <Alert className={classes.alert} severity="warning">
                        <AlertTitle>
                          <FormattedMessage id="administrationsView.processingTitle" />
                        </AlertTitle>
                        <Typography color="textPrimary" variant="h4">
                          <FormattedMessage id="administrationsView.processingMessage" />
                        </Typography>
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <PreviousUploads newUpload={upload} handleProcessingStatus={handleProcessingStatus} user={user} />
              </Grid>
            </>
          ) : null}
        </Grid>
      </Container>
    </Page>
  )
}

export default injectIntl(BulkUploadView)

BulkUploadView.propTypes = {
  intl: PropTypes.object
}
