/* eslint-disable react/no-unescaped-entities */
import { CircularProgress, Box, FormControlLabel, Checkbox, Button, makeStyles, Backdrop } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { DataGrid } from '@material-ui/data-grid'
import { Refresh } from '@material-ui/icons'
import { Alert, Skeleton } from '@material-ui/lab'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { controlsService } from 'src/services'

const useStyles = makeStyles(theme => ({
    backdropParent: {
        position: "relative",
        width: '100%',
        height: 213,
        zIndex: 0
    },
    backdrop: {
        backgroundColor: theme.palette.background.dark,
        position: "absolute",
        zIndex: theme.zIndex.drawer + 1,
    },
}))

const TopicsOffsetsDetail = ({ intl }) => {
    const classes = useStyles()

    const [topicsOffsets, setTopicsOffsets] = React.useState()
    const [loadingTopicsOffsets, setLoadingTopicsOffsets] = React.useState(true)
    const [showOffsets, setShowOffsets] = React.useState(false)

    let cancelled = false

    const loadTopicsOffsets = async () => {
        setLoadingTopicsOffsets(true)
        const result = await controlsService.getTopicsStats()
        const processedResult = result && result.map(({ topic, partitions, consumerPartitions }, index) => {
            const hasConsumerPartition = consumerPartitions.length > 0
            const high = partitions.reduce((acc, cur) => {
                return acc + +cur.high
            }, 0)
            const offset = partitions.reduce((acc, cur) => {
                return acc + +cur.offset
            }, 0)
            const low = partitions.reduce((acc, cur) => {
                return acc + +cur.low
            }, 0)
            const consumerOffset = consumerPartitions.reduce((acc, cur) => {
                return acc + +cur.offset
            }, 0)
            const count = hasConsumerPartition ? offset - consumerOffset : 'N/A'

            return {
                id: index,
                topic,
                high,
                offset,
                low,
                consumerOffset,
                count
            }
        })
        if (!cancelled) {
            setTopicsOffsets(processedResult)
            setLoadingTopicsOffsets(false)
        }
    }

    const toggleShowOffsets = () => {
        setShowOffsets(currState => !currState)
    }

    // add cancelled
    React.useState(() => {
        loadTopicsOffsets()
        return () => { cancelled = true }
    }, [])

    const columns = [
        { field: 'topic', headerName: intl.formatMessage({ id: 'controlsView.topicsOffsets.topic' }), width: 256 },
        { field: 'count', headerName: intl.formatMessage({ id: 'controlsView.topicsOffsets.count' }), width: 156, align: 'right' },
    ]

    const columnsWithOffsets = [
        { field: 'topic', headerName: intl.formatMessage({ id: 'controlsView.topicsOffsets.topic' }), width: 256 },
        { field: 'count', headerName: intl.formatMessage({ id: 'controlsView.topicsOffsets.count' }), width: 156, align: 'right' },
        { field: 'high', headerName: intl.formatMessage({ id: 'controlsView.topicsOffsets.high' }), width: 106, align: 'right' },
        { field: 'offset', headerName: intl.formatMessage({ id: 'controlsView.topicsOffsets.offset' }), width: 106, align: 'right' },
        { field: 'low', headerName: intl.formatMessage({ id: 'controlsView.topicsOffsets.low' }), width: 106, align: 'right' },
        { field: 'consumerOffset', headerName: intl.formatMessage({ id: 'controlsView.topicsOffsets.consumerOffset' }), width: 106, align: 'right' },
    ]

    return (<>
        <br />
        <Grid item xs={12} md={8} >
            <Typography color="textPrimary" variant="h4" >
                <FormattedMessage id="controlsView.kafkaTopicsTitle" />
            </Typography>
        </Grid>
        <Grid item xs={12} md={4} >
            <Box display="flex" justifyContent="flex-end">
                <Box pr={2} >
                    <Button color="primary" variant="text" startIcon={<Refresh />}
                        onClick={() => loadTopicsOffsets()}
                        disabled={loadingTopicsOffsets}
                    >
                        <FormattedMessage id="controlsView.topicsOffsets.refreshTopicsButton" />
                    </Button>
                </Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showOffsets}
                            onChange={toggleShowOffsets}
                            name="checkedB"
                            color="primary"
                        />}
                    label={intl.formatMessage({ id: 'controlsView.topicsOffsets.toggleShowOffsets' })}
                />
            </Box>
        </Grid>
        {!topicsOffsets && (loadingTopicsOffsets ? (
            <Grid item xs={12} >
                <Box display='flex' width='100%' justifyContent="center">
                    <Skeleton variant="rect" width='100%' height={213} />
                </Box>
            </Grid>
        ) : (
            <Grid item xs={12} >
                <Alert severity='info'>
                    <Typography color="textPrimary" variant="h6">
                        <FormattedMessage id="controlsView.topicsOffsetsNotYetAvailable" />
                    </Typography>
                </Alert>
            </Grid >
        ))}
        {topicsOffsets && (<Grid item xs={12}>
            <Box height={213} className={classes.backdropParent}>
                {showOffsets ? (
                    <DataGrid
                        rows={topicsOffsets}
                        columns={columnsWithOffsets}
                        hideFooter
                        rowHeight={30} />
                ) : (
                    <DataGrid
                        rows={topicsOffsets}
                        columns={columns}
                        hideFooter
                        rowHeight={30} />
                )}
                <Backdrop className={classes.backdrop} open={loadingTopicsOffsets}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        </Grid>
        )}
        <br />
    </>
    )
}
export default injectIntl(TopicsOffsetsDetail)

TopicsOffsetsDetail.propTypes = {
    intl: PropTypes.object,
}
