import config from 'src/utils/config'
import fetchWrapper from 'src/utils/fetch'

const baseUrl = `${config.apiUrl}/bulk-uploads`
const downloadBaseUrl = `${config.apiUrl}/bulk-uploads/download`

function upload(formData) {
  return fetchWrapper.postMultipart(baseUrl, formData)
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`)
}

function getAll() {
  return fetchWrapper.get(baseUrl)
}

function getByQuery(query) {
  return fetchWrapper.get(`${baseUrl}`, query)
}

function download(id, fileName) {
  return fetchWrapper.getZipFile(`${downloadBaseUrl}/${id}`, fileName)
}

export default {
  upload,
  download,
  getAll,
  getById,
  getByQuery
}
