import React from 'react'
import PropTypes from 'prop-types'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import BusinessIcon from '@material-ui/icons/Business'
import AvTimerIcon from '@material-ui/icons/AvTimer'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/styles'
//

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  approved: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
  }
}))

const RedChip = withStyles({
  root: {
    borderColor:'coral',
    color:'coral'
  }
})(Chip);

const currentYear = new Date(Date.now()).getFullYear()

const Administration = ({ name, code, status, endYear }) => {
  const classes = useStyles()

  const onlyPastYearSubmission = endYear === (currentYear - 2)
  const closedForSubmission = endYear < (currentYear - 2)

  return (
    <>
      <ListItemAvatar>
        <Avatar>
          <BusinessIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={name} secondary={code} />
      <ListItemSecondaryAction>
        {closedForSubmission && <RedChip
          size="small"
          variant="outlined"
          label={<FormattedMessage id="statuses.submissionClosed" />}
          style={{ marginRight: 5, fontStyle: 'Italic' }}
        />
        }
        {onlyPastYearSubmission && <Chip
          size="small"
          variant="outlined"
          label={<FormattedMessage id="statuses.pastYearOnly" values={{ year: endYear }}/>}
          color="secondary"
          style={{ marginRight: 5, fontStyle: 'Italic' }}
        />
        }
        {status === 'APPROVED' ? (
          <Chip
            size="small"
            onDelete={() => {
              return null
            }}
            variant="outlined"
            label={<FormattedMessage id="statuses.approved" />}
            color="primary"
            className={classes.approved}
            deleteIcon={<CheckCircleIcon className={classes.approved} />}
          />
        ) : (
          <Chip
            size="small"
            onDelete={() => {
              return null
            }}
            variant="outlined"
            label={<FormattedMessage id="statuses.pending" />}
            color="default"
            deleteIcon={<AvTimerIcon />}
          />
        )}
      </ListItemSecondaryAction>
    </>
  )
}

export default injectIntl(Administration)

Administration.propTypes = {
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  endYear: PropTypes.number
}
