import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import {
  MenuItem,
  Select,
  Button,
  FormControl,
  makeStyles,
  InputLabel,
  Grid,
  Box,
  Typography,
  IconButton,
  CircularProgress
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { CloudUpload } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
import { Formik } from 'formik'
//
import { uploadService } from 'src/services'

// year starts 2 years from past and ends previous year
const currentYear = new Date(Date.now()).getFullYear() - 1
const yearOptions = Array.from(new Array(2), (v, i) => {
  return currentYear - i
})

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  dropdown: {
    minWidth: '200px'
  }
}))

const FileUploadForm = ({ administrationId, onUpload, intl, authorizationEndYear, isClosedForSubmission }) => {
  const classes = useStyles()
  const file = React.useRef(null)
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Formik
      initialValues={{ year: currentYear, file: '' }}
      validationSchema={Yup.object().shape({
        year: Yup.string()
          .required(intl.formatMessage({ id: 'validations.field' }))
          .test('year', 'Invalid Year', value => {
            const date = new Date()
            date.setYear(value)
            // eslint-disable-next-line no-restricted-globals
            return !isNaN(date.getTime())
          }),
        file: Yup.string().required(intl.formatMessage({ id: 'validations.field' }))
      })}
      onSubmit={async ({ year, file: filename }, { setSubmitting, resetForm }) => {
        enqueueSnackbar(intl.formatMessage({ id: 'uploads.started' }), {
          variant: 'info',
          autoHideDuration: 3000
        })

        try {
          onUpload(false)

          const formData = new FormData()
          formData.append('year', year)
          formData.append('file', file.current.files[0], filename.replace('C:\\fakepath\\', ''))
          // eslint-disable-next-line no-underscore-dangle
          formData.append('administration', administrationId)
          const uploads = await uploadService.upload(formData)
          file.current.value = null

          const { _id, error, size, errors, status, records } = uploads

          if (error && error === 'validation') {
            enqueueSnackbar(intl.formatMessage({ id: 'uploads.error' }, { total: errors ? errors.length : 0 }), {
              variant: 'error'
            })
          } else if (error && error === 'missing-headers' && errors && errors.length > 0) {
            if (errors.length > 1) {
              enqueueSnackbar(intl.formatMessage({ id: 'uploads.validationErrors.missingHeaders' }, { headers: errors.join(", ") }), {
                variant: 'error'
              })
            } else {
              enqueueSnackbar(intl.formatMessage({ id: 'uploads.validationErrors.missingHeader' }, { header: errors[0] }), {
                variant: 'error'
              })
            }
          } else if (error && error === 'unsupported-headers' && errors && errors.length > 0) {
            if (errors.length > 1) {
              enqueueSnackbar(intl.formatMessage({ id: 'uploads.validationErrors.unsupportedHeaders' }, { headers: errors.join(", ") }), {
                variant: 'error'
              })
            } else {
              enqueueSnackbar(intl.formatMessage({ id: 'uploads.validationErrors.unsupportedHeader' }, { header: errors[0] }), {
                variant: 'error'
              })
            }
          } else if (error && error === 'size') {
            enqueueSnackbar(intl.formatMessage({ id: 'uploads.errorSize' }, { total: size }), {
              variant: 'error'
            })
          } else if (error && error === 'csv-errors') {
            enqueueSnackbar(intl.formatMessage({ id: 'uploads.csvErrors' }, {}), {
              variant: 'error'
            })
          } else if (status && status === 'ERROR') {
            enqueueSnackbar(intl.formatMessage({ id: 'uploads.error' }, { total: size }), {
              variant: 'info'
            })
          } else if (status && status === 'PROCESSING') {
            enqueueSnackbar(intl.formatMessage({ id: 'uploads.processing' }, { total: size }), {
              variant: 'info'
            })
          } else {
            enqueueSnackbar(intl.formatMessage({ id: 'uploads.success' }, { total: size }), {
              variant: 'success'
            })
          }

          resetForm({ values: { year, file: '' } })
          onUpload({ _id, errors, status, error, records })
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
          enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
            variant: 'error'
          })
          onUpload(false)
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ handleChange, handleSubmit, isSubmitting, values, isValid, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <FormControl className={classes.dropdown}>
                <InputLabel style={{ paddingLeft: '10px' }} id="title-label">
                  <FormattedMessage id="uploads.year" /> *
                </InputLabel>
                <Select
                  label={intl.formatMessage({ id: 'uploads.year' })}
                  labelId="year-label"
                  id="year"
                  name="year"
                  defaultValue={currentYear}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={isClosedForSubmission || isSubmitting}
                >
                  {yearOptions.filter(year => !authorizationEndYear || year <= authorizationEndYear).map(year => (
                    <MenuItem name="" key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} item>
              <FormControl>
                <IconButton
                  type="button"
                  component="label"
                  disabled={isClosedForSubmission || isSubmitting}
                >
                  <CloudUpload color="primary" style={{ marginRight: '8px' }} />
                  <input
                    ref={file}
                    type="file"
                    name="file"
                    style={{ display: 'none' }}
                    accept=".csv"
                    onChange={handleChange}
                  />
                  {values.file ? (
                    <Typography>{values.file.replace('C:\\fakepath\\', '')}</Typography>
                  ) : (
                    <Typography>
                      <FormattedMessage id="uploads.upload" />
                    </Typography>
                  )}
                </IconButton>
              </FormControl>
            </Grid>
          </Grid>
          {isSubmitting && (
            <Grid xs={12} item>
              <Alert icon={<CircularProgress thickness={5} size={32} color="inherit" />} severity="info">
                <Typography variant="h6" style={{ paddingTop: '4px' }}>
                  <FormattedMessage id="uploads.uploading" />
                </Typography>
              </Alert>
            </Grid>
          )}
          <Box my={2} mt={3}>
            <Button color="primary" disabled={isSubmitting || !(isValid && dirty)} type="submit" variant="contained">
              <FormattedMessage id="uploads.submit" />
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default injectIntl(FileUploadForm)

FileUploadForm.propTypes = {
  administrationId: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  intl: PropTypes.object,
  authorizationEndYear: PropTypes.number,
  isClosedForSubmission: PropTypes.bool
}
