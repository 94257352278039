import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import PropTypes from 'prop-types'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const options = ['DOC NL', 'DOC FR', 'T&C NL', 'T&C FR', 'Delete Tag'];

const SplitButton = ({ submitTag, disabled }) => {
    const [open, setOpen] = React.useState(false);
    const [tag, setTag] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const doSubmitTag = async () => {
        await submitTag(tag)
        setOpenDialog(false);
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const openDialogWithCurrentSelection = () => {
        const tagSelected = options[selectedIndex]
        setTag(tagSelected)
        setOpenDialog(true)
    }

    return (
        <>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}>
                <DialogTitle>
                    <FormattedMessage id="assets.confirmDialogTitle" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {tag === "Delete Tag" ? (
                            <FormattedMessage id="assets.confirmDialogDescriptionDeleteTag" />
                        ) : (
                            <FormattedMessage id="assets.confirmDialogDescription" values={{ label: tag }} />
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        <FormattedMessage id="buttons.cancel" />
                    </Button>
                    <Button onClick={() => doSubmitTag()} color="primary" autoFocus>
                        <FormattedMessage id="buttons.ok" />
                    </Button>
                </DialogActions>
            </Dialog>

            <ButtonGroup variant="outlined" color="primary" ref={anchorRef} aria-label="split button" disabled={disabled} >
                <Button onClick={() => openDialogWithCurrentSelection()} style={{ color: selectedIndex === 4 ? 'red' : 'black' }} >{options[selectedIndex]}</Button>
                <Button
                    color="primary"
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    style={{ color: selectedIndex === 4 ? 'red' : 'black' }}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>

            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                            style={{ color: index === 4 ? 'red' : 'black' }}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

SplitButton.propTypes = {
    submitTag: PropTypes.func,
    disabled: PropTypes.bool
}

export default SplitButton