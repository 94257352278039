import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Box, Container, Typography, makeStyles } from '@material-ui/core'
//
import Page from 'src/components/Page'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}))

const NotFoundView = ({ intl }) => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title={intl.formatMessage({ id: 'page404.title' })}>
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            <FormattedMessage id="page404.title" />
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            <FormattedMessage id="page404.info" />/
          </Typography>
        </Container>
      </Box>
    </Page>
  )
}

export default injectIntl(NotFoundView)

NotFoundView.propTypes = {
  intl: PropTypes.object
}
