import React from 'react'
import PropTypes from 'prop-types'
import { DataGrid } from '@material-ui/data-grid'
import { makeStyles, Typography, Box } from '@material-ui/core'
import { injectIntl, FormattedMessage } from 'react-intl'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
//
import { uploadService } from 'src/services'

const useStyles = makeStyles(theme => ({
  alert: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const currencyFormatter = new Intl.NumberFormat('fr-BE', {
  style: 'currency',
  currency: 'EUR',
});

const ProcessedRecordsGrid = ({ upload, intl }) => {
  const classes = useStyles()

  const [uploadData, setUploadData] = React.useState(null)

  const columns = [
    { field: 'sequenceNR', headerName: intl.formatMessage({ id: 'dataGrid.nr' }), flex: .3, minWidth: 80 },
    { field: 'firstname', headerName: intl.formatMessage({ id: 'dataGrid.firstName' }), flex: .5, minWidth: 150 },
    { field: 'lastname', headerName: intl.formatMessage({ id: 'dataGrid.lastName' }), flex: .5, minWidth: 150 },
    { field: 'dob', headerName: intl.formatMessage({ id: 'dataGrid.dob' }), width: 110 },
    { field: 'referenceyear', headerName: intl.formatMessage({ id: 'dataGrid.referenceyear' }), flex: .3, minWidth: 100 },
    { field: 'administrationCode', headerName: intl.formatMessage({ id: 'dataGrid.administrationCode' }), flex: .3, minWidth: 100 },
    { field: 'uuid', headerName: intl.formatMessage({ id: 'dataGrid.uuid' }), width: 250 },
    { field: 'uniqueformID', headerName: intl.formatMessage({ id: 'dataGrid.uniqueformID' }), width: 140 },
    {
      field: 'prime',
      headerName: intl.formatMessage({ id: 'dataGrid.prime' }),
      flex: .3,
      minWidth: 100,
      valueFormatter: ({ value }) => currencyFormatter.format(Number(value/100)),
    },
    {
      field: 'cotisation',
      headerName: intl.formatMessage({ id: 'dataGrid.cotisation' }),
      flex: .3,
      minWidth: 100,
      valueFormatter: ({ value }) => currencyFormatter.format(Number(value/100)),
    },
    { field: 'datepaid', headerName: intl.formatMessage({ id: 'dataGrid.datepaid' }), width: 110 },
  ]

  React.useEffect(() => {
    if (typeof upload === 'string') {
      uploadService
        .getByIdForUnion(upload)
        .then(res => {
          // eslint-disable-next-line no-underscore-dangle
          setUploadData({ id: res._id, ...res })
        })
        .catch(() => { })
    } else {
      setUploadData(upload)
    }
  }, [upload])

  if (!uploadData) {
    return (
      <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
        <CircularProgress />
        <Typography color="textPrimary" variant="h4">
          <FormattedMessage id="global.loading" />
        </Typography>
      </div>
    )
  }

  const { error, size, errors, records } = uploadData

  if (error && error === 'size') {
    return (
      <Alert severity="error" className={classes.alert}>
        <Typography color="textPrimary" variant="h5">
          <FormattedMessage id="uploads.errorSize" values={{ total: size }} />
        </Typography>
      </Alert>
    )
  }

  const rows = errors
    ? errors.map((rowError, idx) => {
      return { id: idx, ...rowError }
    })
    : records.map((record, idx) => {
      return { id: idx, ...record, sequenceNR: parseInt(record.sequenceNR, 10) }
    })

  return (
    <Box>
      {errors ? (
        <Alert severity="error" className={classes.alert}>
          <Typography color="textPrimary" variant="h5">
            <FormattedMessage id="uploads.errors" />
          </Typography>
        </Alert>
      ) : (
        <div>
          <Typography variant="h5">
            <FormattedMessage id="uploads.processedTitle" />
          </Typography>
        </div>
      )}
      <div style={{ height: 550, width: '100%' }}>
        <div style={{ height: 500, width: '100%' }}>
          <DataGrid
            disableSelectionOnClick
            rowsPerPageOptions={[10, 20, 50, 100]}
            columns={columns}
            rows={rows}
            pageSize={10}
            headerHeight={42}
            rowHeight={30}
          />
        </div>
      </div>
    </Box>
  )
}

export default injectIntl(ProcessedRecordsGrid)

ProcessedRecordsGrid.propTypes = {
  upload: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  intl: PropTypes.object
}
