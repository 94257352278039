import React from 'react'
import { injectIntl, FormattedMessage, IntlContext } from 'react-intl'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Toolbar from '@material-ui/core/Toolbar'
import useMediaQuery from '@material-ui/core/useMediaQuery'
//
import LangSwitcher from 'src/components/LangSwitcher'

const useStyles = makeStyles({
  toolbar: {
    backgroundColor: '#fff',
    color: '#333',
    height: 32,
    minHeight: 32,
    fontSize: '12px',
    fontFamily: 'Arial, Verdana, sans-serif'
  },
  link: {
    marginLeft: '4px',
    color: '#333',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  image: {
    width: '32px',
    marginLeft: '4px'
  }
})

const FederalHeader = ({ className, ...rest }) => {
  const classes = useStyles()
  const intl = React.useContext(IntlContext)
  const lang = intl.locale || 'en'
  const noMobile = useMediaQuery('(min-width:600px)')

  return (
    <Toolbar className={classes.toolbar} {...rest}>
      <LangSwitcher />
      <Box flexGrow={1} />
      {noMobile ? <FormattedMessage id="header.message" /> : null}

      <a className={classes.link} href={`https://www.belgium.be/${lang}`} target="_blank" rel="noopener noreferrer">
        https://www.belgium.be
      </a>

      <a className={classes.link} href={`https://www.belgium.be/${lang}`} target="_blank" rel="noopener noreferrer">
        <img className={classes.image} src="/static/logo.gif" width="32" alt="" />
      </a>
    </Toolbar>
  )
}

FederalHeader.propTypes = {
  className: PropTypes.string
}

export default injectIntl(FederalHeader)
