import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

const Logo = () => {
  return (
    <Grid container alignContent="center" alignItems="center" spacing={1}>
      <Grid item>
        <Typography color="textSecondary" variant="h4" style={{ minWidth: '200px' }}>
          <FormattedMessage id="header.name" />
        </Typography>
      </Grid>
    </Grid>
  )
}

export default injectIntl(Logo)
