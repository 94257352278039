/* eslint-disable no-underscore-dangle */
import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Container,
  Dialog,
  DialogTitle,
  CircularProgress,
  Typography,
  TextField
} from '@material-ui/core'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import DialogActions from '@material-ui/core/DialogActions'
import { injectIntl, FormattedMessage } from 'react-intl'
//
import { Alert, AlertTitle } from '@material-ui/lab'
import { controlsService } from 'src/services'


const DeleteControlDialog = ({ control, open, handleClose, loading, setLoading, intl }) => {
  const { enqueueSnackbar } = useSnackbar()

  const handleDelete = async () => {
    setLoading(true)
    try {
      await controlsService.deleteControlById(control.id)
      enqueueSnackbar(intl.formatMessage({ id: 'controlsView.controlDeleted' }), {
        variant: 'success',
        autoHideDuration: 3000
      })
    } catch (error) {
      enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
        variant: 'error',
        autoHideDuration: 3000
      })
    }
    handleClose()
  }

  return (
    <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
      <DialogTitle style={{ color: 'red' }} >
        <FormattedMessage id="controlsView.deleteControl" />
      </DialogTitle>
      <Container>
        <Grid container xl>
          <Grid item xs={12}>
            {loading && (
              <div>
                <CircularProgress color="inherit" size={20} />
                <FormattedMessage id="global.loading" />
              </div>
            )}

            {!loading && control ? (
              <Formik
                onSubmit={async () => {
                }}
                initialValues={{
                  controlName: ''
                }}
                validationSchema={Yup.object().shape({
                  controlName: Yup.string()
                    .required(intl.formatMessage({ id: 'validations.field' }))
                    .test('controlName', 'Invalid Name', value => !!value),
                })}
              >
                {({ handleSubmit, values, touched, errors, handleBlur, handleChange }) => (
                  <form onSubmit={handleSubmit}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Alert severity="warning">
                              <AlertTitle>
                                <FormattedMessage id="controlsView.alertDeletedTitle" />
                              </AlertTitle>
                              <Typography color="textPrimary" variant="h4">
                                <FormattedMessage id="controlsView.alertDeletedText" values={{ name: control.name, year: control.controlYear }} />
                              </Typography>
                            </Alert>
                          </Grid>

                          <Grid item xs={12}>
                            <Box display="flex" alignContent="flex-end" alignItems="center">
                              <Box flexGrow={1}>
                                <TextField
                                  // error={Boolean(touched.code && errors.code)}
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                  fullWidth
                                  helperText={touched.controlName && errors.controlName}
                                  label={intl.formatMessage({ id: 'controlsView.permanentDeleteName' })}
                                  name="controlName"
                                  required
                                  value={values.controlName}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                />
                              </Box>
                              <Box pl={5}>
                                <Button fullWidth
                                  onClick={() => handleDelete()}
                                  size="large"
                                  type="button"
                                  variant="contained"
                                  disabled={![control.name, control.id].includes(values.controlName) }
                                  style={{ color: ![control.name, control.id].includes(values.controlName) ? 'gray' : 'red' }}>
                                  <FormattedMessage id="buttons.permanentDelete" />
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>

                    <Box my={2} mt={3}>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </DialogActions>
                    </Box>
                  </form>
                )}
              </Formik>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Dialog >
  )
}

export default injectIntl(DeleteControlDialog)

DeleteControlDialog.propTypes = {
  control: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  intl: PropTypes.object
}
