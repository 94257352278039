import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Box, Button, Container, TextField, Typography, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
//
import Page from 'src/components/Page'
import { accountService } from 'src/services'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const ForgotPasswordView = ({ intl }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { executeRecaptcha } = useGoogleReCaptcha()

  return (
    <Page className={classes.root} title={intl.formatMessage({ id: 'forgotPassword.title' })}>
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email(intl.formatMessage({ id: 'validations.email' }))
                .max(255)
                .required(intl.formatMessage({ id: 'validations.field' }))
            })}
            onSubmit={async ({ email }, { setSubmitting }) => {
              const captcha = await executeRecaptcha('login')
              accountService
                .forgotPassword(email, captcha)
                .then(() => {
                  enqueueSnackbar(intl.formatMessage({ id: 'forgotPassword.message' }), { variant: 'success' })
                })
                .catch(() => {
                  setSubmitting(false)
                  enqueueSnackbar(intl.formatMessage({ id: 'forgotPassword.error' }), { variant: 'error' })
                })
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    <FormattedMessage id="forgotPassword.title" />
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    <FormattedMessage id="forgotPassword.description" />
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={intl.formatMessage({ id: 'login.email' })}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    <FormattedMessage id="forgotPassword.submit" />
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  )
}

export default injectIntl(ForgotPasswordView)

ForgotPasswordView.propTypes = {
  intl: PropTypes.object
}
