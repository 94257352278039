import React from 'react'
import PropTypes from 'prop-types'
import {
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Fab,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { AddIcon, DataGrid } from '@material-ui/data-grid'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as Yup from 'yup'
//
import { Delete, Edit, NavigateNext } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import moment from 'moment'
import { controlsService } from 'src/services'
import { LocalizedLink, useLocalizedNavigate } from 'src/i18n'
import AddPremiumRowDialog from './AddPremiumRowDialog'
import ImportPremiumsDialog from './ImportPremiumsDialog'

const DEFAULT_PREMIUM = [
  {
    id: 1,
    name: "Full premium",
    prime: 9000,
    cotisationMin: 16404,
    cotisationMax: null,
  },
  {
    id: 2,
    name: "3/4 of the premium",
    prime: 6750,
    cotisationMin: 12303,
    cotisationMax: 16404,
  },
  {
    id: 3,
    name: "Half premium",
    prime: 4500,
    cotisationMin: 8202,
    cotisationMax: 12303,
  },
  {
    id: 4,
    name: "1/4 of the premium",
    prime: 2250,
    cotisationMin: 4101,
    cotisationMax: 8202,
  }
];

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  card: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

const CreateControl = ({ intl }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useLocalizedNavigate()

  const loading = false
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [tableLoading, setTableLoading] = React.useState(false)
  const [controlPremium, setControlPremium] = React.useState(DEFAULT_PREMIUM)

  const [open, setOpen] = React.useState(false)
  const [editMode, setEditMode] = React.useState(true)
  const [premiumRow, setPremiumRow] = React.useState()

  const [openImportPremiums, setOpenImportPremiums] = React.useState(false)

  const defaultControlYear = moment().year() - 2

  const handleCloseDialog = (updateTable = false, generatedRow = null) => {
    setOpen(false)
    if (updateTable) {
      setTableLoading(true)

      setTimeout(() => {
        const controlPremiumNewState = controlPremium
        if (editMode) {
          const index = controlPremiumNewState.findIndex(x => x.id === generatedRow.id)
          controlPremiumNewState[index] = generatedRow
          setControlPremium(controlPremiumNewState)
        } else {
          controlPremiumNewState.push(generatedRow)
          setControlPremium(controlPremiumNewState)
        }
        setTableLoading(false)
      }, 10)
    }
  }

  const handleAddPremiumRow = () => {
    const newRow = {
      id: controlPremium.reduce((acc, cur) => acc > cur.id ? acc : cur.id, 0) + 1
    }
    setPremiumRow(newRow)
    setEditMode(false)
    setOpen(true)
  }

  const handleEditPremiumRow = (id) => {
    const row = controlPremium.filter(x => x.id === id)[0]
    setPremiumRow(row)
    setEditMode(true)
    setOpen(true)
  }

  const openDialogImportPremiums = () => {
    setOpenImportPremiums(true)
  }

  const fab = {
    color: 'default',
    className: classes.fab,
    icon: <AddIcon />,
    label: 'Add',
  }

  const columns = [
    { field: 'name', headerName: intl.formatMessage({ id: 'controlsView.premium.name' }), width: 156 },
    { field: 'prime', headerName: intl.formatMessage({ id: 'controlsView.premium.prime' }), width: 156, align: 'right' },
    { field: 'cotisationMax', headerName: intl.formatMessage({ id: 'controlsView.premium.cotisationMax' }), width: 156, align: 'right' },
    { field: 'cotisationMin', headerName: intl.formatMessage({ id: 'controlsView.premium.cotisationMin' }), width: 156, align: 'right' },
    {
      field: 'id',
      headerName: ' ',
      width: 120,
      renderCell: (params) => {
        const id = params.value
        return <>
          <IconButton onClick={() => handleEditPremiumRow(id)}>
            <Edit fontSize="small" />
          </IconButton>

          <IconButton onClick={() => {
            setControlPremium(oldState => {
              const { value } = params
              const newState = oldState.filter(entry => entry.id !== value)
              return newState
            })
          }}>
            <Delete color="error" fontSize="small" />
          </IconButton>
        </>
      },
    },
  ];

  return loading ? (
    <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
      <CircularProgress />
      <Typography color="textPrimary" variant="h4">
        <FormattedMessage id="global.loading" />
      </Typography>
    </div>
  ) : (
    <>
      {open && (
        <AddPremiumRowDialog
          premiumRow={premiumRow}
          editMode={editMode}
          open={open}
          handleClose={handleCloseDialog}
          setPremiumRow={setPremiumRow}
        />
      )}
      <ImportPremiumsDialog
        openImportPremiums={openImportPremiums}
        setOpenImportPremiums={setOpenImportPremiums}
        setControlPremium={setControlPremium}
      />
      <Container maxWidth="md" >
        <Card className={classes.card} >
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12} >
                <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                  <LocalizedLink to='app/controls'>
                    <Typography color="textSecondary" variant="h3" >
                      <FormattedMessage id="navigation.controls" />
                    </Typography>
                  </LocalizedLink>
                  <Typography color="textPrimary" variant="h3">
                    <FormattedMessage id="controlsView.controlConfiguration" />
                  </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid >
            <br />
            <br />
            <Formik
              initialValues={{
                controlYear: defaultControlYear,
                name: ''
              }}
              validationSchema={Yup.object().shape({
                controlYear: Yup.number().required(),
                name: Yup.string().required()
              })}
              onSubmit={({ controlYear, name }, { setSubmitting }) => {
                setSubmitting(true)
                setBackdropOpen(true)

                controlsService.create({ name, controlYear, expectedPremiums: controlPremium })
                  .then((res) => {
                    const { id: controlID } = res
                    enqueueSnackbar(intl.formatMessage({ id: 'controlsView.controlCreatedMessage' }), {
                      variant: 'info',
                      autoHideDuration: 3000
                    })
                    setBackdropOpen(false)
                    navigate(`/app/controls/${controlID}`, { replace: false });
                  }).catch(() => {
                    enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), {
                      variant: 'error',
                      autoHideDuration: 10000
                    })
                    setBackdropOpen(false)
                  })
              }}
            >
              {({ handleBlur, handleChange, handleSubmit, isSubmitting, values, touched, errors }) => (
                <form onSubmit={handleSubmit} >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        error={Boolean(touched.controlYear && errors.controlYear)}
                        fullWidth
                        helperText={touched.controlYear && errors.controlYear}
                        label={intl.formatMessage({ id: 'controlsView.referenceYear' })}
                        name="controlYear"
                        required
                        type="number"
                        value={values.controlYear}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label={intl.formatMessage({ id: 'controlsView.controlName' })}
                        name="name"
                        required
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box height={240}>
                        <>
                          {!tableLoading && controlPremium ? <DataGrid
                            rows={controlPremium}
                            columns={columns}
                            hideFooter
                            rowHeight={30}
                          /> : <Skeleton variant="rect" animation="wave" height={240} />}
                          <Fab size="small"
                            aria-label={fab.label}
                            className={fab.className}
                            color={fab.color}
                            disabled={isSubmitting}
                            onClick={() => handleAddPremiumRow()}>
                            {fab.icon}
                          </Fab>
                        </>
                      </Box>
                    </Grid>
                    <Grid item xs={12} align="right">
                      <Box px={2} component="span">
                        <Button variant="contained" color="default" disabled={openImportPremiums} onClick={() => openDialogImportPremiums()}>
                          <FormattedMessage id="controlsView.premium.importPremiums" />
                        </Button>
                      </Box>
                      <Box component="span">
                        <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                          <FormattedMessage id="controlsView.createControlButton" />
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik >
            <Backdrop className={classes.backdrop} open={backdropOpen} onClick={() => setBackdropOpen(false)}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

CreateControl.propTypes = {
  intl: PropTypes.object
}

export default injectIntl(CreateControl)
