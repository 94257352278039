import React from 'react'
import { Outlet } from 'react-router-dom'
import { Divider, makeStyles } from '@material-ui/core'
import { IntlContext } from 'react-intl'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
//
import config from 'src/utils/config'

import TopBar from './TopBar'
import Footer from './Footer'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 96
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}))

const MainLayout = () => {
  const classes = useStyles()
  const intl = React.useContext(IntlContext)

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaKey} language={intl.locale} useRecaptchaNet>
              <Outlet />
            </GoogleReCaptchaProvider>
          </div>
        </div>
      </div>
      <Divider />
      <Footer />
    </div>
  )
}

export default MainLayout
