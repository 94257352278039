import React from 'react'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import { FormattedMessage, injectIntl } from 'react-intl'
//
import { LocalizedLink } from 'src/i18n'
import InfoMessage from './InfoMessage'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '40px',
    alignItems: 'center',
    display: 'flex'
  },
  link: {
    marginRight: '10px'
  }
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <>
      <InfoMessage />

      <Container maxWidth="lg" className={classes.root}>
        <Link component={LocalizedLink} to="/terms-and-conditions" variant="h6" className={classes.link}>
          <FormattedMessage id="footer.termsLink" />
        </Link>
        <Link component={LocalizedLink} to="/help" variant="h6" className={classes.link}>
          <FormattedMessage id="footer.helpLink" />
        </Link>
      </Container>
    </>
  )
}

export default injectIntl(Footer)
