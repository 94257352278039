/* eslint-disable react/no-unescaped-entities */
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Alert, Skeleton } from '@material-ui/lab'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
//
import { CloudDownload, Replay } from '@material-ui/icons'
import { controlsService } from 'src/services'
import ControlStatusChip from '../ControlStatusChip'
import ControlContext from './ControlContext'

const ImportedUnionsDetail = ({
    unionsStats,
    unionImportStatus,
    verificationStatus,
    loadingImportUnions,
    reimportUnionsAction,
    intl
}) => {
    const [overallProgress, setOverallProgress] = React.useState(null)
    const [open, setOpen] = React.useState(false);

    const control = React.useContext(ControlContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async (actionAgreed = false) => {
        if (actionAgreed) {
            await reimportUnionsAction(control)
        }
        setOpen(false);
    };

    React.useEffect(() => {
        if (unionImportStatus) {
            const { total, statuses } = unionImportStatus
            const completed = statuses.filter(({ status }) => status === "COMPLETED").flatMap(({ count }) => count).shift() || undefined
            if (completed && total) {
                setOverallProgress(`${completed}/${total}`)
            } else {
                setOverallProgress(`N/A`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        <br />
        <Dialog
            open={open}
            onClose={handleClose} >
            <DialogTitle>
                <FormattedMessage id="controlsView.reimportUnionsDialogTitle" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id="controlsView.reimportUnionsDialogText" />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    <FormattedMessage id="buttons.cancel" />
                </Button>
                <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                    <FormattedMessage id="buttons.ok" />
                </Button>
            </DialogActions>
        </Dialog>

        <Grid item xs={12} md={12} >
            <Box display="flex" alignContent="flex-end" alignItems="center">
                <Box width="20%">
                    <Typography color="textPrimary" variant="h4" >
                        <FormattedMessage id="controlsView.importedUnions" />
                    </Typography>
                </Box>
                {unionImportStatus && (
                    <Box display="flex" flexDirection="row" alignItems="center" px={2}>
                        <ControlStatusChip status={unionImportStatus.status} outlined />
                        {["COMPLETED"].includes(unionImportStatus.status) && (<>
                            <Box pl={1}>
                                <IconButton title={intl.formatMessage({ id: 'controlsView.reimportUnionButton' })}
                                    onClick={handleClickOpen}>
                                    <Replay />
                                </IconButton>
                            </Box>
                            <Box pl={1}>
                                <IconButton color="primary" title={intl.formatMessage({ id: 'controlsView.reportUnionsButton' })}
                                    onClick={async () => controlsService.downloadUnionsCSVReport(control)}>
                                    <CloudDownload />
                                </IconButton>
                            </Box>
                        </>
                        )}
                    </Box>)
                }
            </Box>
        </Grid>
        {unionsStats ? (
            <>
                <Grid item xs={6} md={3} >
                    <Typography color="textPrimary" variant="h5" >
                        <FormattedMessage id="controlsView.totalUnions" />
                        {unionImportStatus && unionImportStatus.status === "IN_PROGRESS" ? (
                            <FormattedMessage id="controlsView.overallProgress" />) : null}
                    </Typography>
                </Grid>

                {unionImportStatus && unionImportStatus.status === "IN_PROGRESS" ? (
                    <Grid item xs={6} md={3} >
                        <Typography color="textPrimary">
                            {overallProgress || 'N/A'}
                        </Typography>
                    </Grid>
                ) : (
                    <Grid item xs={6} md={3} >
                        <Typography color="textPrimary">
                            {(unionImportStatus && unionImportStatus.total) || 'N/A'}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={6} md={3} >
                    <Typography color="textPrimary" variant="h5" >
                        <FormattedMessage id="controlsView.totalUnionsFile" />
                    </Typography>
                </Grid>
                <Grid item xs={6} md={3} >
                    <Typography color="textPrimary">
                        {(unionsStats && unionsStats.totalNumberCSVFilesImported) || 'N/A'}
                    </Typography>
                </Grid>

                <Grid item xs={6} md={3} >
                    <Typography color="textPrimary" variant="h5" >
                        <FormattedMessage id="controlsView.sumUnionsCSVRows" />
                    </Typography>
                </Grid>
                <Grid item xs={6} md={3} >
                    <Typography color="textPrimary">
                        {(unionsStats && unionsStats.totalSizeCSVFilesImported) || 'N/A'}
                    </Typography>
                </Grid>
                <Grid item xs={6} md={3} >
                    <Typography color="textPrimary" variant="h5" >
                        <FormattedMessage id="controlsView.totalUnionsPeople" />
                    </Typography>
                </Grid>
                <Grid item xs={6} md={3} >
                    <Typography color="textPrimary">
                        {(verificationStatus && verificationStatus.total) || 'N/A'}
                    </Typography>
                </Grid>
            </>
        ) : (
            <Grid item xs={12} >
                {loadingImportUnions ? (
                    <Skeleton variant="rect" width='100%' height={50} />
                ) : (
                    <Alert severity='info'>
                        <Typography color="textPrimary" variant="h6">
                            <FormattedMessage id="controlsView.importUnionResultsNotYetAvailable" />
                        </Typography>
                    </Alert>
                )}
            </Grid>
        )}
        <br />
    </>
    )
}
export default injectIntl(ImportedUnionsDetail)

ImportedUnionsDetail.propTypes = {
    unionsStats: PropTypes.object,
    unionImportStatus: PropTypes.object,
    verificationStatus: PropTypes.object,
    loadingImportUnions: PropTypes.bool.isRequired,
    reimportUnionsAction: PropTypes.func.isRequired,
    intl: PropTypes.object
}
