import React from 'react'
import PropTypes from 'prop-types'
import { DataGrid } from '@material-ui/data-grid'
import { makeStyles, Typography, Box, IconButton, Tooltip, Grid, CircularProgress, TextareaAutosize } from '@material-ui/core'
import { injectIntl, FormattedMessage } from 'react-intl'
import Alert from '@material-ui/lab/Alert'
import { useSnackbar } from 'notistack'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
//

const ERROR_LOG_DISPLAY_LIMIT = 1000

const useStyles = makeStyles(theme => ({
  alert: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  alertOutlined: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textareaAutosize: {
    width: '100%',
    maxHeight: '25vh',
    resize: 'vertical',
    padding: theme.spacing(.5)
  }
}))

const ErrorsRecordsGrid = ({ upload, small, intl }) => {
  const { enqueueSnackbar } = useSnackbar()

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    enqueueSnackbar(intl.formatMessage({ id: 'messages.copied' }), {
      variant: 'secondary'
    })
  }

  const classes = useStyles()

  const columns = [
    { field: 'sequenceNR', headerName: intl.formatMessage({ id: 'dataGrid.nr' }), width: 80 },
    { field: 'firstname', headerName: intl.formatMessage({ id: 'dataGrid.firstName' }), width: 200 },
    { field: 'lastname', headerName: intl.formatMessage({ id: 'dataGrid.lastName' }), width: 200 },
    { field: 'dob', headerName: intl.formatMessage({ id: 'dataGrid.dob' }), width: 140 },
    { field: 'postcode', headerName: intl.formatMessage({ id: 'dataGrid.postCode' }), width: 140 },
    { field: 'year', headerName: intl.formatMessage({ id: 'dataGrid.year' }), width: 90 },
    { field: 'language', headerName: intl.formatMessage({ id: 'dataGrid.language' }), width: 140 },
  ]

  if (!upload) {
    return (
      <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
        <CircularProgress />
        <Typography color="textPrimary" variant="h4">
          <FormattedMessage id="global.loading" />
        </Typography>
      </div>
    )
  }

  const { error, size, errors } = upload

  if (error && error === 'missing-headers' && errors && errors.length > 0) {
    return (
      <Alert severity="error" className={classes.alert}>
        <Typography color="textPrimary" variant="h5">
          <FormattedMessage id="uploads.errors" />
        </Typography>
        <Typography color="textPrimary" variant="h5">
          {errors.length > 1 ?
            <FormattedMessage id="uploads.validationErrors.missingHeaders" values={{ headers: errors.join(", ") }} /> :
            <FormattedMessage id="uploads.validationErrors.missingHeader" values={{ header: errors[0] }} />
          }
        </Typography>
      </Alert>
    )
  }

  if (error && error === 'unsupported-headers' && errors && errors.length > 0) {
    return (
      <Alert severity="error" className={classes.alert}>
        <Typography color="textPrimary" variant="h5">
          <FormattedMessage id="uploads.errors" />
        </Typography>
        <Typography color="textPrimary" variant="h5">
          {errors.length > 1 ?
            <FormattedMessage id="uploads.validationErrors.unsupportedHeaders" values={{ headers: errors.join(", ") }} /> :
            <FormattedMessage id="uploads.validationErrors.unsupportedHeader" values={{ header: errors[0] }} />
          }
        </Typography>
      </Alert>
    )
  }

  if (error && error === 'size') {
    return (
      <Alert severity="error" className={classes.alert}>
        <Typography color="textPrimary" variant="h5">
          <FormattedMessage id="uploads.errorSize" values={{ total: size }} />
        </Typography>
      </Alert>
    )
  }

  if (error && error === 'csv-errors') {
    const logMessages = errors.map((err) => `${intl.formatMessage({ id: `uploads.line` }, { number: err.index })} ${intl.formatMessage({ id: `uploads.validationErrors.${err.errorCode}` })}`)
    const errorText = logMessages.join('\n')
    let errorDisplay
    if (logMessages.length > ERROR_LOG_DISPLAY_LIMIT) {
      errorDisplay = logMessages.slice(0, ERROR_LOG_DISPLAY_LIMIT).join('\n')
    }
    return (
      <Box>
        <Alert severity="error" className={classes.alert}>
          <Typography color="textPrimary" variant="h5">
            <FormattedMessage id="uploads.errors" />
          </Typography>
          <Typography color="textPrimary" variant="h5">
            <FormattedMessage id="uploads.csvErrors" />
          </Typography>
        </Alert>
        <div style={{ height: small ? 250 : 550 }}>
          <div style={{ height: small ? 225 : 500 }}>
            <Grid
              container
              direction="row"
              justifycontent="flex-start"
              alignItems="baseline"
              style={{ paddingTop: '1rem' }}
              spacing={1}>
              <Grid item >
                <Typography color="textPrimary" gutterBottom variant="h5">
                  <FormattedMessage id="uploads.errorList" />
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={intl.formatMessage({ id: 'buttons.copyToClipboard' })}>
                  <IconButton aria-label="copy to clipboard" onClick={() => copyToClipboard(errorText)}>
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <TextareaAutosize
              className={classes.textareaAutosize}
              aria-label="errors area"
              defaultValue={errorDisplay || errorText}
              disabled
              style={{ overflow: 'auto' }}
            />
            {errorDisplay &&
              <Alert severity="error" variant="outlined" className={classes.alertOutlined}>
                <Typography color="textPrimary" variant="h6">
                  <FormattedMessage id="uploads.errorDisplayLimit" values={{ limit: ERROR_LOG_DISPLAY_LIMIT }} />
                </Typography>
              </Alert>
            }
          </div>
        </div>
      </Box>
    )
  }

  const rows = errors.map((rowError, idx) => {
    return { id: idx, ...rowError }
  })

  return (
    <Box>
      <Alert severity="error" className={classes.alert}>
        <Typography color="textPrimary" variant="h5">
          <FormattedMessage id="uploads.errors" />
        </Typography>
      </Alert>
      {small ? (<div style={{ height: 550 }}>
        <div style={{ height: 500 }}>
          <DataGrid
            disableSelectionOnClick
            rowsPerPageOptions={[10, 20, 50, 100]}
            columns={columns}
            rows={rows}
            pageSize={10}
            headerHeight={42}
            rowHeight={30}
          />
        </div>
      </div>)
        :
        (<div style={{ height: 550 }}>
          <div style={{ height: 500 }}>
            <DataGrid
              disableSelectionOnClick
              rowsPerPageOptions={[10, 20, 50, 100]}
              columns={columns}
              rows={rows}
              pageSize={10}
              headerHeight={42}
              rowHeight={30}
            />
          </div>
        </div>)
      }
    </Box>
  )
}

export default injectIntl(ErrorsRecordsGrid)

ErrorsRecordsGrid.propTypes = {
  upload: PropTypes.object.isRequired,
  small: PropTypes.bool,
  intl: PropTypes.object
}
